import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    CurrentUserTimelineElementContainer: {
      marginLeft: 'auto',
      flexDirection: 'column',
      marginRight: theme.spacing(0.5),

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
  })
);
