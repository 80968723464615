import { IconDefinition, ParsableDate } from './types.common';
import { mimeType } from './types.mimeTypes';
import { GridItem } from './types.GridItem';
import { RequestConfig } from './types.Component';

export type Field =
  | ArrayOf
  | ComboBox
  | Checkbox
  | DatePicker
  | FileUpload
  | FormattedNumber
  | RadioGroup
  | Select
  | Text
  | EntryPicker;

// Тип для моделирования массивов полей.
//
// Бывают ситуации, когда мы хотим предоставить
// пользователю возможность заполнить массив каких-то полей
// (список работ, оценка работы в смете).
//
// Структура полей может представлять из себя что-то подобное:
// [
//   { description: 'Сделал то-то', price: 200, quantity: 2 },
//   { description: 'Ещё то-то', price: 140, quantity: 1 },
// ]
//
// Объект для описания такой структуры может выглядеть так:
// {
//   type: 'arrayOf', // указали тип поля
//   // придумали name для идентификации этой структуры в контексте всей формы
//   name: 'workList',
//   row: [ // см. апи по моделированию типов полей (тип Field)
//     { type: 'text' name: 'description' },
//     { type: 'text', inputType: 'number', name: 'price' },
//     { type: 'text', inputType: 'number', name: 'quantity' },
//   ]
// }
//
// Или может быть просто массив каких-то полей, вида:
// [ 'Не убрали за собой', 'Забыли смазать дверцу' ]
//
// Тогда объект описания может выглядеть так:
// {
//   type: 'arrayOf', // указали тип поля
//   // придумали name для идентификации этой структуры в контексте всей формы
//   name: 'feedback',
//   // указали, что row представляет из себя одно текстовое поле
//   row: { type: 'text' },
// }
//
// ⬇️ Пример использования ⬇️
//
// Хотим получить следующие данные:
// const replacedParts = [
//   { item: 'Хлебопечка', quantity: 1, measure: 'шт.', price: 200 },
//   { item: 'Вал', quantity: 10, measure: 'шт.', price: 20 },
//   { item: 'Вал', quantity: 10, measure: 'шт.', price: 20 },
// ];
//
// Для этого с бека должно прийти это:
// const field: ArrayOf = {
//   type: 'arrayOf',
//   name: 'replacedParts',
//   label: 'Заменённые запчасти',
//   rowDefinition: [
//     {
//       type: 'select',
//       name: 'spec',
//       options: [
//         {
//           value: '5f74c62d-5f50-49c1-8b88-ed688da6dc01',
//           label: 'Производитель',
//         },
//         {
//           value: '75e22fc6-d534-417d-9794-405a6877f0ee',
//           label: 'Серийный номер',
//         },
//         {
//           value: '75e22fc6-d534-417d-9794-405a6877f0ee',
//           label: 'Страна/происхождение',
//         },
//       ],
//       label: 'Спецификация',
//     },
//     { type: 'text', name: 'foo', label: 'Значение' },
//   ],
//   defaultValue: [
//     {
//       spec: {
//         value: '5f74c62d-5f50-49c1-8b88-ed688da6dc01',
//         label: 'Производитель',
//       },
//       foo: 'Россия',
//     },
//     {
//       spec: {
//         value: '75e22fc6-d534-417d-9794-405a6877f0ee',
//         label: 'Серийный номер',
//       },
//       foo: '63379',
//     },
//     {
//       spec: {
//         value: '75e22fc6-d534-417d-9794-405a6877f0ee',
//         label: 'Страна/происхождение',
//       },
//       foo: 'sdf,dsf ods,fo',
//     },
//   ],
// };

export type ArrayOfDefaultValue = {
  [key: string]:
    | string
    | number
    | boolean
    | SelectOption
    | SelectOption[]
    | null;
};

export interface ArrayOf extends BaseField<'arrayOf'> {
  rowDefinition: Field[];
  defaultValue?: ArrayOfDefaultValue[];
  disallowRowDeletion?: boolean; // запретить удаление строк
  disallowRowAddition?: boolean; // запретить добавление строк
  showAutoNumeration?: boolean; // показать авто-нумерацию
  addRowButtonText?: string; // текст кнопки "добавить строку"
  showEmptyRowByDefault?: boolean; // показать пустую строку (нельзя удалить). По-умолчанию `true`.
}

export type DatePicker =
  | SimpleDatePicker
  | DateTimePicker
  | DateRangePicker
  | DateTimeRangePicker
  | YearPicker;

export interface SimpleDatePicker extends BaseDatePicker<'date'> {
  defaultValue?: ParsableDate;
}

export interface DateTimePicker extends BaseDatePicker<'dateTime'> {
  defaultValue?: ParsableDate;
}

export interface DateRangePicker extends BaseDatePicker<'dateRange'> {
  defaultValue?: DateRangeValue;
}

export interface DateTimeRangePicker extends BaseDatePicker<'dateTimeRange'> {
  defaultValue?: DateRangeValue;
}

export interface YearPicker extends BaseDatePicker<'year'> {
  defaultValue?: ParsableDate;
}

interface BaseDatePicker<T> extends BaseField<'datePicker'> {
  pickerType: T; // by defaults 'date'
  defaultValue?: ParsableDate | DateRangeValue;
  initialFocusedDate?: ParsableDate;
  minDate?: ParsableDate;
  maxDate?: ParsableDate;
}

export type DateRangeValue = {
  from?: string | null;
  to?: string | null;
};

export interface Checkbox extends BaseField<'checkbox'> {
  defaultValue?: boolean;
  labelPlacement?: 'top' | 'end';
  highlightColor?: HighlightColor;
}

export interface RadioGroup extends BaseField<'radio'> {
  options: RadioOption[];
}

export interface FileUpload extends BaseField<'file'> {
  viewConfig?: FileUploadViewConfig;
  defaultValue?: File[];
  accept?: mimeType[];
}

interface FileUploadViewConfig {
  viewStyle?: 'textFieldWithClip' | 'dropZone'; // тип представление
}

export interface File {
  // id нужен для получения файла
  // //api/upload/451490c9-c197-4bc9-8464-ba2169554b9a?width=320
  id: string | number;
  size: number; // размер в байтах
  name: string;
  mimeType: string;
}

/**
 * Поле для выбора значения справочника
 */
export type EntryPicker = EntryPickerSingle | EntryPickerMulti;

export interface EntryPickerSingle extends BaseEntryPicker<ComboBoxOption> {
  multiple: false;
}

export interface EntryPickerMulti extends BaseEntryPicker<ComboBoxOption[]> {
  multiple: true;
}

interface BaseEntryPicker<D> extends BaseField<'entryPicker'> {
  multiple: boolean;
  defaultValue?: D;
  searchRequestConfig: RequestConfig;
  gridRequestConfig: RequestConfig;
}

/**
 * \\ Поле для выбора значения справочника
 */

/**
 * Поле для выбора из списка значений.
 */
export type ComboBox =
  | FlatComboBoxMultiple
  | FlatComboBoxSingle
  | CheckboxTreeComboBox
  | RadioTreeComboBox;

export enum OptionsType {
  checkboxTree = 'checkboxTree',
  radioTree = 'radioTree',
  flat = 'flat',
}

/**
 * Древовидный список checkbox-значений.
 * В стейте может храниться несколько значений.
 *
 * В options должны передаваться CheckboxTreeOption.
 *
 * На бек придёт массив CheckboxTreeOption.
 */
export interface CheckboxTreeComboBox
  extends BaseComboBox<OptionsType.checkboxTree, CheckboxTreeSelectOption> {
  defaultValue?: CheckboxTreeSelectOption[];
  multiline?: boolean;
}

/**
 * Древовидный список radio-значений.
 * В стейте может быть только одно значение.
 *
 * В options должны передаваться RadioTreeSelectOption.
 * На основе selectable в RadioTreeSelectOption будет выводиться радиокнопка
 * для выбора значения.
 *
 *
 * На бек придёт одно значение, типа RadioTreeSelectOption.
 */
export interface RadioTreeComboBox
  extends BaseComboBox<OptionsType.radioTree, RadioTreeSelectOption> {
  defaultValue?: RadioTreeSelectOption;
}

/**
 * Аналог MultipleSelect.
 * Плоский список значений.
 * В стейте может быть несколько значений.
 *
 * На бек придёт массив из значений, типа ComboBoxOption.
 */
export interface FlatComboBoxMultiple
  extends BaseComboBox<OptionsType.flat, ComboBoxOption> {
  multiple: true;
  multiline?: boolean;
  defaultValue?: ComboBoxOption[];
}

/**
 * Аналог SingleSelect.
 * Плоский список значений.
 * В стейте хранится только один вариант.
 *
 * На бек придёт одно значение, типа ComboBoxOption.
 */
export interface FlatComboBoxSingle
  extends BaseComboBox<OptionsType.flat, ComboBoxOption> {
  multiple?: false;
  defaultValue?: ComboBoxOption;
}

/**
 * Базовый интерфейс для всех типов ComboBox.
 */
interface BaseComboBox<T, O> extends BaseField<'comboBox'> {
  optionsType: T; // 'flat' | 'checkboxTree' | 'radioTree'
  options: O[]; // массив вариантов. Имеется непрямая связь с полем `optionsType`.
  warningText?: string; // жёлтые поля
}

export type Select =
  | DependentSelect
  | AsyncMultiSelect
  | AsyncSelect
  | MultiSelect
  | SingleSelect;

export interface DependentSelect extends BaseSelect<'dependent'> {
  searchUrl: string; // куда стучать для получения списка SelectOption[].
  /**
   * Если нужно зацепиться на row ArrayOf, то
   * нужно указать JSONObjectPath с маской - 'arrayOfFieldName[].rowFieldName'.
   *
   * К примеру, есть arrayOf поле, у которого name = 'equipmentsArray'.
   * Row состоит из двух полей: { name: 'room' }, { name: 'equipment' }.
   *
   * Если хочется заставить их смотреть друг на друга, то json будет такой:
   * { name: 'room', watchOn: ["equipmentsArray[].equipment"] },
   * { name: 'equipment', dependOn: ["equipmentsArray[].room"] }
   *
   * Также arrayOf row можно заставить смотреть на "внешку", но внешнее поле
   * нельзя заставить смотреть в arrayOf row.
   *
   * Более подробные примеры использования смотрите в
   * `//services/Main/local/stubs/fields/DependWithArrayOf.ts`.
   */
  dependOn?: string[]; // от какого поля зависит.
  watchOn?: string[]; // за каким полем следить.
  sequential?: boolean; // последовательный выбор или параллельный.
  multiple?: boolean; // множественный выбор?
  // если множественный, то SelectOption[], иначе SelectOption.
  defaultValue?: SelectOption | SelectOption[];
  autoSelect?: boolean; // авто-подстановка, при единственном варианте. По-умолчанию false.
}

export interface AsyncMultiSelect extends BaseSelect<'asyncMulti'> {
  searchUrl: string;
  defaultValue?: SelectOption[];
  preLoadedOptions?: SelectOption[];
}

export interface AsyncSelect extends BaseSelect<'async'> {
  searchUrl: string;
  defaultValue?: SelectOption;
  preLoadedOptions?: SelectOption[];
}

export interface MultiSelect extends BaseSelect<'multi'> {
  defaultValue?: SelectOption[];
  options: SelectOption[];
}

export interface SingleSelect extends BaseSelect<'single'> {
  defaultValue?: SelectOption;
  options: SelectOption[];
}

interface BaseSelect<T> extends BaseField<'select'> {
  defaultValue?: SelectOption | SelectOption[];
  selectType?: T; // by default 'single',
  highlightColor?: HighlightColor;
}

export type HighlightColor = 'error' | 'warning' | 'info' | 'success';

export type RadioTreeSelectOption = {
  selectable: boolean; // можно ли выбирать этот Option
} & TreeSelectOption;

export type CheckboxTreeSelectOption = {} & TreeSelectOption;

export type TreeSelectOption = {
  parentValue: string | null; // значение родительского поля
} & ComboBoxOption;

export type ComboBoxOption = {
  value: string; // значение поля
  label: string; // отображаемое значение
  href?: string; // ссылка
};

export type RadioOption = SelectOption & {
  icon?: IconDefinition | null;
};

export type SelectOption = {
  value: string;
  label: string;
  href?: string;
  disabled?: boolean; // by default 'false' - DEPRECATED.
};

export type FormattedNumber = PriceFormat | FlexibleNumberFormat;

// Нужен для отображения инпута для работы с ценами.
// Автоматически делит число по разрядам и подставляет
// знак валюты в конце.
export interface PriceFormat extends BaseFormattedNumber<'price'> {
  currencySign: string;
}

// Нужен для ручного кофигурирования правил
// форматирования числового ввода.
//
// Документация по назначению свойств маски https://github.com/s-yadav/react-number-format
// Можно применять только те свойства, которые перечислены ниже.
export interface FlexibleNumberFormat
  extends BaseFormattedNumber<'flexibleNumber'> {
  decimalScale?: number | string;
  decimalSeparator?: string;
  thousandSeparator?: string;
  allowNegative?: boolean;
}

export interface BaseFormattedNumber<T> extends BaseField<'formattedNumber'> {
  formattedNumberType: T; // 'price' | 'flexibleNumber'
  min?: number | string;
  max?: number | string;
}

export interface Text extends BaseField<'text'> {
  inputType?: 'text' | 'numeric' | 'password';
  multiline?: boolean;
  rowsMax?: number; // Максимальное количество строк
  autoFocus?: boolean;
}

interface BaseField<T> extends GridItem {
  type: T;
  name: string;
  label?: string | Element | JSX.Element;
  helperText?: string;
  defaultValue?: unknown;
  disabled?: boolean;
  validationConfig?: ValidationConfig;
  renderCondition?: FieldRenderCondition;
  /**
   * Управления функционалом браузерного автозаполнения.
   *
   * "on" - включает автозаполнение текста;
   * "off" - отключает автозаполнение.
   *
   * Документация: https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
   *
   * По-умолчанию: "off"
   */
  autoComplete?: string;
}

// Какие бывают условия:
//
// На какое-то другое поле установлено определённое значение.
// На какое-то другое поле НЕ установлено определённое значение.
// На какое-то другое поле установлено ЛЮБОЕ значение.
// На какое-то другое поле НЕ установлено НИКАКОЕ значение.
export interface FieldRenderCondition {
  dependOn: string; // name поля, за значением которого следим.
  condition: 'equals' | 'except' | 'empty' | 'notEmpty';
  value?: unknown;
}

export const ALL_VALIDATION_TYPES = [
  'string',
  'number',
  'array',
  'mixed',
  'date',
] as const;
type validationTypeTuple = typeof ALL_VALIDATION_TYPES;
export type ValidationType = validationTypeTuple[number];

export interface ValidationConfig {
  validationType: ValidationType;
  validations: Validation[];
}

export interface Validation {
  type: string;
  params: ValidationParams;
}

export type ValidationParams =
  | WithErrorText
  | WithArgAndErrorText
  | WithSubSchemaAndErrorText
  | WithWhenValidationRule;

type WithErrorText = [string];
type WithArgAndErrorText = [unknown, string];
type WithSubSchemaAndErrorText = [Pick<Field, 'name' | 'validationConfig'>[]];
export type WithWhenValidationRule = [string, WhenValidationRule];

interface WhenValidationRule {
  is: unknown; // значение "главного" поля
  then: Validation[]; // если "главное" поле === is, то выполнить эти валидации
  otherwise?: Validation[]; // иначе провести эти валидации
}

export interface BreadcrumbItem {
  isLast: boolean;
  label: string;
  url?: string;
  icon?: IconDefinition | null;
}
