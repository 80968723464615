import { RootResponse } from '../types';

export const rootResponse = {
  keepUpVersion: {
    appliedMigrations: '20201120074912_init',
    database: 'Dev8888',
    environmentName: 'Development',
    keepUpVersion: '2.2.15.301',
    server: 'tcp://localhost:5432',
  },
  user: {
    id: 1,
    name: 'Арапетян Сигизмунд',
    token: '57c20fead82542f98a468a7b0cf858e4==',
    login: 'KeepUpAdmin',
    locale: 'ru_RU',
  },
  routes: [
    { pageId: 65535, path: '/', exact: true },
    { pageId: 65535, path: '/divider-link-example', exact: true },
    { pageId: 1, path: '/task-types/repair' },
    { pageId: 2, path: '/issues', exact: true },
    { pageId: 3, path: '/issues/:id' },
    {
      pageId: 72,
      path: '/issues/create',
      exact: true,
    },
    { pageId: 15, path: '/response-reactions/redirect', exact: true },
    { pageId: 16, path: '/response-reactions/dynamic-redirect', exact: true },
    { pageId: 17, path: '/response-reactions/reload-module', exact: true },
    { pageId: 18, path: '/response-reactions/async-redirect', exact: true },
    {
      pageId: 19,
      path: '/response-reactions/show-alert-and-redirect',
      exact: true,
    },
    { pageId: 20, path: '/high-level-components/table/simple', exact: true },
    {
      pageId: 21,
      path: '/high-level-components/table/with-call-formatters',
      exact: true,
    },
    {
      pageId: 22,
      path: '/high-level-components/table/with-filters',
      exact: true,
    },
    {
      pageId: 23,
      path: '/high-level-components/table/with-row-click-reaction',
      exact: true,
    },
    {
      pageId: 24,
      path: '/high-level-components/table/with-row-click-reaction/:id',
    },
    {
      pageId: 46,
      path: '/high-level-components/table/header',
      exact: true,
    },
    { pageId: 25, path: '/high-level-components/form/simple', exact: true },
    { pageId: 26, path: '/fields/text', exact: true },
    { pageId: 27, path: '/fields/select', exact: true },
    { pageId: 28, path: '/fields/date', exact: true },
    { pageId: 29, path: '/fields/formatted-number', exact: true },
    { pageId: 30, path: '/fields/file-upload', exact: true },
    { pageId: 31, path: '/fields/array-of', exact: true },
    { pageId: 32, path: '/fields/radio', exact: true },
    { pageId: 33, path: '/high-level-components/form/all-fields', exact: true },
    { pageId: 34, path: '/page-views/single-component', exact: true },
    { pageId: 35, path: '/page-views/tabs', exact: true },
    { pageId: 43, path: '/page-views/widgets', exact: true },
    { pageId: 36, path: '/page-views/404', exact: true },
    { pageId: 37, path: '/page-views/403', exact: true },
    { pageId: 71, path: '/page-views/removed-page', exact: true },
    { pageId: 38, path: '/high-level-components/form/header', exact: true },
    { pageId: 39, path: '/actions/submit-form', exact: true },
    { pageId: 40, path: '/actions/trigger-endpoint', exact: true },
    { pageId: 41, path: '/actions/show-form-variant-1', exact: true },
    { pageId: 42, path: '/actions/show-form-variant-2', exact: true },
    { pageId: 44, path: '/complex/calendar-stm', exact: true },
    { pageId: 45, path: '/fields/checkbox', exact: true },
    { pageId: 68, path: '/fields/fields-scroll', exact: true },
    { pageId: 47, path: '/response-reactions/validation-error', exact: true },
    {
      pageId: 48,
      path: '/high-level-components/form/with-alerts',
      exact: true,
    },
    { pageId: 49, path: '/actions/redirect', exact: true },
    // С сервера можно передать список страниц, к которым ограничен доступ (403).
    { pageId: 11, path: '/private-page', exact: true },
    { pageId: 50, path: '/complex/approve-service-work', exact: true },
    {
      pageId: 51,
      path: '/high-level-components/form/with-validation',
      exact: true,
    },
    { pageId: 53, path: '/fields/dependent-select', exact: true },
    { pageId: 54, path: '/fields/sequential-dependent-select', exact: true },
    {
      pageId: 55,
      path: '/response-reactions/async-show-component',
      exact: true,
    },
    {
      pageId: 56,
      path: '/response-reactions/download-file',
      exact: true,
    },
    {
      pageId: 57,
      path: '/widgets/text-widget',
      exact: true,
    },
    {
      pageId: 58,
      path: '/high-level-components/html-printer',
      exact: true,
    },
    {
      pageId: 59,
      path: '/fields/auto-selectable-dependent-select',
      exact: true,
    },
    {
      pageId: 60,
      path: '/fields/conditional',
      exact: true,
    },
    {
      pageId: 61,
      path: '/fields/dependent-with-array-of',
      exact: true,
    },
    {
      pageId: 62,
      path: '/validations/matches',
      exact: true,
    },
    {
      pageId: 63,
      path: '/fields/dependent-with-array-of-and-text-field',
      exact: true,
    },
    {
      pageId: 64,
      path: '/fields/dependent-with-yellow',
      exact: true,
    },
    {
      pageId: 65,
      path: '/high-level-components/table/separate-row-click-reaction',
      exact: true,
    },
    {
      pageId: 66,
      path: '/actions/go-back',
      exact: true,
    },
    {
      pageId: 67,
      path: '/fields/combobox',
      exact: true,
    },
    {
      pageId: 69,
      path: '/high-level-components/table/with-alerts',
      exact: true,
    },
    {
      pageId: 70,
      path: '/fields/entry-picker',
      exact: true,
    },

    {
      pageId: 73,
      path: '/some-tasks',
      exact: true,
    },
    {
      pageId: 74,
      path: '/some-tasks/:id',
      exact: true,
    },
    {
      pageId: 75,
      path: '/some-tasks/:id/:subTaskId',
      exact: true,
    },
    {
      pageId: 76,
      path: '/some-tasks/create',
      exact: true,
    },
    {
      pageId: 77,
      path: '/some-tasks/:id/create-subtask',
      exact: true,
    },
  ],
  mainMenu: [
    { type: 'module', id: 1, label: 'Пресеты страниц', icon: 'mui:ListAlt' },
    {
      type: 'link',
      id: 2,
      parentId: 1,
      to: '/',
      label: 'Главная',
    },

    { type: 'module', id: 3, label: 'Комплексные примеры', icon: 'mui:Event' },
    {
      type: 'link',
      id: 4,
      parentId: 3,
      to: '/issues',
      label: 'Заявки',
    },

    {
      type: 'group',
      id: 5,
      parentId: 3,
      label: 'ТО',
    },
    {
      type: 'link',
      id: 6,
      parentId: 5,
      to: '/complex/calendar-stm',
      label: 'Создание ежегодного ППР',
    },
    {
      type: 'link',
      id: 7,
      parentId: 5,
      to: '/complex/approve-service-work',
      label: 'Выполнение ТО',
    },

    {
      type: 'module',
      id: 8,
      label: 'Представления страниц',
      icon: 'mui:Tab',
      to: '/divider-link-example',
    },
    {
      type: 'link',
      id: 9,
      parentId: 8,
      to: '/page-views/single-component',
      label: 'SingleComponent',
    },
    {
      type: 'link',
      id: 10,
      parentId: 8,
      to: '/page-views/tabs',
      label: 'Tabs',
    },
    {
      type: 'link',
      id: 11,
      parentId: 8,
      to: '/page-views/widgets',
      label: 'Widgets',
    },
    {
      type: 'link',
      id: 12,
      parentId: 8,
      to: '/page-views/404',
      label: '404',
    },
    {
      type: 'link',
      id: 13,
      parentId: 8,
      to: '/page-views/403',
      label: '403',
    },
    {
      type: 'link',
      id: '3f138812-11e0-4e4f-b198-8f7487a787fc',
      parentId: 8,
      to: '/page-views/removed-page',
      label: 'Страница удалена',
    },

    {
      type: 'module',
      id: 14,
      label: 'Высокоуровневые компоненты',
      icon: 'mui:GridOn',
    },

    {
      type: 'group',
      id: 15,
      parentId: 14,
      label: 'Table',
    },
    {
      type: 'link',
      id: 16,
      parentId: 15,
      to: '/high-level-components/table/simple',
      label: 'Простой пример',
    },
    {
      type: 'link',
      id: 17,
      parentId: 15,
      to: '/high-level-components/table/with-call-formatters',
      label: 'С форматтерами ячеек',
    },
    {
      type: 'link',
      id: 18,
      parentId: 15,
      to: '/high-level-components/table/with-filters',
      label: 'С фильтрами',
    },
    {
      type: 'link',
      id: 19,
      parentId: 15,
      to: '/high-level-components/table/with-row-click-reaction',
      label: 'С rowClickReaction',
    },
    {
      type: 'link',
      id: 20,
      parentId: 15,
      to: '/high-level-components/table/header',
      label: 'С набафанным Header',
    },
    {
      type: 'link',
      id: 21,
      parentId: 15,
      to: '/issues',
      label: 'С поиском',
    },
    {
      type: 'link',
      id: 22,
      parentId: 15,
      to: '/high-level-components/table/separate-row-click-reaction',
      label: 'Разные rowClickReaction на каждой row',
    },
    {
      type: 'link',
      id: 72,
      parentId: 15,
      to: '/high-level-components/table/with-alerts',
      label: 'С алертами',
    },

    {
      type: 'group',
      id: 23,
      parentId: 14,
      label: 'Form',
    },
    {
      type: 'link',
      id: 24,
      parentId: 23,
      to: '/high-level-components/form/simple',
      label: 'Простой пример',
    },
    {
      type: 'link',
      id: 25,
      parentId: 23,
      to: '/high-level-components/form/all-fields',
      label: 'Со всеми полями',
    },
    {
      type: 'link',
      id: 26,
      parentId: 23,
      to: '/high-level-components/form/header',
      label: 'С набафанным Header',
    },
    {
      type: 'link',
      id: 27,
      parentId: 23,
      to: '/tasks/30c151a5-f863-4747-b664-3e4457f5acc7',
      label: 'Со всеми actions',
    },
    {
      type: 'link',
      id: 28,
      parentId: 23,
      to: '/high-level-components/form/with-alerts',
      label: 'С алертами',
    },
    {
      type: 'link',
      id: 29,
      parentId: 23,
      to: '/high-level-components/form/with-validation',
      label: 'С валидацией',
    },

    {
      type: 'group',
      id: 30,
      parentId: 14,
      label: 'HTMLPrinter',
    },
    {
      type: 'link',
      id: 31,
      parentId: 30,
      to: '/high-level-components/html-printer',
      label: 'Простой пример',
    },

    { type: 'module', id: 32, label: 'Остальное', icon: 'mui:HelpOutline' },
    {
      type: 'group',
      id: 33,
      parentId: 32,
      label: 'Типы Actions',
    },
    {
      type: 'link',
      id: 34,
      parentId: 33,
      to: '/actions/submit-form',
      label: 'SubmitForm',
    },
    {
      type: 'link',
      id: 35,
      parentId: 33,
      to: '/actions/trigger-endpoint',
      label: 'TriggerEndpoint',
    },
    {
      type: 'link',
      id: 36,
      parentId: 33,
      to: '/actions/show-form-variant-1',
      label: 'ShowForm модалка',
    },
    {
      type: 'link',
      id: 37,
      parentId: 33,
      to: '/actions/show-form-variant-2',
      label: 'ShowForm fullScreen форма',
    },
    {
      type: 'link',
      id: 38,
      parentId: 33,
      to: '/actions/redirect',
      label: 'Redirect',
    },
    {
      type: 'link',
      id: 39,
      parentId: 33,
      to: '/actions/go-back',
      label: 'GoBack',
    },

    {
      type: 'group',
      id: 40,
      parentId: 32,
      label: 'Типы responseReaction',
    },
    {
      type: 'link',
      id: 41,
      parentId: 40,
      to: '/response-reactions/reload-module',
      label: 'reLoadModule',
    },
    {
      type: 'link',
      id: 42,
      parentId: 40,
      to: '/response-reactions/async-redirect',
      label: 'asyncRedirect',
    },
    {
      type: 'link',
      id: 43,
      parentId: 40,
      to: '/response-reactions/redirect',
      label: 'redirect',
    },
    {
      type: 'link',
      id: 44,
      parentId: 40,
      to: '/response-reactions/show-alert-and-redirect',
      label: 'showAlertAndRedirect',
    },
    {
      type: 'link',
      id: 45,
      parentId: 40,
      to: '/response-reactions/dynamic-redirect',
      label: 'dynamicRedirect',
    },
    {
      type: 'link',
      id: 46,
      parentId: 40,
      to: '/response-reactions/validation-error',
      label: 'validationError',
    },
    {
      type: 'link',
      id: 47,
      parentId: 40,
      to: '/response-reactions/async-show-component',
      label: 'asyncShowComponent',
    },
    {
      type: 'link',
      id: 48,
      parentId: 40,
      to: '/response-reactions/download-file',
      label: 'downloadFile',
    },

    {
      type: 'group',
      id: 49,
      parentId: 32,
      label: 'Поля формы',
    },
    {
      type: 'link',
      id: 50,
      parentId: 49,
      to: '/fields/text',
      label: 'Text',
    },
    {
      type: 'link',
      id: 51,
      parentId: 49,
      to: '/fields/select',
      label: 'Select',
    },
    {
      type: 'link',
      id: 52,
      parentId: 49,
      to: '/fields/dependent-select',
      label: 'Ведущие-ведомые',
    },
    {
      type: 'link',
      id: 53,
      parentId: 49,
      to: '/fields/sequential-dependent-select',
      label: 'Последовательные ведущие-ведомые',
    },
    {
      type: 'link',
      id: 54,
      parentId: 49,
      to: '/fields/dependent-with-array-of',
      label: 'Ведущие-ведомые + ArrayOf',
    },
    {
      type: 'link',
      id: 55,
      parentId: 49,
      to: '/fields/dependent-with-array-of-and-text-field',
      label: 'Ведущие-ведомые + ArrayOf + text',
    },
    {
      type: 'link',
      id: 56,
      parentId: 49,
      to: '/fields/dependent-with-yellow',
      label: 'Ведущие-ведомые + желтые поля',
    },
    {
      type: 'link',
      id: 58,
      parentId: 49,
      to: '/fields/auto-selectable-dependent-select',
      label: 'Ведущие-ведомые с авто-выбором',
    },
    {
      type: 'link',
      id: 59,
      parentId: 49,
      to: '/fields/date',
      label: 'Date',
    },
    {
      type: 'link',
      id: 60,
      parentId: 49,
      to: '/fields/formatted-number',
      label: 'FormattedNumber',
    },
    {
      type: 'link',
      id: 61,
      parentId: 49,
      to: '/fields/file-upload',
      label: 'FileUpload',
    },
    {
      type: 'link',
      id: 62,
      parentId: 49,
      to: '/fields/array-of',
      label: 'ArrayOf',
    },
    {
      type: 'link',
      id: 6556565656,
      parentId: 49,
      to: '/fields/combobox',
      label: 'ComboBox',
    },
    {
      type: 'link',
      id: 63,
      parentId: 49,
      to: '/fields/radio',
      label: 'Radio',
    },
    {
      type: 'link',
      id: 64,
      parentId: 49,
      to: '/fields/checkbox',
      label: 'Checkbox',
    },

    {
      type: 'group',
      id: 66,
      parentId: 32,
      label: 'Виджеты',
    },
    {
      type: 'link',
      id: 67,
      parentId: 66,
      to: '/widgets/text-widget',
      label: 'Текстовый виджет',
    },

    {
      type: 'group',
      id: 68,
      parentId: 32,
      label: 'Валидации',
    },
    {
      type: 'link',
      id: 69,
      parentId: 68,
      to: '/validations/matches',
      label: 'Matches',
    },

    {
      type: 'group',
      id: 70,
      parentId: 32,
      label: 'Feature demo',
    },
    {
      type: 'link',
      id: 71,
      parentId: 70,
      to: '/fields/fields-scroll',
      label: 'Горизонтальный скролл полей',
    },
    {
      type: 'link',
      id: 57,
      parentId: 70,
      to: '/fields/conditional',
      label: 'Условные поля',
    },
    {
      type: 'link',
      id: 74000,
      parentId: 49,
      to: '/fields/entry-picker',
      label: 'EntryPicker (Выбор из справочника)',
    },

    {
      type: 'group',
      id: 9000,
      parentId: 32,
      label: 'Тестирование роутинга',
    },
    {
      type: 'link',
      id: 9100,
      parentId: 9000,
      to: '/some-tasks',
      label: 'Задачи',
    },
  ],
} as RootResponse;
