import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    rowWrapper: {
      display: 'flex',
    },
    index: {
      width: 13,
      marginRight: theme.spacing(1),

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);
