import React from 'react';
import { FormAction } from 'services/Main/types.Component';
import FormActionComponent from '../FormAction';
import { OnReactionExecuteEvent } from '../../../responseReactions/ReactionController';

interface FormActionsProps {
  actions: FormAction[];
  onReactionExecute?: OnReactionExecuteEvent;
}

const FormActions = ({ actions, onReactionExecute }: FormActionsProps) => (
  <>
    {actions.map((actionProps) => (
      <FormActionComponent
        key={Object.values(actionProps).join('')}
        action={actionProps}
        onReactionExecute={onReactionExecute}
      />
    ))}
  </>
);

export default FormActions;
