import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import {
  AsyncRedirectReaction,
  AsyncShowComponentInModalReaction,
  Component,
  DownloadFileReaction,
  DynamicRedirectReaction,
  Reaction,
  RedirectReaction,
  RenderPageReaction,
  ShowAlertAndRedirectReaction,
} from '../services/Main/types.Component';
import reLoadModule from './reLoadModule';
import { PageContext } from '../components/creational/PageLoader/PageLoader';
import asyncRedirectReaction from './asyncRedirect';
import showAlertAndRedirectReaction from './showAlertAndRedirect';
import redirectReaction from './redirect';
import dynamicRedirectReaction from './dynamicRedirect';
import { UnexpectedDataError } from '../utils/errors/UnexpectedDataError';
import asyncShowComponentInModal from './asyncShowComponentInModal';
import downloadFileReaction from './downloadFile';
import renderPageReaction from './renderPage';
import { Page } from '../services/Main/types.Page';

export type OnReactionExecuteEvent = () => void;
type EnqueueSnackbarFn = (
  message: SnackbarMessage,
  options?: OptionsObject
) => SnackbarKey;

export default class ReactionController {
  private readonly reactionProps: Reaction;

  private readonly pageContext: PageContext;

  private enqueueSnackbar: EnqueueSnackbarFn;

  private readonly payload?: unknown;

  constructor(
    reactionProps: Reaction,
    pageContext: PageContext,
    enqueueSnackbar: EnqueueSnackbarFn,
    payload?: unknown
  ) {
    this.reactionProps = reactionProps;
    this.pageContext = pageContext;
    this.enqueueSnackbar = enqueueSnackbar;
    this.payload = payload;
  }

  execute(onReactionExecute?: OnReactionExecuteEvent) {
    onReactionExecute && onReactionExecute();

    console.log(
      'Executing response reaction with reactionProps:',
      this.reactionProps,
      'pageContext:',
      this.pageContext,
      'payload:',
      this.payload
    );

    if (this.reactionProps.snackbar) {
      this.enqueueSnackbar(
        this.reactionProps.snackbar.text,
        this.reactionProps.snackbar.options
      );
    }

    switch (this.reactionProps!.type) {
      case 'reLoadModule':
        reLoadModule(this.pageContext);
        break;

      case 'dynamicRedirect':
        dynamicRedirectReaction(
          this.reactionProps as DynamicRedirectReaction,
          this.payload
        );
        break;

      case 'asyncRedirect':
        asyncRedirectReaction(
          this.reactionProps as AsyncRedirectReaction,
          this.payload
        );
        break;

      case 'redirect':
        redirectReaction(this.reactionProps as RedirectReaction);
        break;

      case 'showAlertAndRedirect':
        showAlertAndRedirectReaction(
          this.reactionProps as ShowAlertAndRedirectReaction
        );
        break;

      case 'asyncShowComponentInModal':
        asyncShowComponentInModal(
          this.reactionProps as AsyncShowComponentInModalReaction,
          this.payload as Component
        );
        break;

      case 'downloadFile':
        downloadFileReaction(
          this.reactionProps as DownloadFileReaction,
          this.payload as string
        );
        break;

      case 'renderPage':
        renderPageReaction(
          this.reactionProps as RenderPageReaction,
          this.pageContext,
          this.payload as Page
        );
        break;

      default:
        throw new UnexpectedDataError(
          'Не удалось определить тип реакции на ответ сервера.'
        );
    }
  }
}
