import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

interface StylesProps {
  fontSizeInPx?: number;
  width?: number | string;
  height?: number | string;
  stroke?: string;
  fill?: string;
}

export default ({ fill, fontSizeInPx, height, stroke, width }: StylesProps) =>
  makeStyles((theme: Theme) =>
    createStyles({
      svg: {
        width,
        height,
        stroke,
        fill,
        fontSize: fontSizeInPx
          ? theme.typography.pxToRem(fontSizeInPx)
          : 'inherit',
      },
    })
  );
