import { useContext, useMemo } from 'react';
import EntryPickerTableContext from '../EntryPickerTable.context';

export interface SortingColumnExtension {
  columnName: string;
  sortingEnabled: boolean;
}

export function useSorting() {
  const { columns } = useContext(EntryPickerTableContext);

  const sortingStateColumnExtensions = useMemo(
    () =>
      (columns || []).reduce(
        (acc, column) => [
          ...acc,
          {
            columnName: column.name,
            sortingEnabled: !!column.options?.sortable,
          },
        ],
        [] as SortingColumnExtension[]
      ),
    [columns]
  );

  const someColumnSortable = useMemo(
    () =>
      Array.isArray(columns) &&
      columns.some((column) => column.options?.sortable),
    [columns]
  );

  return {
    sortingStateColumnExtensions,
    someColumnSortable,
  };
}
