import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormContext } from 'react-hook-form';
import throttle from 'lodash.throttle';
import get from 'lodash.get';
import mainService from 'services/Main';
import { AsyncSelect, SelectOption } from 'services/Main/types.Field';
import useStyles from '../Select.styles';
import { useFormatMessage } from '../../../../locale';

interface OtherProps {
  value?: any;
  onChange?: any;
}

export default ({
  disabled,
  label,
  preLoadedOptions,
  searchUrl,
  value,
  helperText,
  onChange,
  name,
  highlightColor,
}: AsyncSelect & OtherProps) => {
  const formatMessage = useFormatMessage();
  const classes = useStyles(highlightColor)();
  const { errors } = useFormContext();
  const error = get(errors, name);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<SelectOption[]>(
    preLoadedOptions || []
  );
  const [warningText, setWaringText] = useState<string | undefined>();

  useEffect(
    function loadOptions() {
      setIsLoading(true);
      mainService
        .fetchAutocompleteOptions(searchUrl, { value: inputValue })
        .then(({ options: newOptions, warningText: newWarningText }) => {
          setWaringText(newWarningText || undefined);
          setOptions(newOptions);
          setIsLoading(false);
        });
    },
    [inputValue, searchUrl, setOptions]
  );

  const handleInputChange = useCallback(
    throttle((event: any, newInputValue: string) => {
      setInputValue(newInputValue);
    }, 200),
    []
  );

  const handleChange = (event: any, option: any) => {
    onChange(option);
  };

  return (
    <Autocomplete
      className={clsx(classes.autocompleteRoot, {
        warning: !!warningText,
      })}
      loading={isLoading}
      loadingText={formatMessage('loading')}
      noOptionsText={formatMessage('noOptions')}
      clearText={formatMessage('clear')}
      closeText={formatMessage('close')}
      openText={formatMessage('open')}
      disabled={disabled}
      value={value || null}
      inputValue={inputValue}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(o, v) => o.value === v.value}
      getOptionDisabled={(o) => o.disabled}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.textField}
          variant="standard"
          label={label}
          name={name}
          helperText={error?.message || warningText || helperText}
          error={!!error}
        />
      )}
    />
  );
};
