import React from 'react';
import DialogFormAction from '../FormAction/DialogFormAction';
import { useHandleClickAction } from './handlers';
import { TableActionProps } from './types';
import TableActionView from '../TableActions/TableActionsView';

export default (props: TableActionProps) => {
  const { action, onReactionExecute } = props;
  const { type, label, color, icon, variant, ...rest } = action;

  const handleClick = useHandleClickAction({
    type,
    onReactionExecute,
    ...rest,
  });

  // Показать форму, со своими экшнами в модалке, выполнить реакцию по сабмиту.
  if (props.action.type === 'showForm')
    return (
      <DialogFormAction
        renderButton={(openDialog) => (
          <TableActionView {...props} handleClick={openDialog} />
        )}
        {...props}
      />
    );

  return <TableActionView {...props} handleClick={handleClick} />;
};
