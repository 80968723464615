import React, { FunctionComponent } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import MuiChip from '@material-ui/core/Chip';
import MuiAvatar from '@material-ui/core/Avatar';
import { Chip } from 'services/Main/types.Component';
import useStyles from './ChipTypeProvider.styles';
import Avatar from '../../../../lowLevel/Avatar';
import Icon from '../../../../lowLevel/Icon';

const Formatter: FunctionComponent<DataTypeProvider.ValueFormatterProps> = ({
  value,
}) => {
  const classes = useStyles(value?.htmlColor)();

  if (!value || !(value as Chip)?.label) return null;

  const {
    label,
    avatar,
    clickable,
    color,
    disabled,
    icon,
    size,
    variant,
  } = value as Chip;

  return (
    <MuiChip
      className={classes.root}
      avatar={
        avatar &&
        (avatar.hasOwnProperty('fullName') ? (
          <Avatar name={(avatar as any).fullName} />
        ) : (
          <MuiAvatar src={(avatar as any).src} alt={(avatar as any).alt} />
        ))
      }
      icon={icon ? <Icon definition={icon} /> : undefined}
      label={label}
      disabled={disabled}
      clickable={clickable}
      color={color as any}
      size={size}
      variant={variant}
    />
  );
};

interface IconTypeProviderProps {
  for: string[];
}

const ChipTypeProvider = (props: IconTypeProviderProps) => (
  <DataTypeProvider formatterComponent={Formatter} {...props} />
);

export default ChipTypeProvider;
