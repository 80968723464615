import {
  TableFixedColumnsProps,
  Table,
} from '@devexpress/dx-react-grid-material-ui';
import { ColumnBands } from '@devexpress/dx-react-grid';
import { AlertProps } from '@material-ui/lab/Alert';
import { Color, Size } from '@material-ui/core';
import { OptionsObject as SnackbarOptionsObject } from 'notistack';
import { Field, SelectOption, DateRangeValue, File } from './types.Field';
import { IconDefinition, ParsableDate } from './types.common';

/**
 * Высокоуровневый компонент фронта
 */
export type Component =
  | TimelineComponent
  | TableComponent
  | FormComponent
  | GroupOfTextWidget
  | DonutChartWidget
  | BarChartWidget
  | HTMLPrinter;

export interface HTMLPrinter extends BaseComponent<'HTMLPrinter'> {
  props: HTMLPrinterProps;
}

export interface HTMLPrinterProps {
  html: string;
}

export interface BarChartWidget extends BaseComponent<'barChartWidget'> {
  props: BarChartProps;
}

export interface BarChartProps extends BaseWidget {
  data: BarChartDatum[]; // Данные Bar'ов.
  bars: Bar[]; // Конфиги Bar'ов.
}

export interface BarChartDatum {
  name: string; // Название одной ячейки по оси X.
  [key: string]: unknown; // Значения. Ключ === key из Bar, значение - обязательно number!
}

export interface Bar {
  key: string; // системное название свойства.
  name: string; // локализованное название свойства.
  fill: string; // HEX/rgba цвет заливки.
  stackId?: string | number; // произвольная строка или число для группировки нескольких значений в один стек.
}

export interface DonutChartWidget extends BaseComponent<'donutChartWidget'> {
  props: DonutChartProps;
}

export interface DonutChartProps extends BaseWidget {
  data: DonutChartDatum[];
}

export interface DonutChartDatum {
  name: string;
  value: number; // Число‼️
  fill?: string; // html-цвет (hex или rgb или rgba) для заливки
  stroke?: string; // html-цвет (hex или rgb или rgba) для обводки
}

export interface GroupOfTextWidget extends BaseComponent<'groupOfTextWidget'> {
  props: TextWidget[];
}

export interface TextWidget {
  title: string; // заголовок (желательно не более 14 символов)
  metric?: string; // показатель/метрика (желательно не более 8 символов)
  trend?: Trend; // изменения в виде тренда
  description?: string; // надпись справа от тренда (желательно не более 8 символов)
}

export interface Trend {
  value: string; // показатель тренда
  dynamic: TrendDynamic; // позитивные изменения или отрицательные
}

export const ALL_TREND_DYNAMIC = [
  'positive',
  'negative',
  'withoutChanges',
] as const;

type TrendDynamicTuple = typeof ALL_TREND_DYNAMIC;
export type TrendDynamic = TrendDynamicTuple[number];

interface BaseWidget {
  heading?: string;
  showLegend?: boolean;
  showTooltip?: boolean;
  interactionConfig?: WidgetInteractionConfig;
}

export interface WidgetInteractionConfig {
  requestConfig: RequestConfig;
  planeForm?: PlaneFormComponent;
  period?: PeriodFormProps;
}

export interface PeriodFormProps {
  defaultValue?: SelectOption;
  options: SelectOption[];
}

export interface PlaneFormComponent extends BaseComponent<'planeForm'> {
  props: {
    requestOn: 'change'; // в каких ситуациях применять фильтр
    fieldGroups: FieldGroup[];
  };
}

/**
 * TIMELINE
 */
export interface TimelineComponent extends BaseComponent<'timeline'> {
  props: TimelineComponentProps;
}

export type TimelineComponentProps = {
  header: Header;
  addCommentUrl: RequestConfig; // куда отослать свободный коммент (по HTTP)
  timelineSocketConfig: SocketConfig; // куда подписаться для обновлений
  addTimelineElementForm: Field[]; // поля для формы добавления комментария
};

export interface SocketConfig {
  uri: string; // местонахождение хоста. Можно использовать `/` для same origin.
  client?: 'SignalR' | 'io' | null; // default 'SignalR'.
  path?: string | null; // нужно начинать с `/`. К примеру, `/timeline-hub`.
  query?: { [key: string]: string };
}

export type TimelineElement =
  | CurrentUserTimelineElement
  | OtherUserTimelineElement
  | SystemTimelineElement;

export interface CurrentUserTimelineElement
  extends AdvancedTimelineElement<'currentUser'> {}

export interface OtherUserTimelineElement
  extends AdvancedTimelineElement<'otherUser'> {
  header: string; // Заголовок сообщения. Обычно туда нужно писать ФИО автора
}

export type FormHistoryElement = Pick<
  OtherUserTimelineElement,
  'id' | 'body' | 'createdAt' | 'sort'
> &
  Partial<Pick<OtherUserTimelineElement, 'header' | 'author'>>;

export interface SystemTimelineElement extends BaseTimelineElement<'system'> {}

interface AdvancedTimelineElement<T> extends BaseTimelineElement<T> {
  author: TimelineAuthor; // Данные для построения модалки с инфой о авторе
  isAddedManually?: boolean; // признак, что это вручную добавленный комментарий
}

interface BaseTimelineElement<T> {
  id: string | number;
  type: T;
  sort: number; // для сортировки TimelineElement[] на фронте
  body: TimelineElementBody; // основной текст сообщения
  createdAt: ParsableDate;
  quote?: QuotedTimelineElement; // цитируемое сообщение
  attachments?: File[];
  privacy?: SelectOption; // причина, по которой мы видим это сообщение (передавать, если не "всем")
}

export type QuotedTimelineElement = {
  // ИД/гуид цитируемого сообщения (чтобы на фронте можно было к нему проскроллить)
  quotedElementId: string | number;
  header?: string; // Заголовок сообщения. Обычно туда нужно писать ФИО автора
  body: TimelineElementBody;
};

/**
 * {
 *   html: '<div>
 *     <p>Заявка взята в работу.</p>
 *     <p><b>Ответственный:</b> Малогин А.</p>
 *   </div>',
 *   plainText: 'Заявка взята в работу. Ответственный: Малогин А.'
 * }
 */
interface TimelineElementBody {
  html: string; // собранный текст в HTML по шаблонам от аналитика
  plainText: string; // собранный текст в обычную строку (без переносов строк)
}

export type TimelineAuthor = {
  id: string;
  firstName: string;
  secondName?: string;
  lastName: string;
  email: string;
  phone?: string;
  position?: string[]; // локализованные названия должностей.
  department?: string; // подразделение.
  organization?: string; // организация.
};
/**
 * \\ TIMELINE
 */

export type TableComponentProps = {
  requestConfig: RequestConfig; // куда и как стучать для фильтрации
  heading?: string;
  header?: Header;
  columns: TableColumn[];
  columnBands?: ColumnBands[];
  fixedColumns?: Pick<TableFixedColumnsProps, 'leftColumns' | 'rightColumns'>;
  columnExtensions?: Table.ColumnExtension[];
  rows: TableRow[];
  options: TableOptions;
  filterComponent?: TableFilterFormComponent;
  quickFilters?: TableQuickFilters | null;
  showSearchbar?: boolean;
  alerts?: Alert[];
  actions?: TableAction[];
  showTotalRows?: boolean; // показывать ли счётчик строк. По-умолчанию "true".
  showWithClosedButton?: boolean; // показывать кнопку "отображать закрытые". По-умолчанию "false".
  fullHeight?: boolean; // на весь экран? По-умолчанию "true".
  /** Изменение размера колонок на фронте.
   * По-умолчанию работает как "false".
   * Если устанавливаете "true", то в `columnExtensions`
   * крайне желательно явно задать фиксированную ширину для каждой колонки. */
  enableColumnResizing?: boolean;
  /**
   * Включает изменения порядка колонок при помощи drag & drop.
   * По-умолчанию порядок будет взят на основе columns.
   */
  enableColumnReordering?: boolean;
  /**
   * Добавляет возможность пользователю скрывать имена столбцов.
   */
  columnVisibilityConfig?: ColumnVisibilityConfig;
};

export type ColumnVisibilityConfig =
  | EnabledColumnVisibilityConfig
  | DisabledColumnVisibilityConfig;

interface DisabledColumnVisibilityConfig {
  enabled?: false;
}

interface EnabledColumnVisibilityConfig {
  enabled: true;
  /**
   * Массив имен столбцов (name), которые необходимо скрыть в таблице.
   * По-умолчанию, все столбцы отображаются.
   */
  defaultHiddenColumnNames?: string[];
}

export interface TableQuickFilters {
  options: TableQuickFilterOption[];
}

export interface TableQuickFilterOption {
  value: string;
  label: string;
}

export type DataTypeProvidersProps = {
  [key in FormatAs]?: string[];
};

type FilterOption =
  | string // вхождение подстроки
  | number // точное значение
  | string[] // точное значение, логическое ИЛИ
  | DateRangeValue; // рейндж

export type FilterOptions = {
  [key: string]: FilterOption;
};

type SortingObject = {
  columnName: string;
  direction: 'asc' | 'desc';
};

export type SortingState = SortingObject[];

// В таком виде будут уходить данные на сервер
// по фильтрам/сортировкам/поиску/пагинации
export interface FetchTableDataOptions {
  requestConfig: RequestConfig; // куда и как стучать для фильтрации
  query?: string; // поиск
  filter?: FilterOptions; // фильтрация
  sort?: SortingState; // сортировка
  currentPage: number;
  pageSize: number; // строк на страницу
  quickFilters?: string[];
  withClosed?: boolean;
}

interface TableComponent extends BaseComponent<'table'> {
  props: TableComponentProps;
}

export interface TableColumn {
  title?: string; // локализованное название колонки. Если не передать все - не отобразится ничего.
  name: string;
  options?: TableColumnOptions;
}

interface TableColumnOptions {
  disableLineBreak?: boolean;
  sortable?: boolean;
  formatAs?: FormatAs;
}

// см. https://stackoverflow.com/a/45486495
export const ALL_FORMATS = [
  'date',
  'dateTime',
  'cutTextWithTooltip',
  'icon',
  'chip',
  'link',
] as const;
type FormatAsTuple = typeof ALL_FORMATS;
export type FormatAs = FormatAsTuple[number];

export interface TableRow {
  id: string | number;
  viewConfig?: TableRowViewConfig;
  // Если задано тут, то применится отсюда, иначе rowClickReaction из TableOptions.
  rowClickReaction?: Reaction;

  [key: string]: any | IconDefinition | Chip | Link;
}

export interface Chip {
  label: string;
  avatar?: Avatar;
  disabled?: boolean;
  color?: Exclude<Color, 'inherit'>;
  clickable?: boolean;
  icon?: IconDefinition | null;
  size?: Size;
  variant?: 'default' | 'outlined';
  htmlColor?: string;
}

export type Avatar = { fullName: string } | { src: string; alt: string };

export interface TableRowViewConfig {
  bold?: boolean;
  backgroundColor?: string;
  color?: string;
}

interface TableOptions {
  // Всего строк
  totalRows: number;
  // Текущая страница
  currentPage: number;
  // Количество строк на страницу. По-умолчанию 10.
  // Сервер должен отдавать по 10 строк по-умолчанию.
  pageSize: number;
  // Массив с параметрами для селектора управления количеством строк на страницу.
  // По-умолчанию селектор не отображается.
  pageSizeOptions?: number[];
  // Если задано тут и в row, то применится из row, а это проигнорируется.
  rowClickReaction?: Reaction;
  cellClickReaction?: TableCellClickReaction;
  sorting?: SortingState;
}

export interface Alert
  extends Pick<AlertProps, 'color' | 'severity' | 'variant'> {
  body: Paragraph[];
  title?: string;
}

export interface Paragraph {
  text: string;
  title?: string;
}

export type TableCellClickReaction =
  | ShowAsyncModalOnCellClickReaction
  | Reaction;

// Реакция, которая позволяет при клике на ячейку построить модалку на основе
// данных, которые придут с сервера.
export interface ShowAsyncModalOnCellClickReaction
  extends BaseReaction<'showAsyncModalOnCellClickReaction'> {
  fetchModalDataRequestConfig: RequestConfig; // куда стучать для получения данных модалки.
  columnNames: string[]; // для ячеек в каких колонках навешивать обработчики клика.
}

// 1. При клики на ячейку в обработчик упала информация о rowId и columnName. Постучали на бек по fetchModalDataRequestConfig и передали туда инфу о координатах ячейки (rowId и columnName).
// Пример запроса - '/api/v1.0/table/get-cell-modal?columnName="jan-4"&rowId="5dfcd7f1-0a83-449d-9799-4219bbed26f4'
//
// 2. После получения успешного ответа с сервера, из ответа выцепляем интерфейс ShowAsyncModalResponse.

// Ответ с сервера для постоения модалки.
export interface ShowAsyncModalResponse {
  component: Component;
}

export interface TableFilterFormComponent extends BaseComponent<'tableFilter'> {
  props: {
    requestOn: 'change'; // в каких ситуациях применять фильтр
    fieldGroups: FieldGroup[];
  };
}

export type FormComponentProps = {
  form: Form;
  actions?: FormAction[];
  actionsYPosition?: 'top' | 'bottom';
  alerts?: Alert[];
};

interface FormComponent extends BaseComponent<'form'> {
  props: FormComponentProps;
}

export interface Header {
  heading: string;
  preHeadingText?: string;
  preHeadingIcon?: IconDefinition | null;
  subHeadings?: SubHeadingElement[];
  headingClassName?: string;
}

type SubHeadingElement = [string | undefined | null, SubHeadingElementValue];

export type SubHeadingElementValue =
  | string // просто строка
  | number // просто число
  | ConvertibleValue;

type ConvertibleValue = ConvertibleDate | ConvertibleLink;

export type ConvertibleLink = BaseConvertibleValue<'link'> & Link;

interface ConvertibleDate extends BaseConvertibleValue<'date'> {
  value: string;
  formatAs?: 'date' | 'dateTime'; // 'date' by default.
}

interface BaseConvertibleValue<T> {
  type: T;
}

export type Link = {
  href: string;
  label: string;
  icon?: string | null;
  external?: true; // ссылка куда-то вне приложения (без react-router)
};

interface BaseComponent<T> {
  type: T;
  id: number;
  props: unknown;
}

// Кнопки бывают:
// 1. Дёргают что-то на сервере и либо
//     обновляют модель данных, либо редиректят
//
// 2. Показывают модалку с формой,
//     после сабмита либо обновляют модель данных либо редиректят.
//
// 4. Переадресовывает на форму,
//     после сабмита либо обновляют модель данных либо редиректят.

export type TableAction = BaseAction;

export type FormAction = SubmitFormAction | BaseAction;

type BaseAction =
  | TriggerEndpointAction
  | ShowFormAction
  | RedirectAction
  | GoBackAction;

/**
 * Экшны
 */
export interface GoBackAction extends BaseFormAction<'goBack'> {}

// Редиректит на указанный URL. URL может быть как внутренним,
// так и внешним.
export interface RedirectAction extends BaseFormAction<'redirect'> {
  redirectTo: string;
}

// Проводит сабмит формы (клиентская валидация, мап значений)
// и отправляет значения формы в теле запроса на сервер.
export interface SubmitFormAction extends RequestFormAction<'submitForm'> {}

// Вызывает запрос на сервер, без передачи каких-либо данных
// на сервер.
export interface TriggerEndpointAction
  extends RequestFormAction<'triggerEndpoint'> {}

// Показывает форму в модалке.
export interface ShowFormAction extends BaseFormAction<'showForm'> {
  actions: FormAction[];
  form: Form;
  viewStyle?: 'modal' | 'fullScreenModal';
  alerts?: Alert[];
}

/**
 * \\ Экшны
 */

/**
 * Типы экшнов
 */
interface RequestFormAction<T> extends BaseFormAction<T> {
  requestConfig: RequestConfig;
  successResponseReaction: Reaction;
  validationErrorReaction?: Reaction;
}

interface BaseFormAction<T> {
  type: T;
  label: string; // название кнопки
  color?: 'inherit' | 'primary' | 'secondary' | 'default'; // цветовая тема кнопки
  variant?: 'text' | 'outlined' | 'contained'; // стиль кнопки
  icon?: IconDefinition | null;
}

/**
 * \\ Типы экшнов
 */

export interface RequestConfig {
  url: string;
  method?: 'GET' | 'POST';
}

export type Reaction =
  | DownloadFileReaction
  | AsyncShowComponentInModalReaction
  | ShowAlertAndRedirectReaction
  | ReLoadModuleReaction
  | DynamicRedirectReaction
  | AsyncRedirectReaction
  | RedirectReaction
  | RenderPageReaction;

// Тип реакции, при начинается загрузка файла.
// В ответе сервера ожидается url для загрузки с правильно
// настроенными заголовками Content-Type и Content-Disposition
export interface DownloadFileReaction extends BaseReaction<'downloadFile'> {}

// Тип реакции, при которой показывается модалка с компонентом (любым).
// В ответе сервера ожидается интерфейс Component.
export interface AsyncShowComponentInModalReaction
  extends BaseReaction<'asyncShowComponentInModal'> {}

export interface ShowAlertAndRedirectReaction
  extends BaseReaction<'showAlertAndRedirect'> {
  alertText: string;
  redirectTo: string;
}

interface ReLoadModuleReaction extends BaseReaction<'reLoadModule'> {}

// Тип редиректа, когда данные для построения url доступны на фронте и нужно
// указать что за ключ у базовой сущности нужно заменить.
//
// К примеру, если реакция описывает редирект, который должен произойти после
// клика по строке в таблице, то для построения урла карточки элемента, по
// которому произошёл клик понадобится его ID. ID хранится в этой строке.
//
// См. пример данных любого списка (список заявок/заданий на ремонт).
export interface DynamicRedirectReaction
  extends BaseReaction<'dynamicRedirect'> {
  // строка вида //api/domain/:entityProperty, где 'entityProperty'
  // это свойство сущности, от которой произошла реакция.
  redirectTo: string;
}

// Тип редиректа, когда url содержится в JSON-теле ответа сервера.
//
// Это бывает нужно, когда только после вызова эндпоинта становится понятно,
// куда нужно редиректить.
//
// К примеру, создалась новая сущность.
export interface AsyncRedirectReaction extends BaseReaction<'asyncRedirect'> {
  responseRedirectKey: string;
}

// Самый простой тип редиректа с явным указанием url.
export interface RedirectReaction extends BaseReaction<'redirect'> {
  redirectTo: string;
}

export interface RenderPageReaction extends BaseReaction<'renderPage'> {}

interface BaseReaction<T> {
  type: T;
  snackbar?: Snackbar;
}

export interface Snackbar {
  text: string;
  options?: SnackbarOptionsObject & { link?: Link };
  // action: any; // Будет потом, пока нет потребности
}

export type Form = PresetForm | MarkupForm;

interface PresetForm extends BaseForm<'preset'> {
  presetName: 'estimate';
}

interface MarkupForm extends BaseForm<'markup'> {
  fieldGroups: FieldGroup[];
}

interface BaseForm<T> {
  id: number | string;
  type: T;
  disabled?: boolean;
  header: Header;
  historyConfig?: FormHistoryConfig;
}

export interface FormHistoryConfig {
  requestConfig: RequestConfig;
}

export interface FieldGroup {
  label?: string;
  header?: Header;
  accordion?: AccordionProps;
  fields: Field[];
}

export interface AccordionProps {
  enabled: boolean;
  defaultExpanded: boolean;
}

export interface FormValues {
  [key: string]: string | string[] | number | boolean | null;
}

export interface FilterChip {
  fieldName: string;
  printValue: string;
  dirtyValue: DirtyFormValue;
}

// Возможные варианты, которые лежат в состоянии формы
export type DirtyFormValue =
  | string
  | string[]
  | SelectOption
  | SelectOption[]
  | DateRangeValue
  | number
  | boolean
  | null;

export interface DirtyFormValues {
  [key: string]: DirtyFormValue;
}
