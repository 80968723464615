import React, { useState, useMemo } from 'react';
import DependentSelectView from './DependentSelect.view';
import { DependentSelectContainerProps } from './types';
import useSubscribe from './useSubscribe';
import getCurrentValue from './getCurrentValue';

const DependentSelect = (props: DependentSelectContainerProps) => {
  const { watchOn, dependOn } = props;
  const [
    reFetchOptionsCountAndReason,
    setReFetchOptionsCountAndReason,
  ] = useState<string>('0_start');

  const [dependOnFieldValues] = useSubscribe({
    setReFetchOptionsCount: setReFetchOptionsCountAndReason,
    reason: 'parentChanged',
    subscribeOn: dependOn,
    currentFieldName: props.name,
  });
  const [watchOnFieldValues] = useSubscribe({
    setReFetchOptionsCount: setReFetchOptionsCountAndReason,
    reason: 'childChanged',
    subscribeOn: watchOn,
    currentFieldName: props.name,
  });

  const isParentEmpty = useMemo(
    () =>
      dependOnFieldValues
        ? Object.entries(dependOnFieldValues).some(([, fieldValue]) =>
            Array.isArray(fieldValue) ? fieldValue.length === 0 : !fieldValue
          )
        : false,
    [dependOnFieldValues]
  );

  const currentValue = getCurrentValue(props.value, props.multiple);

  return (
    <DependentSelectView
      {...props}
      isParentEmpty={isParentEmpty}
      reFetchOptionsCountAndReason={reFetchOptionsCountAndReason}
      allDependencies={{
        ...dependOnFieldValues,
        ...watchOnFieldValues,
        currentValue,
      }}
      dependOnFieldValues={dependOnFieldValues}
    />
  );
};

export default DependentSelect;
