import React, { useState } from 'react';
import {
  BarChart,
  Bar as BarComponent,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import {
  BarChartDatum,
  Bar,
  BarChartProps,
} from '../../../services/Main/types.Component';
import {
  HEADING_HEIGHT,
  PERIOD_BAR_HEIGHT,
} from '../DonutChartWidget/constants';
import WidgetContainer from '../../lowLevel/WidgetContainer';
import getMinHeight from '../DonutChartWidget/getMinHeight';
import EmptyWidgetDataText from '../../lowLevel/EmptyWidgetDataText';

interface State {
  bars: Bar[];
  data: BarChartDatum[];
}

export default ({
  data: initialData,
  bars: initialBars,
  heading,
  showLegend,
  showTooltip,
  interactionConfig,
}: BarChartProps) => {
  const [bars, setBars] = useState<Bar[]>(initialBars);
  const [data, setData] = useState<BarChartDatum[]>(initialData);
  const minHeight = getMinHeight(
    300,
    !!heading,
    !!showLegend,
    !!interactionConfig?.period
  );

  const handleStateChange = ({ bars: newBars, data: newData }: State) => {
    setBars(newBars);
    setData(newData);
  };

  return (
    <WidgetContainer<State>
      style={{ width: '100%', height: '100%', minHeight }}
      heading={heading}
      interactionConfig={interactionConfig}
      handleStateChange={handleStateChange}
    >
      {({ width, height }) => {
        const chartHeight = (() => {
          let result = height;

          if (heading) result -= HEADING_HEIGHT;
          if (interactionConfig?.period) result -= PERIOD_BAR_HEIGHT;

          return result;
        })();

        return data && Array.isArray(data) && data.length > 0 ? (
          <BarChart
            width={width}
            height={chartHeight}
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            {showTooltip && <Tooltip />}
            {showLegend && <Legend />}
            {bars.map(({ key, fill, name, stackId }) => (
              <BarComponent
                key={key}
                dataKey={key}
                stackId={stackId}
                fill={fill}
                name={name}
              />
            ))}
          </BarChart>
        ) : (
          <EmptyWidgetDataText height={chartHeight} width={width} />
        );
      }}
    </WidgetContainer>
  );
};
