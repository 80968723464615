import React, { createContext, PropsWithChildren, ReactElement } from 'react';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import hash from 'object-hash';
import { GridItem } from '../../../services/Main/types.GridItem';

interface GridBuilderProps<T extends GridItem> {
  markup: T[];
  renderGridItem: (rest: Omit<T, 'mdCols' | 'mdOffset'>) => ReactElement;
  spacing?: GridSpacing;
}

export const GridItemContext = createContext<Pick<
  GridItem,
  'mdCols' | 'mdOffset'
> | null>(null);

const GridBuilder = <T extends GridItem>({
  markup,
  renderGridItem,
  spacing,
}: PropsWithChildren<GridBuilderProps<T>>) => (
  // 26.04.2021 Здесь раньше был xs={12}, но он конфликтует с spacing.
  // spacing делает width: calc(100% + {spacing * 8}), а
  // xs={12} добавляет свойство max-width: 100%.
  <Grid container item spacing={spacing || 1}>
    {markup.map(({ mdCols, mdOffset, ...rest }) => (
      <GridItemContext.Provider value={{ mdCols, mdOffset }} key={hash(rest)}>
        {mdOffset && (
          <Grid
            item
            md={mdOffset}
            implementation="css"
            smDown
            component={Hidden}
          />
        )}
        <Grid item xs={12} md={mdCols || 12} hidden={rest.hidden}>
          {renderGridItem(rest)}
        </Grid>
      </GridItemContext.Provider>
    ))}
  </Grid>
);

export default GridBuilder;
