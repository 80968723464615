import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import mainService from 'services/Main';
import {
  Reaction,
  RedirectReaction,
  SubmitFormAction,
  TriggerEndpointAction,
} from 'services/Main/types.Component';
import store from '../../../store';
import { PageContext } from '../../creational/PageLoader/PageLoader';
import Icon from '../Icon';
import ReactionController from '../../../responseReactions/ReactionController';
import { mapValuesToPlain } from '../FormBuilder/helpers';
import DialogFormAction from './DialogFormAction';
import { FormActionProps } from './types';
import redirectReaction from '../../../responseReactions/redirect';
import FormBuilderContext, {
  FormSubmitHandler,
} from '../FormBuilder/FormBuilderContext';
import { DialogAlertContext } from '../DialogAlert/DialogAlert';
import useIsSomeActionExecuting from './useIsSomeActionExecuting';
import history from '../../../utils/history';
import { alertsFromActionServerResponseActions } from '../../../store/alertsFromActionServerResponse';
import { metaActions } from '../../../store/meta';
import { ComponentContext } from '../../creational/ComponentLoader';
import useEnqueueSnackbar from '../../../utils/hooks/useEnqueueSnackbar';
import FormActionButton from './FormAction.Button';

export default (props: FormActionProps) => {
  const { action, onReactionExecute } = props;
  const dispatch = useDispatch();
  const { type, label, color, icon, variant, ...rest } = action;
  const pageContext = useContext(PageContext);
  const componentContext = useContext(ComponentContext);
  const formBuilderContext = useContext(FormBuilderContext);
  const { isSomeActionExecuting, setIsSomeActionExecuting } =
    useIsSomeActionExecuting();
  const {
    onSubmitHandlers,
    setOnSubmitHandlers,
    setFiredSubmitAction,
    fields,
  } = useContext(FormBuilderContext);
  const enqueueSnackbar = useEnqueueSnackbar();
  const dialogAlertContext = useContext(DialogAlertContext);

  // Показать форму, со своими экшнами в модалке, выполнить реакцию по сабмиту.
  if (type === 'showForm') return <DialogFormAction {...props} />;

  /** submitForm */
  // Получить урл,
  // сделать запрос с данными формы,
  // выполнить реакцию (колбек)
  useEffect(() => {
    if (type === 'submitForm' && !onSubmitHandlers) {
      // устанавливаем handleSubmit в rhf (см. FormBuilder)
      const handler: FormSubmitHandler = (dirtyValues: any) => {
        setIsSomeActionExecuting(true);

        mainService
          .makeActionRequest(
            (rest as SubmitFormAction).requestConfig,
            mapValuesToPlain(dirtyValues, fields)
          )
          .then(
            ({
              payload,
              snackbar,
              dialogAlert,
              preventSuccessResponseReactionReasons,
            }) => {
              dispatch(metaActions.setIsSomeActionExecuting(false));

              if (snackbar) enqueueSnackbar(snackbar.text, snackbar.options);
              if (dialogAlert) dialogAlertContext.setDialogState(dialogAlert);

              const alertsId = `${componentContext.id}_${formBuilderContext.id}`;

              // TODO везде (здесь, ниже, в TableAction) сделать следующую проверку:
              // if (preventSuccessResponseReactionReasons && !Array.isArray(preventSuccessResponseReactionReasons)) {
              //   throw new Error('preventSuccessResponseReactionReasons must be an array');
              // }
              if (
                preventSuccessResponseReactionReasons &&
                Array.isArray(preventSuccessResponseReactionReasons)
              ) {
                dispatch(
                  alertsFromActionServerResponseActions.setForPage({
                    id: alertsId,
                    alerts: preventSuccessResponseReactionReasons,
                  })
                );
                return;
              }

              if (
                store.getState().alertsFromActionServerResponse[alertsId]
                  ?.length > 0
              ) {
                dispatch(alertsFromActionServerResponseActions.clear(alertsId));
              }

              new ReactionController(
                (rest as SubmitFormAction).successResponseReaction,
                pageContext as PageContext,
                enqueueSnackbar,
                payload
              ).execute(onReactionExecute);
            }
          )
          .catch((error) => {
            if (error.response.status === 422) {
              if (error.response?.data?.snackbar) {
                enqueueSnackbar(
                  error.response?.data?.snackbar.text,
                  error.response?.data?.snackbar.options
                );
              }

              if (error.response?.data?.dialogAlert) {
                if (error.response?.data?.dialogAlert)
                  dialogAlertContext.setDialogState(
                    error.response.data.dialogAlert
                  );
              }

              if ((rest as SubmitFormAction).validationErrorReaction) {
                new ReactionController(
                  (rest as SubmitFormAction)
                    .validationErrorReaction as Reaction,
                  pageContext as PageContext,
                  enqueueSnackbar,
                  error.response
                ).execute(onReactionExecute);
              }
            }

            console.log('Ошибка', error);
          });
      };

      setOnSubmitHandlers((prevHandlers) => {
        return {
          ...prevHandlers,
          [(rest as SubmitFormAction).requestConfig.url]: handler,
        };
      });
    }
    // eslint-disable-next-line
  }, []);
  /** \\submitForm */

  const handleClick = (() => {
    switch (type) {
      // showForm обрабатываем выше.
      // case 'showForm':
      //   return () => console.log(type, rest);

      case 'submitForm':
        return () => {
          setFiredSubmitAction((rest as SubmitFormAction).requestConfig.url);
        };

      // Получить урл, сделать запрос,
      // выполнить реакцию (колбек)
      case 'triggerEndpoint':
        return () => {
          setIsSomeActionExecuting(true);
          mainService
            .makeActionRequest((rest as TriggerEndpointAction).requestConfig)
            .then(
              ({
                payload,
                snackbar,
                dialogAlert,
                preventSuccessResponseReactionReasons,
              }) => {
                if (snackbar) enqueueSnackbar(snackbar.text, snackbar.options);
                if (dialogAlert) dialogAlertContext.setDialogState(dialogAlert);

                const alertsId = `${componentContext.id}_${formBuilderContext.id}`;

                if (
                  preventSuccessResponseReactionReasons &&
                  Array.isArray(preventSuccessResponseReactionReasons)
                ) {
                  dispatch(
                    alertsFromActionServerResponseActions.setForPage({
                      id: alertsId,
                      alerts: preventSuccessResponseReactionReasons,
                    })
                  );
                  dispatch(metaActions.setIsSomeActionExecuting(false));
                  return;
                }

                if (
                  store.getState().alertsFromActionServerResponse[alertsId]
                    ?.length > 0
                ) {
                  dispatch(
                    alertsFromActionServerResponseActions.clear(alertsId)
                  );
                }

                new ReactionController(
                  (rest as TriggerEndpointAction).successResponseReaction,
                  pageContext as PageContext,
                  enqueueSnackbar,
                  payload
                ).execute(onReactionExecute);
              }
            )
            .catch((error) => {
              if (error.response.status === 422) {
                if (error.response?.data?.snackbar) {
                  enqueueSnackbar(
                    error.response?.data?.snackbar.text,
                    error.response?.data?.snackbar.options
                  );
                }

                if (error.response?.data?.dialogAlert) {
                  if (error.response?.data?.dialogAlert)
                    dialogAlertContext.setDialogState(
                      error.response.data.dialogAlert
                    );
                }

                if ((rest as TriggerEndpointAction).validationErrorReaction) {
                  new ReactionController(
                    (rest as TriggerEndpointAction)
                      .validationErrorReaction as Reaction,
                    pageContext as PageContext,
                    enqueueSnackbar,
                    error.response
                  ).execute(onReactionExecute);
                }
              }

              console.log('Ошибка', error);
            });
        };

      case 'redirect':
        return () => {
          redirectReaction(rest as RedirectReaction);
        };

      case 'goBack':
        return () => {
          history.goBack();
        };

      default:
        return () => console.log('CLICK CLOCK');
    }
  })();

  return (
    <>
      <Grid item>
        <FormActionButton
          variant={variant}
          color={color}
          type={type === 'submitForm' ? 'submit' : 'button'}
          onClick={handleClick}
          disabled={isSomeActionExecuting}
          startIcon={icon && <Icon definition={icon} />}
        >
          {label}
        </FormActionButton>
      </Grid>
    </>
  );
};
