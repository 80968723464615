import React from 'react';
import { Plugin } from '@devexpress/dx-react-core';
import { DataTypeProvidersProps } from 'services/Main/types.Component';
import DateTypeProvider from '../DateTypeProvider';
import DateTimeTypeProvider from '../DateTimeTypeProvider';
import CutTextWithTooltipTypeProvider from '../CutTextWithTooltipTypeProvider';
import IconTypeProvider from '../IconTypeProvider';
import ChipTypeProvider from '../ChipTypeProvider';
import LinkTypeProvider from '../LinkTypeProvider';

/**
 * Плагин DevExpress для форматирования ячеек.
 *
 * Вместо того, чтобы под каждую ячейку писать форматтер
 * в Table.tsx, форматтеры назначаются тут, а в Table.tsx
 * подключаются одной строкой.
 *
 * Компонент служит чисто для поддержания Table.tsx компактным.
 *
 * Подробную информацию можно посмотреть в доках DevExpress:
 * https://devexpress.github.io/devextreme-reactive/react/grid/docs/guides/plugin-overview/#combining-multiple-plugins
 *
 * Для добавления нового форматера, нужно:
 * 1. Добавить новый вариант в ALL_FORMATS.
 * 2. Создать компонент-форматтер по аналогии с <DateTypeProvider for={date} />
 */
const DataTypeProviders = ({
  date,
  dateTime,
  cutTextWithTooltip,
  icon,
  chip,
  link,
}: DataTypeProvidersProps) => {
  if (!date && !dateTime && !cutTextWithTooltip && !icon) return null;

  return (
    <Plugin>
      {date && <DateTypeProvider for={date} />}
      {dateTime && <DateTimeTypeProvider for={dateTime} />}
      {cutTextWithTooltip && (
        <CutTextWithTooltipTypeProvider for={cutTextWithTooltip} />
      )}
      {icon && <IconTypeProvider for={icon} />}
      {chip && <ChipTypeProvider for={chip} />}
      {link && <LinkTypeProvider for={link} />}
    </Plugin>
  );
};

export default DataTypeProviders;
