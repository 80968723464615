import { useMemo } from 'react';
import isEqual from 'lodash.isequal';
import * as yup from 'yup';
import { FieldGroup } from '../../../services/Main/types.Component';
import {
  ALL_VALIDATION_TYPES,
  ArrayOf,
  DependentSelect,
  Field,
  Select,
  Validation,
  ValidationParams,
  WithWhenValidationRule,
} from '../../../services/Main/types.Field';

const yupReducer = (acc: any, { name, validationConfig, ...rest }: Field) => {
  if (!validationConfig) return acc;

  const { validationType: initialValidationType, validations } =
    validationConfig;
  let validationType = initialValidationType;

  // Случай, когда с бека прилетает валидация для single dependent select.
  // Технически все dependent select работают как multiple, но бек об этом
  // не знает и мыслит фразой: "Если прилетает объект, то валидируем как mixed".
  if (
    rest.type === 'select' &&
    (rest as Select).selectType === 'dependent' &&
    !(rest as DependentSelect).multiple &&
    initialValidationType === 'mixed'
  ) {
    validationType = 'array';
  }

  // Случай, когда с бека прилетает валидация для combobox.
  // Технически все combobox работают как multiple, но бек об этом
  // не знает и мыслит фразой: "Если прилетает объект, то валидируем как mixed".
  if (rest.type === 'comboBox' && initialValidationType === 'mixed') {
    validationType = 'array';
  }

  if (!ALL_VALIDATION_TYPES.includes(validationType)) {
    console.error('Не удалось определить тип валидатора.');
    return acc;
  }

  // @ts-ignore
  let fieldValidators: any = yup[validationType]();

  // Исправляет ошибку из-за которой валидация
  // происходит по некорректному дефолтному значению
  if (
    validationType === 'date' ||
    validationType === 'number' ||
    validationType === 'array'
  ) {
    fieldValidators = fieldValidators.nullable(true);
  }

  // Исправляет ошибку, из-за которой пустая строка
  // в `number` трансформировалась в NaN.
  if (validationType === 'number') {
    fieldValidators = fieldValidators.transform((v: any, o: any) =>
      o === '' ? null : v
    );
  }

  validations.forEach(({ type, params }: Validation) => {
    if (type === 'of') {
      if (rest.type !== 'arrayOf') {
        throw new Error(
          `Некорректное использование of типа валидации. Его нужно использовать только для ArrayOf типов полей, а используется для ${rest.type} в поле ${name}`
        );
      }

      // Собираем коллекцию, где ключ — name поля, значение - Field.
      const arrayOfFieldsByName = new Map<string, Field>(
        (rest as ArrayOf).rowDefinition.map((f) => [f.name, f])
      );

      const subFields: Field[] = (
        params[0] as Pick<Field, 'name' | 'validationConfig'>[]
      ).reduce<Field[]>((subAcc, validationParam) => {
        const arrayOfFieldNotation = arrayOfFieldsByName.get(
          validationParam.name
        );

        if (!arrayOfFieldNotation) {
          throw new Error(
            `Некорректный name в validationConfig для ArrayOf, ругаемся на: ${validationParam.name}`
          );
        }

        return [
          ...subAcc,
          {
            ...arrayOfFieldNotation,
            validationConfig: validationParam.validationConfig,
          },
        ];
      }, []);

      const subSchema = (subFields as Field[]).reduce(yupReducer, {} as any);

      fieldValidators = fieldValidators[type](yup.object().shape(subSchema));
    } else if (type === 'when') {
      // NOTE ➡️: В 'when' не поддерживаются вложенные 'of' и 'when'!
      const leadFieldName = params[0];
      const { is, then, otherwise } = (params as WithWhenValidationRule)[1];
      // NEEDS REFACTOR 🛠: повторяющиеся циклы, на 54-63. Мутации.
      // @ts-ignore
      let thenValidations: any = yup[validationType]();
      then.forEach(({ type: t, params: p }) => {
        // TODO эти две 'matches' переписать на рекурсию.
        if (t === 'matches') {
          thenValidations = validateWithRegexp(
            p[0] as string,
            fieldValidators,
            t,
            p
          );
        } else {
          thenValidations = thenValidations[t](...p);
        }
      });

      // @ts-ignore
      let otherwiseValidations: any = yup[validationType]();
      otherwise?.forEach(({ type: t, params: p }) => {
        // TODO эти две 'matches' переписать на рекурсию.
        if (t === 'matches') {
          otherwiseValidations = validateWithRegexp(
            p[0] as string,
            fieldValidators,
            t,
            p
          );
        } else {
          otherwiseValidations = otherwiseValidations[t](...p);
        }
      });

      fieldValidators = fieldValidators[type](leadFieldName, {
        is: (fieldValue: any) => {
          // Здесь можно проверять на разные is,
          // в зависимости от потребности.
          return isEqual(fieldValue, is) || fieldValue?.value === is;
        },
        then: thenValidations,
        otherwise: otherwiseValidations,
      });
    } else if (type === 'matches') {
      if (typeof params[0] !== 'string' || typeof params[1] !== 'string') {
        throw new Error(`Некорректные params в validationConfig поля ${name}`);
      }

      fieldValidators = validateWithRegexp(
        params[0],
        fieldValidators,
        type,
        params
      );
    } else {
      fieldValidators = fieldValidators[type](...params);
    }
  });

  return { ...acc, [name]: fieldValidators };
};

function validateWithRegexp(
  regexString: string,
  fieldValidators: any,
  type: string,
  params: ValidationParams
) {
  // Конвертируем строку с регуляркой в экземпляр RegExp
  const flags = regexString.replace(/.*\/([gimy]*)$/, '$1');
  const pattern = regexString.replace(new RegExp(`^/(.*?)/${flags}$`), '$1');
  const regex = new RegExp(pattern, flags);

  return fieldValidators[type](regex, {
    message: params[1],
    excludeEmptyString: true, // всегда исключаем пустые строки (с пробелами)
  });
}

export default (fieldGroups: FieldGroup[]) =>
  useMemo(() => {
    const fields: Field[] = fieldGroups.reduce(
      (acc, group) => [...acc, ...group.fields],
      [] as Field[]
    );

    const formValidators = fields.reduce(yupReducer, {} as any);

    return yup.object().shape(formValidators);
  }, [fieldGroups]);
