import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import MuiGrid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {
  DirtyFormValues,
  FilterChip,
  RequestConfig,
  TableFilterFormComponent,
  Header as HeaderProps,
  TableAction,
  Alert as AlertProps,
  TableQuickFilters,
} from 'services/Main/types.Component';
import SearchInput, { SearchInputProps } from '../SearchInput';
import useStyles from './TableModules.styles';
import TableFilters from '../TableFilters';
import TableFilterChips from '../TableFilterChips';
import Header from '../Header';
import { getHeader } from './helpers';
import TableActionsComponent from '../TableActions';
import TableExtraActions from '../TableExtraActions';
import TableQuickFiltersComponent from '../TableQuickFilters';
import { useFormatMessage } from '../../../locale';
import Alerts from '../Alerts';
import TableWithClosedButton from '../TableWithClosedButton';
import { TableContext } from '../../highLevel/Table';
import TableColumnVisibilityButton from '../TableColumnVisibilityButton';

export interface TableFilterFormProps extends TableFilterFormComponent {
  setFilter: (newFilterState: DirtyFormValues | undefined) => void;
  setFilterChips: Dispatch<SetStateAction<FilterChip[] | undefined>>;
  filter?: DirtyFormValues;
  filterChips?: FilterChip[];
}

type TableModulesProps = {
  totalRows: number;
  heading?: string;
  header?: HeaderProps;
  searchInputProps: SearchInputProps;
  requestConfig: RequestConfig;
  tableFilterFormProps?: Omit<
    TableFilterFormProps,
    'filterChips' | 'setFilterChips'
  >;
  showSearchbar?: boolean;
  actions?: TableAction[];
  alerts?: AlertProps[];
  showTotalRows?: boolean;
  quickFilters?: TableQuickFilters | null;
};

const TableModules = ({
  totalRows,
  heading,
  header: headerProps,
  searchInputProps,
  tableFilterFormProps,
  showSearchbar,
  actions,
  alerts,
  showTotalRows = true,
  quickFilters,
}: TableModulesProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const tableContext = useContext(TableContext);

  const header = getHeader(headerProps, heading);
  const [filterChips, setFilterChips] = useState<FilterChip[] | undefined>();

  return (
    <>
      <Box className={classes.tableModulesContainer}>
        {quickFilters && <TableQuickFiltersComponent {...quickFilters} />}
        <MuiGrid container spacing={1}>
          <MuiGrid item xs={12} md={7}>
            {header && <Header {...header} />}
            {showTotalRows && (
              <Typography className={classes.quantity}>
                {`${formatMessage('total')}: ${totalRows}`}
              </Typography>
            )}
          </MuiGrid>
          <MuiGrid item container xs={12} md={5} spacing={1}>
            {actions && Array.isArray(actions) && actions.length > 0 && (
              <MuiGrid
                container
                spacing={1}
                justify="flex-end"
                className={classes.actionsContainer}
              >
                <TableActionsComponent actions={actions} />
              </MuiGrid>
            )}
            <MuiGrid
              className={classes.utilityActionsWrapper}
              container
              alignItems="flex-start"
              justify="flex-end"
              spacing={1}
            >
              {showSearchbar && (
                <MuiGrid item>
                  <SearchInput
                    className={classes.searchInput}
                    {...searchInputProps}
                  />
                </MuiGrid>
              )}
              {tableContext?.showWithClosedButton && (
                <MuiGrid item>
                  <TableWithClosedButton />
                </MuiGrid>
              )}
              {tableContext?.columnVisibilityConfig?.enabled && (
                <MuiGrid item>
                  <TableColumnVisibilityButton />
                </MuiGrid>
              )}
              {tableFilterFormProps && (
                <TableFilters
                  {...tableFilterFormProps}
                  filterChips={filterChips}
                  setFilterChips={setFilterChips}
                />
              )}
              <TableExtraActions />
            </MuiGrid>
          </MuiGrid>
          <Alerts staticAlerts={alerts} />
          {tableFilterFormProps && (
            <TableFilterChips
              {...tableFilterFormProps}
              filterChips={filterChips}
              setFilterChips={setFilterChips}
            />
          )}
        </MuiGrid>
      </Box>
    </>
  );
};

export default TableModules;
