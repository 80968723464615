import React from 'react';
import {
  Dialog,
  Typography,
  Grid,
  IconButton,
  Toolbar,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DocViewer from 'react-doc-viewer';
import { File } from '../../../../services/Main/types.Field';
import { getFileUrl } from '../DropZoneFileUpload/helpers';
import DocViewerRenderers from './plugins';
import useStyles from './FileViewer.styles';

interface FileViewerProps {
  open: boolean;
  file: File;
  handleClose: () => void;
}

const FileViewer = ({ open, file, handleClose }: FileViewerProps) => {
  const classes = useStyles();

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <Toolbar className={classes.toolbar}>
        <Grid container alignItems="center" justify="flex-end" wrap="nowrap">
          <Grid item className={classes.fileName}>
            <Typography variant="h6" noWrap>
              {file.name}
            </Typography>
          </Grid>
          <Grid item id="fileViewerToolbar" wrap="nowrap" />
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
      <DocViewer
        pluginRenderers={DocViewerRenderers}
        documents={[{ uri: getFileUrl(file.id), fileType: file.mimeType }]}
        className={classes.docViewer}
        config={{
          header: {
            disableHeader: true,
          },
        }}
      />
    </Dialog>
  );
};

export default FileViewer;
