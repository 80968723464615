import React from 'react';
import clsx from 'clsx';
import MuiAvatar, { AvatarProps } from '@material-ui/core/Avatar';
import useStyles from './Avatar.styles';

interface Props extends AvatarProps {
  name: string;
}

const ALLOWED_COLORS = [
  '#d73d32',
  '#7e3794',
  '#4285f4',
  '#71d03c',
  '#d61a7f',
  '#ff4080',
  '#611b1b',
  '#2b8a1a',
  '#e8a102',
];

const stringAsciiPRNG = (value: string, m: number): number => {
  const charCodes = value.split('').map((letter) => letter.charCodeAt(0));
  const len = charCodes.length;

  const a = (len % (m - 1)) + 1;
  const c = charCodes.reduce((current, next) => current + next) % m;

  let random = charCodes[0] % m;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) random = (a * random + c) % m;

  return random;
};

const getRandomColor = (value: string, colors = ALLOWED_COLORS): string => {
  const colorIndex = stringAsciiPRNG(value, colors.length);

  return colors[colorIndex];
};

export default ({ name, className, ...rest }: Props): React.ReactElement => {
  const nameArr = name.split(' ').slice(0, 2);
  const classes = useStyles(getRandomColor(`${name}`))();

  return (
    <MuiAvatar className={clsx(className, classes.root)} {...rest}>
      {nameArr.map((part) => part.substring(0, 1))}
    </MuiAvatar>
  );
};
