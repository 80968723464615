import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    quantity: {
      fontSize: theme.typography.pxToRem(13),
      color: theme.palette.grey[500],
    },
    select: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(3),
      textAlign: 'right',
      // Align <select> on Chrome.
      textAlignLast: 'right',
    },
    input: {
      color: 'inherit',
      fontSize: 'inherit',
      flexShrink: 0,
    },
    selectRoot: {
      marginRight: theme.spacing(4),
      marginLeft: theme.spacing(1),
    },
  })
);
