import {
  DirtyFormValues,
  FieldGroup,
  FormValues,
} from 'services/Main/types.Component';
import {
  ArrayOf,
  DateRangeValue,
  Field,
  OptionsType,
  SelectOption,
} from 'services/Main/types.Field';

export const getInitialValues = (
  fieldGroups: FieldGroup[],
  ignoreDefaultValue?: boolean
) => {
  let fields: Field[] = [];

  fieldGroups.forEach((group) => {
    fields = [...fields, ...group.fields];
  });

  /**
   * TODO
   *  ✅  вынести в отдельную функцию convertFieldValueFromServerToClient;
   *  ✅  добавить логику обработки для полей типа comboBox в ArrayOf;
   *  ❌  переписать type === 'arrayOf' на рекурсию.
   */
  return fields.reduce<DirtyFormValues>((acc, field) => {
    const { defaultValue: defaultValueProp } = field;
    const defaultValue = ignoreDefaultValue ? false : defaultValueProp;
    acc[field.name] = convertFieldValueFromServerToClient(field, defaultValue);
    return acc;
  }, {});
};

export const convertFieldValueFromServerToClient = (
  field: Field,
  defaultValue: unknown
) => {
  const { type, selectType, pickerType, multiple, optionsType, rowDefinition } =
    field as any;

  if (
    type === 'select' &&
    selectType &&
    (selectType === 'asyncMulti' ||
      selectType === 'multi' ||
      selectType === 'dependent')
  ) {
    if (
      selectType === 'dependent' &&
      typeof defaultValue === 'object' &&
      !Array.isArray(defaultValue)
    ) {
      return [defaultValue] as SelectOption[];
    }
    return (defaultValue as SelectOption[]) || [];
  }

  if (
    type === 'datePicker' &&
    (pickerType === 'date' ||
      pickerType === 'dateTime' ||
      pickerType === 'year')
  ) {
    return typeof defaultValue === 'string' ? defaultValue : null;
  }

  if (
    type === 'datePicker' &&
    (pickerType === 'dateRange' || pickerType === 'dateTimeRange')
  ) {
    return (defaultValue as DateRangeValue) || null;
  }

  if (type === 'select') {
    return (defaultValue as string) || null;
  }

  if (type === 'comboBox') {
    if (multiple || optionsType === 'checkboxTree') {
      return (defaultValue as SelectOption[]) || [];
    }

    // Всегда храним [], т.к. в ComboBox всегда multiple={true}.
    return defaultValue ? ([defaultValue] as SelectOption[]) : [];
  }

  if (type === 'entryPicker') {
    if (multiple) {
      return (defaultValue as SelectOption[]) || [];
    }

    // Всегда храним [], т.к. в ComboBox всегда multiple={true}.
    return defaultValue ? ([defaultValue] as SelectOption[]) : [];
  }

  if (type === 'formattedNumber') {
    return (defaultValue as string) || null;
  }

  if (type === 'arrayOf') {
    const singleDependentSelectNames: string[] = (rowDefinition as Field[])
      .filter(
        (f) =>
          f.type === 'select' && f.selectType === 'dependent' && !f.multiple
      )
      .reduce((acc, f) => [...acc, f.name], [] as string[]);
    let arrayOfDefaultValue = defaultValue;

    // Оборачивание Single Dependent Select defaultValue в []
    // const from = {
    //   room: {
    //     value: 'f1ca02f4-30b1-4ab8-a56b-f2ec0cf5a7c5',
    //     label: 'Торговый зал К',
    //     href: '/page-views/tabs',
    //   },
    // };
    //
    // const to = {
    //   room: [
    //     {
    //       value: 'f1ca02f4-30b1-4ab8-a56b-f2ec0cf5a7c5',
    //       label: 'Торговый зал К',
    //       href: '/page-views/tabs',
    //     },
    //   ],
    // };
    if (singleDependentSelectNames.length > 0) {
      if (Array.isArray(defaultValue)) {
        // TODO Конфликт типов
        // @ts-ignore
        arrayOfDefaultValue = arrayOfDefaultValue.map((v: any) =>
          Object.entries(v).reduce((acc, [key, value]) => {
            if (
              singleDependentSelectNames.includes(key) &&
              value &&
              !Array.isArray(value)
            ) {
              return { ...acc, [key]: [value] };
            }

            return { ...acc, [key]: value };
          }, {})
        );
      }
    }

    const singleComboBoxFiledNames: string[] = (rowDefinition as Field[])
      .filter(
        (f) =>
          f.type === 'comboBox' &&
          ((f.optionsType === 'flat' && !f.multiple) ||
            f.optionsType === 'radioTree')
      )
      .reduce((acc, f) => [...acc, f.name], [] as string[]);

    if (singleComboBoxFiledNames.length > 0) {
      if (Array.isArray(defaultValue)) {
        // TODO Конфликт типов
        // @ts-ignore
        arrayOfDefaultValue = arrayOfDefaultValue.map((v: any) =>
          Object.entries(v).reduce((acc, [key, value]) => {
            if (
              singleComboBoxFiledNames.includes(key) &&
              value &&
              !Array.isArray(value)
            ) {
              return { ...acc, [key]: [value] };
            }

            return { ...acc, [key]: value };
          }, {})
        );
      }
    }

    return (arrayOfDefaultValue as any) || [];
  }

  if (type === 'checkbox') {
    return (defaultValue as any) || false;
  }

  return (defaultValue as string) || '';
};

export const mapValuesToPlain = (
  formValues: DirtyFormValues,
  fields: Field[]
): FormValues => {
  // Собираем объект со всеми полями формы и их стандартными значениями
  const emptyFormValues = getInitialValues([{ fields }], true);
  const preparedFormValues = { ...emptyFormValues, ...formValues };

  return Object.entries(preparedFormValues).reduce(
    dirtyValueReducer(fields),
    {}
  );
};

function dirtyValueReducer(fields: Field[], arrayOfFieldName?: string) {
  return (acc: any, [fieldName, dirtyValue]: any) => {
    if (!fields || fields.length === 0)
      return { ...acc, [fieldName]: dirtyValue };

    const field = arrayOfFieldName
      ? ((
          (fields.find((f) => f.name === arrayOfFieldName) as ArrayOf)
            ?.rowDefinition as Field[]
        ).find((f) => f.name === fieldName) as Field)
      : (fields.find((f) => f.name === fieldName) as Field);

    const isSelectOption =
      field &&
      field.type === 'select' &&
      (field.selectType === 'single' || field.selectType === 'async');

    // SelectOption[]
    const isArrayOfSelectOption =
      field &&
      field.type === 'select' &&
      (field.selectType === 'multi' ||
        field.selectType === 'asyncMulti' ||
        (field.selectType === 'dependent' && field.multiple === true));

    // ArrayOf
    const isArray = field && field.type === 'arrayOf';

    const isSingleDependent =
      field &&
      field.type === 'select' &&
      field.selectType === 'dependent' &&
      field.multiple !== true;

    const isSingleFlatComboBox =
      field &&
      field.type === 'comboBox' &&
      field.optionsType === 'flat' &&
      !field.multiple;
    const isRadioComboBox =
      field &&
      field.type === 'comboBox' &&
      field.optionsType === OptionsType.radioTree;

    const isSingleEntryPicker =
      field && field.type === 'entryPicker' && !field.multiple;

    if (isSelectOption) {
      return {
        ...acc,
        [fieldName]: selectOptionFormatter(dirtyValue),
      };
    }

    if (isArrayOfSelectOption) {
      return {
        ...acc,
        [fieldName]: arrayOfSelectOptionFormatter(dirtyValue),
      };
    }

    if (isArray) {
      return {
        ...acc,
        [fieldName]: Array.isArray(dirtyValue)
          ? (dirtyValue as any).map((val: any) =>
              Object.entries(val).reduce(
                dirtyValueReducer(fields, fieldName),
                {}
              )
            )
          : dirtyValue,
      };
    }

    if (
      isSingleDependent ||
      isSingleFlatComboBox ||
      isRadioComboBox ||
      isSingleEntryPicker
    ) {
      return {
        ...acc,
        [fieldName]: singleDependentFormatter(dirtyValue),
      };
    }

    if (field.type === 'file') {
      return {
        ...acc,
        [fieldName]: dirtyValue === '' ? null : dirtyValue,
      };
    }

    return {
      ...acc,
      [fieldName]: dirtyValue as any,
    };
  };
}

function selectOptionFormatter(dirtyValue: SelectOption) {
  if (!dirtyValue) return null;

  return dirtyValue;
}

function arrayOfSelectOptionFormatter(dirtyValue: SelectOption[]) {
  if (!dirtyValue) return null;

  return dirtyValue;
}

function singleDependentFormatter(dirtyValue: SelectOption[]) {
  if (!Array.isArray(dirtyValue)) return null; // ? !== [...]
  if (dirtyValue.length === 0) return null; // ? === []
  if (typeof dirtyValue[0] !== 'object') return null; // ?[0] !== {...}
  if (dirtyValue[0] === null) return null; // ?[0] === null

  return dirtyValue[0];
}
