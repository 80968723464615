import React, { ChangeEvent, useCallback, useState } from 'react';
import clsx from 'clsx';
import { Autocomplete } from '@material-ui/lab';
import debounce from 'lodash.debounce';
import { useFormatMessage } from 'locale';
import { AutocompleteInputChangeReason } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import { EntryPickerProps } from '../../types';
import { ComboBoxOption } from '../../../../../services/Main/types.Field';
import getLocalizedProperties from '../../../ComboBox/helpers/getLocalizedProperties';
import useStyles from './EntryPickerComboBox.styles';
import getInput from './getInput';
import entryPickerService from '../../../../../services/EntryPicker';
import renderTags from '../../../ComboBox/helpers/renderTags';
import getEntryPickerPaper from '../getEntryPickerPaper';
import { FetchOptionsRequestBody } from '../../../../../services/EntryPicker/EntryPicker.interface';
import useEnqueueSnackbar from '../../../../../utils/hooks/useEnqueueSnackbar';

interface Props {
  entryPickerProps: EntryPickerProps;
  openDialog: () => void;
}

const EntryPickerComboBox = ({ entryPickerProps, openDialog }: Props) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { disabled, multiple, value, onChange } = entryPickerProps;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<ComboBoxOption[] | null>(null);
  const [noOptionsText, setNoOptionsText] = useState<string>(
    formatMessage('defaultNoOptionsText')
  );
  const [displayMoreOptionsText, setDisplayMoreOptionsText] =
    useState<boolean>(false);

  const debounceLoadOptions = useCallback(
    debounce((params: FetchOptionsRequestBody) => {
      entryPickerService
        .fetchOptions(entryPickerProps.searchRequestConfig, params)
        .then(({ payload }) => {
          const { options: newOptions, hasMoreOptionsThanLimit } = payload;
          setOptions(newOptions);

          if (newOptions.length === 0) {
            setDisplayMoreOptionsText(false);
            setNoOptionsText(formatMessage('noOptionsWereFoundForQuery'));
          }

          setDisplayMoreOptionsText(!!hasMoreOptionsThanLimit);
        })
        .catch(() => {
          enqueueSnackbar(formatMessage('errorOccurredWhileRequestingData'), {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 250),
    []
  );

  const loadOptions = (
    event: ChangeEvent<{}>,
    newInputValue: string,
    reason: AutocompleteInputChangeReason
  ) => {
    // Исправляет работу disableCloseOnSelect при multiple: true.
    if (multiple && reason === 'reset') return;

    if (newInputValue.length < 3) {
      setNoOptionsText(formatMessage('defaultNoOptionsText'));
      setDisplayMoreOptionsText(false);

      if (options && options.length > 0) {
        setOptions(null);
      }
      return;
    }

    setIsLoading(true);
    setOptions(null);

    debounceLoadOptions({ query: newInputValue });
  };

  const handleChange = (event: any, changedValue: ComboBoxOption[]) => {
    if (disabled) return;

    let newOptions = changedValue;

    if (!multiple && newOptions && newOptions?.length > 1) {
      newOptions = newOptions?.slice(-1);
    }

    onChange(newOptions);
  };

  // Функция вызывается после закрытия Popper
  // и возвращает все значения в default.
  const handleClose = () => {
    setNoOptionsText(formatMessage('defaultNoOptionsText'));
    setDisplayMoreOptionsText(false);

    if (options && options.length > 0) {
      setOptions(null);
    }
  };

  return (
    <Autocomplete
      {...getLocalizedProperties(formatMessage)}
      noOptionsText={!isLoading && noOptionsText}
      classes={{
        root: clsx(classes.root, { disabled }),
        inputRoot: classes.inputRoot,
        input: classes.input,
        endAdornment: classes.endAdornment,
      }}
      multiple
      forcePopupIcon={false}
      loading={isLoading}
      disabled={disabled}
      disableCloseOnSelect={multiple}
      disableClearable={disabled}
      options={options || []}
      onClose={handleClose}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(o, v) => o.value === v.value}
      onInputChange={loadOptions}
      onChange={handleChange}
      value={value}
      renderInput={getInput(entryPickerProps, openDialog)}
      renderTags={renderTags({})}
      PaperComponent={getEntryPickerPaper(
        displayMoreOptionsText && !isLoading,
        openDialog
      )}
    />
  );
};

export default EntryPickerComboBox;
