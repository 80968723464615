import { io, Socket } from 'socket.io-client';
import authService from '../Auth';
import { SocketConfig } from '../Main/types.Component';

class SocketServiceIo {
  connection: Socket;

  constructor({ uri, path, query }: SocketConfig) {
    this.connection = io(uri, {
      extraHeaders: {
        Authorization: `Bearer ${authService.token}`,
      },
      path: path || undefined,
      query,
    });
  }

  close = () => {
    this.connection.close();
  };
}

export default SocketServiceIo;
