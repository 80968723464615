import React, { Component, PropsWithChildren, ReactElement } from 'react';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { metaActions } from 'store/meta';
import { sendMessageToBot } from '../bot';

type State = {
  readonly error: Error | null | undefined;
};

type Props = PropsWithChildren<{
  dispatch: any;
  fallbackRender?: ReactElement | null;
}>;

const initialState = {
  error: undefined,
};

class CleanUnexpectedDataErrorBoundary extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = initialState;
  }

  componentDidCatch(error: Error | null) {
    const { dispatch } = this.props;

    if (error && error.name === 'UnexpectedDataError') {
      this.setState({ error });
      this.notifyAdmins(error);
      dispatch(metaActions.hideLoadingScreen());
    }
  }

  notifyAdmins = (error: Error | null) => {
    sendMessageToBot(
      `*⚠️ В приложении обнаружены неожиданные данные:*\n${error?.message}`
    );
  };

  render() {
    const { error } = this.state;
    const { children, fallbackRender } = this.props;

    if (error) {
      if (fallbackRender || fallbackRender === null) return fallbackRender;

      return (
        <Alert severity="error">
          <AlertTitle>
            В работе приложения произошла фатальная ошибка.
          </AlertTitle>
          Попробуйте перезагрузить страницу или обратитесь к администратору
          системы.
        </Alert>
      );
    }

    return children;
  }
}

export const UnexpectedDataErrorBoundary = connect(null, (dispatch) => ({
  dispatch,
}))(CleanUnexpectedDataErrorBoundary);
