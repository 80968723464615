import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default (htmlColor?: string) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: htmlColor
        ? {
            color: htmlColor,
            backgroundColor: `${htmlColor}55`,
          }
        : {},
    })
  );
