import React from 'react';
import { FieldProps } from './types';
import Field from './Field';
import useShouldRender from './useShouldRender';

export default (props: FieldProps) => {
  const shouldRender = useShouldRender(props.renderCondition);

  if (!shouldRender) return null;

  return <Field {...props} />;
};
