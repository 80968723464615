import React from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';

const TableHeaderTooltip = ({
  getMessage,
  column,
  children,
  ...restProps
}: TableHeaderRow.SortLabelProps) => {
  return (
    <TableHeaderRow.SortLabel
      column={column}
      getMessage={() => `${column.title}`}
      {...restProps}
    >
      {children}
    </TableHeaderRow.SortLabel>
  );
};

export default TableHeaderTooltip;
