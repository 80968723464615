import React, { useMemo, useRef } from 'react';
import clsx from 'clsx';
import useStyles from './Tags.styles';
import { ComboBoxOption } from '../../../../../services/Main/types.Field';
import { useContentSwiper } from '../../../../../utils/hooks/useContentSwiper';
import Tag from './Tag';
import { RenderTagsProps } from '../../helpers/renderTags';

interface TagsProps extends RenderTagsProps {
  tags: ComboBoxOption[];
}

const Tags = ({ tags, multiline }: TagsProps) => {
  const classes = useStyles();
  const tagsContainerRef = useRef<HTMLDivElement>(null);
  const { events, swiperContainerStyles } = useContentSwiper(tagsContainerRef);

  const textTags = useMemo(
    () =>
      tags.map((tag) => {
        const { label, href } = tag;

        return <Tag key={`${label}_${href}`} {...tag} />;
      }),
    [tags]
  );

  if (multiline) {
    return <div className={clsx(classes.tags, { multiline })}>{textTags}</div>;
  }

  return (
    <div
      className={clsx(classes.tags, swiperContainerStyles)}
      ref={tagsContainerRef}
      {...events}
    >
      {textTags}
    </div>
  );
};

export default Tags;
