import React, { MouseEventHandler, useContext } from 'react';
import { QuotedTimelineElement } from '../../../services/Main/types.Component';
import useStyles from './TimelineQuote.styles';
import useSharedStyles from '../../highLevel/Timeline/Timeline.styles';
import { TimelineContext } from '../../highLevel/Timeline/TimelineContext';

const TimelineQuote = ({
  body,
  header,
  quotedElementId,
}: QuotedTimelineElement) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const timelineContext = useContext(TimelineContext);

  const handleQuoteClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();

    timelineContext?.timelineScrollManager?.current?.scrollToElementWithId(
      quotedElementId.toString()
    );
  };

  return (
    <div className={classes.TimelineQuote} onClick={handleQuoteClick}>
      {header && <div className={sharedClasses.TimelineHeader}>{header}</div>}
      <div
        className={classes.TimelineQuoteText}
        dangerouslySetInnerHTML={{ __html: body.html }}
      />
    </div>
  );
};

export default TimelineQuote;
