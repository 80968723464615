import * as yup from 'yup';
import { StringSchema, ValidationError } from 'yup';
import { FormatMessage } from 'locale/hooks';

declare module 'yup' {
  interface StringSchema {
    validatePassword(formatMessage: FormatMessage): StringSchema;
  }
}

yup.addMethod<StringSchema>(
  yup.string,
  'validatePassword',
  function validatePassword(formatMessage) {
    return this.test('passwordValidation', '', (value) => {
      const rules = [
        {
          errorText: formatMessage('minimumCharacters'),
          regexp: /.{6,}/g,
        },
        {
          errorText: formatMessage('oneLowerEnglishLetter'),
          regexp: /[a-z]/g,
        },
        {
          errorText: formatMessage('oneCapitalEnglishLetter'),
          regexp: /[A-Z]/g,
        },
        {
          errorText: formatMessage('oneDigit'),
          regexp: /[0-9]/g,
        },
        {
          errorText: formatMessage('oneSpecialSymbol'),
          regexp: /[!@#$%^&*()_+\-=]/g,
        },
        {
          errorText: formatMessage('containOnlyAllowedCharacters'),
          regexp: /^[A-Za-z0-9!@#$%^&*()_+\-=]*$/g,
        },
      ];

      const errors = rules.reduce((acc, { errorText, regexp }) => {
        if (!regexp.test(value as string)) {
          acc.push(errorText);
        }
        return acc;
      }, [] as string[]);

      if (errors.length === 0) return true;

      return new ValidationError(errors.join('\n'), value, 'password');
    });
  }
);
