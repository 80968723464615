import React from 'react';
import {
  Component,
  DonutChartProps,
  FormComponentProps,
  TimelineComponentProps,
  BarChartProps,
  TableComponentProps,
  TextWidget,
  HTMLPrinterProps,
} from 'services/Main/types.Component';
import Form from 'components/highLevel/Form';
import Table from 'components/highLevel/Table';
import { UnexpectedDataError } from 'utils/errors/UnexpectedDataError';
import GroupOfTextWidget from '../../highLevel/GroupOfTextWidget';
import DonutChartWidget from '../../highLevel/DonutChartWidget';
import BarChartWidget from '../../highLevel/BarChartWidget';
import HTMLPrinter from '../../highLevel/HTMLPrinter';
import Timeline from '../../highLevel/Timeline';
import { ComponentContext } from './ComponentLoader.context';

interface ComponentLoaderProps {
  component: Component;
}

const ComponentLoader = ({ component }: ComponentLoaderProps) => {
  const { type, props } = component;

  const getComponent = () => {
    switch (type) {
      case 'form':
        return <Form {...(props as FormComponentProps)} />;
      case 'table':
        return <Table {...(props as TableComponentProps)} />;
      case 'timeline':
        return <Timeline {...(props as TimelineComponentProps)} />;
      case 'groupOfTextWidget':
        return <GroupOfTextWidget textWidgets={props as TextWidget[]} />;
      case 'donutChartWidget':
        return <DonutChartWidget {...(props as DonutChartProps)} />;
      case 'barChartWidget':
        return <BarChartWidget {...(props as BarChartProps)} />;
      case 'HTMLPrinter':
        return <HTMLPrinter {...(props as HTMLPrinterProps)} />;
      default:
        throw new UnexpectedDataError(
          `Не удалось определить тип высокоуровневого компонента. Указан тип: ${type}.`
        );
    }
  };

  return (
    <ComponentContext.Provider value={component}>
      {getComponent()}
    </ComponentContext.Provider>
  );
};

export default ComponentLoader;
