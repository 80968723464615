import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '../../../../../theme';

export default makeStyles<Theme>((theme) =>
  createStyles({
    tags: {
      '& > div:not(:last-child):after': {
        content: '", "',
      },

      '&.multiline': {
        whiteSpace: 'pre-line',
      },
    },
    tag: {
      display: 'inline-flex',
      verticalAlign: 'middle',
      paddingRight: theme.spacing(0.5),
    },
    link: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);
