import { createContext, Dispatch, SetStateAction } from 'react';
import {
  DirtyFormValues,
  FilterChip,
  FilterOptions,
  Header,
  SortingState,
  TableColumn,
  TableFilterFormComponent,
  TableRow,
} from '../../../../../services/Main/types.Component';
import { FetchTablePropsRequestBody } from '../../../../../services/EntryPicker/EntryPicker.interface';

interface EntryPickerTableContext {
  multiple?: boolean;
  onCancel: () => void;
  onEntryChoose: (chosenValues: (string | number)[]) => void;
  columns: TableColumn[] | null;
  setColumns: Dispatch<SetStateAction<TableColumn[] | null>>;
  rows: TableRow[];
  setRows: Dispatch<SetStateAction<TableRow[]>>;
  pageSize: number;
  setPageSize: Dispatch<SetStateAction<number>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  totalRows: number;
  setTotalRows: Dispatch<SetStateAction<number>>;
  header?: Header;
  setHeader: Dispatch<SetStateAction<Header | undefined>>;
  filterComponent?: TableFilterFormComponent;
  setFilterComponent: Dispatch<
    SetStateAction<TableFilterFormComponent | undefined>
  >;

  sorting?: SortingState;
  setSorting: Dispatch<SetStateAction<SortingState | undefined>>;

  selectedRowsId: (number | string)[];
  setSelectedRowsId: Dispatch<SetStateAction<(number | string)[]>>;

  filter?: DirtyFormValues;
  setFilter: Dispatch<SetStateAction<DirtyFormValues | undefined>>;

  filterChips?: FilterChip[];
  setFilterChips: Dispatch<SetStateAction<FilterChip[] | undefined>>;

  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;

  loadRows: (params: FetchTablePropsRequestBody) => void;
  filterOptions?: FilterOptions;
  setFilterOptions: Dispatch<SetStateAction<FilterOptions | undefined>>;
}

export default createContext<EntryPickerTableContext>({
  multiple: false,
  onCancel: () => {},
  onEntryChoose: () => {},
  columns: null,
  setColumns: () => {},
  rows: [],
  setRows: () => {},
  pageSize: 0,
  setPageSize: () => {},
  currentPage: 0,
  setCurrentPage: () => {},
  totalRows: 0,
  setTotalRows: () => {},
  header: undefined,
  setHeader: () => {},
  filterComponent: undefined,
  setFilterComponent: () => {},
  selectedRowsId: [],
  setSelectedRowsId: () => {},
  sorting: undefined,
  setSorting: () => {},
  filter: undefined,
  setFilter: () => {},
  isLoading: true,
  setIsLoading: () => {},
  filterChips: [],
  setFilterChips: () => {},
  loadRows: () => {
    throw new Error('`loadRows` fn was not initialized!');
  },
  filterOptions: undefined,
  setFilterOptions: () => {},
});
