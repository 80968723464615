import React from 'react';
import { File } from '../../../services/Main/types.Field';
import FileListItem from '../FileListItem';

interface FileListProps {
  files: File[];
  viewSize?: 'small' | 'normal';
}

const FileList = ({ files, viewSize }: FileListProps) => {
  return (
    <>
      {files.map((file) => (
        <FileListItem key={file.id} file={file} viewSize={viewSize} />
      ))}
    </>
  );
};

export default FileList;
