import { createStyles, makeStyles } from '@material-ui/core';
import { TableRow } from 'services/Main/types.Component';

export default (row: TableRow) =>
  makeStyles(() =>
    createStyles({
      cell: {
        backgroundColor:
          row.viewConfig && row.viewConfig.backgroundColor
            ? `${row.viewConfig.backgroundColor} !important`
            : '#ffffff',
      },
      clickableCell: {
        cursor: 'pointer',
        backgroundColor:
          row.viewConfig && row.viewConfig.backgroundColor
            ? `${row.viewConfig.backgroundColor} !important`
            : '#ffffff',
        '&:hover': {
          backgroundColor: 'rgba(16, 156, 241, 0.03) !important',
          userSelect: 'none',
        },
      },
    })
  );
