import React, { useCallback, useContext, useState } from 'react';
import isEqual from 'lodash.isequal';
import Typography from '@material-ui/core/Typography';
import theme from 'theme';
import {
  DirtyFormValues,
  FilterChip,
  FilterOptions,
  FormValues,
} from 'services/Main/types.Component';
import useStyles from './TableFilterForm.styles';
import Footer from './TableFilterForm.footer';
import FormBuilder from '../FormBuilder';
import { TableFilterFormProps } from '../TableModules';
import mapFilterChips from '../TableFilters/mapFilterChips';
import { useFormatMessage } from '../../../locale';
import { ComponentContext } from '../../creational/ComponentLoader';

const TableFilterForm = ({
  props,
  filter: propsFilter,
  setFilter,
  setFilterChips,
}: TableFilterFormProps) => {
  const classes = useStyles();
  const componentContext = useContext(ComponentContext);
  const formatMessage = useFormatMessage();

  const { fieldGroups } = props;
  const [filterState, setFilterState] = useState<FormValues | undefined>();

  const handleChange = useCallback(
    (values: FormValues, dirtyValues: DirtyFormValues) => {
      const haveSomeValue = Object.values(values).some((v) => !!v || v === 0);
      const oldFiltersLength = filterState
        ? Object.values(filterState).filter((v) => !!v || v === 0).length
        : 0;
      const newFiltersLength = Object.values(values).filter(
        (v) => !!v || v === 0
      ).length;

      const isInserting = !isEqual(filterState, values) && haveSomeValue;

      const isDeletingLastFilter =
        !isEqual(filterState, values) &&
        oldFiltersLength > 0 &&
        newFiltersLength === 0;

      // Проверка на то, что фильтр изменился реально.
      // Нужно, т.к. RHF watch метод срабатывает
      // чаще, чем нужно (при focusout, к примеру).
      if (isInserting || isDeletingLastFilter) {
        const filterChips: FilterChip[] = mapFilterChips(formatMessage)(
          fieldGroups[0].fields,
          dirtyValues
        );

        setFilterState(values);
        setFilter(dirtyValues);
        setFilterChips(filterChips);
      }
    },
    // eslint-disable-next-line
    [filterState, setFilterState, setFilter]
  );

  return (
    <div className={classes.root}>
      <Typography variant="h6">{formatMessage('filterList')}</Typography>
      <FormBuilder
        id={
          componentContext
            ? `${componentContext.id}_tableFilter`
            : 'unknown_tableFilter'
        }
        fieldGroups={fieldGroups}
        padding={theme.spacing(0, 0, 2, 0)}
        disableBorder
        footer={Footer}
        values={propsFilter}
        onChange={handleChange}
      />
    </div>
  );
};

export function mapValuesToFilterOptions(
  values: FormValues | DirtyFormValues
): FilterOptions {
  return Object.entries(values).reduce<any>((acc, [key, value]) => {
    const newAcc = { ...acc };

    // if (isEmptyArray) continue;
    if (Array.isArray(value) && value.length === 0) {
      return newAcc;
    }

    if (value || value === 0) {
      newAcc[key] = value;
    }

    return newAcc;
  }, {});
}

export default TableFilterForm;
