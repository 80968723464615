import React from 'react';
import TextField from '@material-ui/core/TextField';
import { HighlightColor, SelectOption } from 'services/Main/types.Field';
import { InputProps as RenderInputProps } from '../sharedTypes';
import useStyles from '../../Select.styles';

interface OtherProps {
  highlightColor?: HighlightColor;
}

const SingleSelectInput = ({
  params,
  label,
  error,
  helperText,
  name,
  highlightColor,
}: RenderInputProps<SelectOption | null | undefined> & OtherProps) => {
  const classes = useStyles(highlightColor)();

  return (
    <TextField
      {...params}
      className={classes.textField}
      variant="standard"
      label={label}
      name={name}
      helperText={error?.message || helperText}
      error={!!error}
    />
  );
};

export default SingleSelectInput;
