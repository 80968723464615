import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import hash from 'object-hash';
import { FormComponentProps } from 'services/Main/types.Component';
import FormBuilder from '../../lowLevel/FormBuilder';
import Header from '../../lowLevel/Header';
import FormActions from '../../lowLevel/FormActions';
import Alerts from '../../lowLevel/Alerts';
import FormHistory from '../../lowLevel/FormHistory';

const Form = ({
  form,
  actions,
  alerts,
  actionsYPosition,
}: FormComponentProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (form.type === 'preset') return <h1>Will be soon...</h1>;

  return (
    <>
      <FormBuilder
        key={hash(form)}
        id={form.id}
        header={() => (
          <Box mb={1}>
            <Box mb={1}>
              <Grid container spacing={1} wrap={isMobile ? 'wrap' : 'nowrap'}>
                <Grid item xs="auto" style={{ overflow: 'hidden' }}>
                  <Header {...form.header} />
                </Grid>
                <Grid
                  item
                  container
                  justify={isMobile ? 'flex-start' : 'flex-end'}
                  wrap={isMobile ? 'wrap' : 'nowrap'}
                  style={{
                    flexShrink: 10,
                  }}
                  xs
                  alignItems="center"
                  spacing={1}
                >
                  {form.historyConfig && (
                    <Grid item>
                      <FormHistory config={form.historyConfig} />
                    </Grid>
                  )}
                  {actions && actionsYPosition !== 'bottom' && (
                    <FormActions actions={actions} />
                  )}
                </Grid>
              </Grid>
            </Box>
            <Alerts staticAlerts={alerts} />
          </Box>
        )}
        footer={() => (
          <>
            {actions && actionsYPosition === 'bottom' && (
              <Grid container item spacing={1} xs={12} justify="flex-end">
                <FormActions actions={actions} />
              </Grid>
            )}
          </>
        )}
        fieldGroups={form.fieldGroups}
        globalDisabled={form.disabled}
      />
    </>
  );
};

export default Form;
