import {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
} from 'react';
import {
  QuotedTimelineElement,
  TimelineElement,
} from '../../../services/Main/types.Component';
import ScrollManager from '../../../utils/ScrollManager';

export type QuoteState = QuotedTimelineElement | null;

type Props = {
  quote: QuoteState;
  setQuote: (quote: QuoteState) => void;
  isAddTimelineElementFormOpened: boolean;
  setIsAddTimelineElementFormOpened: Dispatch<SetStateAction<boolean>>;
  timelineScrollManager: MutableRefObject<ScrollManager | undefined>;
  timelineElements: TimelineElement[];
};

export type TimelineContextValue = Props | null;
export const TimelineContext = createContext<TimelineContextValue>(null);
