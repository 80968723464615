import Axios, { AxiosRequestConfig } from 'axios';
import history from 'utils/history';
import { metaActions } from 'store/meta';
import store from 'store';
import { apiClient } from '../Api';
import PubSub from './PubSub';

const TOKEN_STORAGE_NAME = 'keepUp_userToken';
const REFRESH_TOKEN_STORAGE_NAME = 'keepUp_refreshToken';

// За сколько секунд до смерти нужно обновить токен.
const DESIRABLE_TOKEN_UPDATE_TIME = 300;

export class AuthServiceBase extends PubSub {
  uniqueName: string | null = null;

  token: string | null = null;

  refreshToken: string | null = null;

  tokenExpireAt: number | null = null;

  returnUrl?: string;

  constructor() {
    super();

    this.token = localStorage.getItem(TOKEN_STORAGE_NAME);
    this.refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_NAME);
    this.tokenExpireAt = this.parseToken()?.exp || null;
    this.uniqueName = this.parseToken()?.unique_name || null;

    this.updateApiClient();
    this.initializeInterceptors();
  }

  /**
   * Метод реализующий получение токена на сервере.
   *
   * Должен быть переопределён наследником.
   */
  login: (login: string, password: string) => Promise<boolean> = () => {
    throw new Error('Implement login() method!');
  };

  /**
   * Метод реализующий получение токена на сервере по google tokenId.
   *
   * Должен быть переопределён наследником.
   */
  loginWithGoogle: (tokenId: string) => Promise<boolean> = () => {
    throw new Error('Implement loginWithGoogle() method!');
  };

  /**
   * Метод реализующий обновление токена на сервере.
   *
   * Должен быть переопределён наследником.
   */
  updateToken: () => Promise<any> = async () => {
    throw new Error('Implement updateToken() method!');
  };

  /**
   * Метод для проверки авторизации.
   */
  isAuthenticated: () => boolean = () => {
    return !!this.token && !!this.refreshToken;
  };

  /**
   * Метод для использования на фронте.
   *
   * После его вызова произойдёт редирект на страницу
   * авторизации. После авторизации юзера вернут на
   * страницу, с которой он пошёл авторизоваться.
   */
  processLogin = () => {
    this.returnUrl = window.location.pathname;

    history.push('/login');
  };

  /**
   * Метод для использования на фронте.
   *
   * После его вызова произойдёт деавторизация пользователя
   * и редирект на страницу, с которой произошёл логаут.
   */
  processLogout = () => {
    this.returnUrl = window.location.pathname;

    this.logout().then((isSuccess) => {
      if (isSuccess && this.returnUrl) {
        store.dispatch(metaActions.showLoadingScreen());
        // Не используем react-router для того, чтобы выгрузить приложение
        window.location.pathname = this.returnUrl || '/';
      }
    });
  };

  /**
   * Метод для выпиливания авторизации.
   */
  logout: () => Promise<boolean> = () => {
    localStorage.removeItem('redux_localstorage_simple_tables');
    this.saveCredential(null, null);
    return Promise.resolve(true);
  };

  /**
   * Метод для сохранения всей информации об
   * авторизации пользователя в нужных местах.
   *
   * Также здесь вызывается .notify() для уведомления
   * подписчиков об обновлении состояния сервиса.
   */
  protected saveCredential = (
    token: string | null,
    refreshToken: string | null
  ) => {
    this.token = token;
    this.refreshToken = refreshToken;
    this.tokenExpireAt = this.parseToken()?.exp || null;
    this.uniqueName = this.parseToken()?.unique_name || null;

    token
      ? localStorage.setItem(TOKEN_STORAGE_NAME, token)
      : localStorage.removeItem(TOKEN_STORAGE_NAME);

    refreshToken
      ? localStorage.setItem(REFRESH_TOKEN_STORAGE_NAME, refreshToken)
      : localStorage.removeItem(REFRESH_TOKEN_STORAGE_NAME);

    this.updateApiClient();
    this.notify();
  };

  /**
   * Метод для обработки ответа сервера при использовании
   * credential или refresh-token роутов.
   */
  protected handleLoginResponse = ({ data }: any) => {
    if (!data.isSuccess && !!data.exceptionString) {
      throw new Error(data.exceptionString);
    }

    if (!data.isSuccess) {
      throw new Error(
        'С сервера вернулся isSuccess: false, но не вернулся exceptionString'
      );
    }

    if (!data.jwtToken.value || !data.refreshToken.value) {
      throw new Error(
        'С сервера вернулся isSuccess: true, но не вернулся jwtToken или refreshToken'
      );
    }

    this.saveCredential(data.jwtToken.value, data.refreshToken.value);

    return data.jwtToken.value;
  };

  /**
   * Метод для работы работы с экземпляром API-клиента
   */
  updateApiClient = () => {
    if (this.token) {
      apiClient.defaults.headers.common.Authorization = `Bearer ${this.token}`;
    } else {
      delete apiClient.defaults.headers.common.Authorization;
    }
  };

  /**
   * Вспомогательный метод для парсинга JWT токена
   */
  private parseToken = () => {
    if (!this.token) return null;

    const base64Url = this.token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );

    return JSON.parse(jsonPayload);
  };

  /**
   * Метод для инициализации перехватчика 401 ошибки
   */
  private initializeInterceptors = () => {
    apiClient.interceptors.request.use(
      async (config: AxiosRequestConfig & { retry?: boolean }) => {
        if (
          !this.tokenExpireAt ||
          config.url?.includes('refresh-token') || // игнорируем /auth/v1.0/refresh-token
          config.url?.includes('root') || // игнорируем /component/v1.0/root
          config.url?.includes('credential') || // игнорируем /auth/v1.0/credential
          config.url?.includes('logger') || // игнорируем /logger/v1.0
          config.retry
        )
          return config;

        const tokenLifetimeLeftInSeconds =
          (this.tokenExpireAt * 1000 - new Date().getTime()) / 1000;

        // У Артура на .life стоит 17990
        if (tokenLifetimeLeftInSeconds < DESIRABLE_TOKEN_UPDATE_TIME) {
          config.retry = true;
          const token = await this.updateToken();
          config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
      }
    );

    apiClient.interceptors.response.use(
      (config) => config,
      async (error: any) => {
        const { config } = error;

        if (
          error &&
          error.response &&
          error.response.status === 401 &&
          this.token &&
          !config.retry
        ) {
          config.retry = true;
          const token = await this.updateToken();
          error.config.headers.Authorization = `Bearer ${token}`;

          return Axios.request(error.config);
        }

        return Promise.reject(error);
      }
    );
  };
}
