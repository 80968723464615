import React, { useMemo } from 'react';
import { Switch, Route as RouterRoute } from 'react-router-dom';
import RouteComponent from 'components/lowLevel/Route';
import RootLoader from 'components/creational/RootLoader';
import NotFound from 'pages/errors/NotFound';
import LoginPage from 'pages/login';
import ProfilePage from 'pages/profile';
import PrivateRoutesTemplate from 'components/template/PrivateRoutesTemplate';
import PublicRoutesTemplate from 'components/template/PublicRoutesTemplate';
import { useSelector } from 'store';
import PasswordForgotPage from '../pages/password/forgot';
import mapRoutes from './mapRoutes';
import ForbiddenPage from '../pages/errors/Forbidden';
import ForbiddenUserPage from '../pages/errors/ForbiddenUser';
import HomePage from '../pages/home';
import PasswordSetupPage from '../pages/password/setup';
import { PASSWORD_CLIENT_ROUTE } from '../services/Password/Password.constants';
import useSoringRoutes from './useSoringRoutes';

const Router = () => {
  const routes = useSelector((state) => state.routes);
  const routesWithoutHomePage = useMemo(
    () => routes.filter((r) => r.path !== '/'),
    [routes]
  );

  const sortedRoutes = useSoringRoutes(routesWithoutHomePage);

  const { showForbiddenPage, showForbiddenUserPage } = useSelector(
    (state) => state.meta
  );

  const routesPath: string[] = useMemo(
    () => sortedRoutes.map((route) => route.path),
    [sortedRoutes]
  );

  return (
    <>
      <RootLoader />
      <Switch>
        {showForbiddenPage && <ForbiddenPage />}
        {showForbiddenUserPage && <ForbiddenUserPage />}

        <RouterRoute
          key="auth"
          path={['/login', ...Object.values(PASSWORD_CLIENT_ROUTE)]}
          exact
        >
          <PublicRoutesTemplate>
            <RouterRoute key="login" path="/login" exact>
              <LoginPage />
            </RouterRoute>
            <RouterRoute
              key={PASSWORD_CLIENT_ROUTE.forgot}
              path={PASSWORD_CLIENT_ROUTE.forgot}
              exact
            >
              <PasswordForgotPage />
            </RouterRoute>
            <RouterRoute
              key={PASSWORD_CLIENT_ROUTE.setup}
              path={PASSWORD_CLIENT_ROUTE.setup}
              exact
            >
              <PasswordSetupPage />
            </RouterRoute>
          </PublicRoutesTemplate>
        </RouterRoute>
        <RouterRoute key="index" path={[...routesPath, '/profile', '/']} exact>
          <PrivateRoutesTemplate>
            <Switch>
              <RouteComponent key="home" path="/" exact>
                <HomePage />
              </RouteComponent>
              <RouteComponent key="profile" path="/profile" exact>
                <ProfilePage />
              </RouteComponent>

              {mapRoutes(sortedRoutes)}
            </Switch>
          </PrivateRoutesTemplate>
        </RouterRoute>
        <RouterRoute key="no-match" path="*" component={NotFound} />
      </Switch>
    </>
  );
};

export default Router;
