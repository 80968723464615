import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { QuoteState } from './TimelineContext';
import { metaActions } from '../../../store/meta';
import { RequestConfig } from '../../../services/Main/types.Component';

export default (addCommentUrl: RequestConfig) => {
  const dispatch = useDispatch();
  const [quote, setQuote] = useState<QuoteState>(null);
  const [
    isAddTimelineElementFormOpened,
    setIsAddTimelineElementFormOpened,
  ] = useState<boolean>(false);

  const handleQuoteSet = (newQuote: QuoteState) => {
    /**
     * В redux храним дополнительные данные для отправки с формой.
     * Не стоит использовать для хранения состояния.
     */
    newQuote
      ? dispatch(
          metaActions.createAdditionalPayloadToSendWithForm({
            id: addCommentUrl.url,
            additionalPayloadToSendWithForm: { quote: newQuote },
          })
        )
      : dispatch(
          metaActions.deleteAdditionalPayloadToSendWithForm(addCommentUrl.url)
        );

    if (newQuote) setIsAddTimelineElementFormOpened(true);

    setQuote(newQuote);
  };

  return {
    quote,
    setQuote: handleQuoteSet,
    isAddTimelineElementFormOpened,
    setIsAddTimelineElementFormOpened,
  };
};
