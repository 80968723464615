import isEmpty from 'lodash.isempty';
import { SelectOption } from '../../../../services/Main/types.Field';

// returns:
// multiple && value === null => [];
// multiple && value === [] => [];
// multiple && value === SelectOption[] => guid[];
// multiple && value === SelectOption => guid[];
// !multiple && value === null => undefined;
// !multiple && value === [] => undefined;
// !multiple && value === SelectOption => guid;
// !multiple && value === SelectOption[] => guid;
export default function (
  value: SelectOption | SelectOption[] | null | undefined,
  multiple?: boolean
): string | string[] | undefined {
  if (multiple) {
    // null | undefined | []
    if (!value || isEmpty(value)) return [];
    // SelectOption[]
    if (Array.isArray(value)) return value.map((v) => v.value);

    // SelectOption
    return [value.value];
  }

  // null | undefined | []
  if (!value || isEmpty(value)) return undefined;
  // SelectOption[]
  if (Array.isArray(value)) return value[0].value;

  // SelectOption
  return value.value;
}
