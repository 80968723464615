import React, { useEffect, useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ErrorMessage } from '@hookform/error-message';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Remove from '@material-ui/icons/Remove';
import Add from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import { ArrayOf } from 'services/Main/types.Field';
import Field from '../Field';
import useStyles from './ArrayOf.styles';
import getFormControlLabel from '../../../utils/getFormControlLabel';
import { useFormatMessage } from '../../../locale';
import { getEmptyRow } from './helpers/getEmptyRow';

export default ({
  control,
  name,
  rowDefinition,
  disallowRowAddition,
  disallowRowDeletion,
  disabled,
  showAutoNumeration,
  addRowButtonText,
  showEmptyRowByDefault = true,
  validationConfig,
}: ArrayOf & { control: any }) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const isRowAdditionEnabled =
    disallowRowAddition !== true && disabled !== true;
  const isRowDeletionEnabled =
    disallowRowDeletion !== true && disabled !== true;

  const emptyRow = useMemo(() => getEmptyRow(rowDefinition), [rowDefinition]);

  useEffect(() => {
    if (fields.length === 0 && showEmptyRowByDefault) {
      append(emptyRow);
    }
  }, [fields, append, emptyRow, showEmptyRowByDefault]);

  return (
    <>
      {fields.map(({ id, ...field }, index) => (
        <Box mb={3} className={classes.rowWrapper} key={id}>
          {showAutoNumeration && (
            <TextField
              className={classes.index}
              label="№"
              value={index + 1}
              disabled
            />
          )}
          <Grid container spacing={1} alignItems="flex-end">
            <Grid
              md={isRowDeletionEnabled ? 11 : 12}
              item
              container
              spacing={1}
            >
              {rowDefinition.map(
                ({ name: rowName, mdCols, label: rowLabel, ...row }) => {
                  // Ищем rowValidationConfig для того, чтобы сформировать label.
                  // Потенциально может вызвать ошибки.
                  const rowValidationConfig = (
                    validationConfig?.validations?.find((v) => v.type === 'of')
                      ?.params[0] as any[]
                  )?.find((v: any) => v.name === rowName)?.validationConfig;
                  const label = getFormControlLabel(
                    rowLabel,
                    rowValidationConfig?.validations
                  );

                  return (
                    <Grid
                      xs={12}
                      key={`${name}[${index}].${rowName}`}
                      item
                      md={mdCols}
                    >
                      <Field
                        {...row}
                        label={label}
                        disabled={disabled || row.disabled}
                        name={`${name}[${index}].${rowName}`}
                        control={control}
                        defaultValue={field[rowName]}
                      />
                    </Grid>
                  );
                }
              )}
            </Grid>
            {isRowDeletionEnabled && (
              <Grid md={1} item>
                <Button
                  variant="text"
                  size="small"
                  disabled={showEmptyRowByDefault && fields.length <= 1}
                  color="primary"
                  onClick={() => remove(index)}
                >
                  <Remove />
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      ))}
      <ErrorMessage
        name={name}
        render={({ message }) => (
          <FormHelperText error>{message}</FormHelperText>
        )}
      />
      {isRowAdditionEnabled && (
        <>
          <br />
          <Button
            variant="text"
            color="primary"
            startIcon={<Add />}
            onClick={() => {
              append(emptyRow);
            }}
          >
            {addRowButtonText || formatMessage('addLine')}
          </Button>
        </>
      )}
    </>
  );
};
