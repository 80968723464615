import { useEffect, useRef } from 'react';
import ScrollManager from '../../../utils/ScrollManager';

export default () => {
  const timelineScrollManager = useRef<ScrollManager>();
  const timelineElementsContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (timelineElementsContainer.current)
      timelineScrollManager.current = new ScrollManager(
        timelineElementsContainer.current,
        timelineElementsContainer.current.scrollHeight,
        'data-timeline-element-id'
      );
    // eslint-disable-next-line
  }, [timelineElementsContainer.current]);

  return { timelineScrollManager, timelineElementsContainer };
};
