import React from 'react';
import Icon from 'components/lowLevel/Icon';
import { File } from 'services/Main/types.Field';
import { getFileUrl } from '../FileUpload/DropZoneFileUpload/helpers';

type FileIconProps = Omit<File, 'size'> & {
  classes: Record<string, string>;
};

const FileIcon = ({ id, name, mimeType, classes }: FileIconProps) => {
  switch (mimeType) {
    case 'image/png':
    case 'image/jpeg':
    case 'image/gif':
    case 'image/svg+xml':
    case 'image/bmp':
      return (
        <div className={classes.image}>
          <img className={classes.imageView} src={getFileUrl(id)} alt={name} />
        </div>
      );
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return (
        <div className={classes.iconContainer}>
          <Icon className={classes.icon} definition="local:FileExcel" />
        </div>
      );
    case 'application/pdf':
      return (
        <div className={classes.iconContainer}>
          <Icon className={classes.icon} definition="local:FilePdf" />
        </div>
      );
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return (
        <div className={classes.iconContainer}>
          <Icon className={classes.icon} definition="local:FileDoc" />
        </div>
      );
    case 'application/zip':
    case 'application/vnd.rar':
    case 'application/tar+gzip':
    case 'application/x-tar':
    default:
      return (
        <div className={classes.iconContainer}>
          <Icon className={classes.icon} definition="local:FileArchive" />
        </div>
      );
  }
};

export default FileIcon;
