import React, { PropsWithChildren } from 'react';
import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useStyles from './FieldGroup.styles';
import Header from '../Header';
import { FormBuilderProps } from '../FormBuilder';
import {
  AccordionProps,
  Header as HeaderProps,
} from '../../../services/Main/types.Component';

interface OtherProps {
  label?: string;
  header?: HeaderProps;
  accordion?: AccordionProps;
}

type FieldGroupProps = PropsWithChildren<
  OtherProps & Pick<FormBuilderProps, 'padding' | 'disableBorder'>
>;

const FieldGroup = ({
  children,
  label,
  disableBorder,
  padding,
  accordion,
  header,
}: FieldGroupProps) => {
  const isAccordionEnabled = !!accordion?.enabled;
  const classes = useStyles({ isAccordionEnabled, disableBorder, padding })();

  const additionalProps: any = {};

  if (!accordion || !accordion.enabled) {
    additionalProps.expanded = true;
  }

  return (
    <Accordion
      className={classes.root}
      defaultExpanded={accordion?.defaultExpanded !== false}
      {...additionalProps}
    >
      {(label || header) && (
        <AccordionSummary
          className={classes.summary}
          expandIcon={isAccordionEnabled && <ExpandMoreIcon />}
        >
          <Grid item xs={12}>
            {label && (
              <Typography variant="body2" className={classes.label}>
                {label}
              </Typography>
            )}
            {header && (
              <Header {...header} headingClassName={classes.heading} />
            )}
          </Grid>
        </AccordionSummary>
      )}
      <AccordionDetails className={classes.detail}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default FieldGroup;
