import React from 'react';
import { SelectOption, HighlightColor } from 'services/Main/types.Field';
import { InputProps } from '../components/sharedTypes';
import MultiSelectInput from '../components/MultiSelectInput/MultiSelectInput';
import SingleSelectInput from '../components/SingleSelectInput';

interface OtherProps {
  highlightColor?: HighlightColor;
}

interface Params {
  multiple: boolean;
  inputProps: InputProps<SelectOption | SelectOption[] | null | undefined> &
    OtherProps;
}

export default ({ multiple, inputProps }: Params) => {
  return multiple ? (
    <MultiSelectInput
      {...(inputProps as InputProps<SelectOption[] | null | undefined> &
        OtherProps)}
    />
  ) : (
    <SingleSelectInput
      {...(inputProps as InputProps<SelectOption | null | undefined> &
        OtherProps)}
    />
  );
};
