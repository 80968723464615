import React, { SyntheticEvent, useEffect, useState } from 'react';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import { TableAction } from 'services/Main/types.Component';
import TableActionComponent from '../TableAction/TableAction';
import { OnReactionExecuteEvent } from '../../../responseReactions/ReactionController';
import useStyles from './TableActions.styles';
import { useFormatMessage } from '../../../locale';

interface TableActionsProps {
  actions: TableAction[];
  onReactionExecute?: OnReactionExecuteEvent;
}

const TableActions = ({ actions, onReactionExecute }: TableActionsProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsDialogOpen(false);
  }, [isMdUp]);

  const handleOpen = (event: SyntheticEvent<{}>) => {
    if ((event.target as HTMLElement)?.closest('.MuiDialog-root')) return;
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <SpeedDial
      className={classes.speedDial}
      ariaLabel={formatMessage('actions')}
      open={isMdUp || isDialogOpen}
      FabProps={{ size: 'small' }}
      icon={<SpeedDialIcon />}
      hidden={isMdUp}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Grid
        container
        direction={isMdUp ? 'row' : 'column'}
        justify="flex-end"
        spacing={1}
      >
        {actions.map((actionProps) => (
          <TableActionComponent
            key={Object.values(actionProps).join('')}
            action={actionProps}
            isDialogOpen={isDialogOpen}
            onReactionExecute={onReactionExecute}
          />
        ))}
      </Grid>
    </SpeedDial>
  );
};

export default TableActions;
