import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import debounce from 'lodash.debounce';
import { TableProps } from '@devexpress/dx-react-grid-material-ui';
import objectHash from 'object-hash';
import {
  ALL_FORMATS,
  DirtyFormValues,
  FetchTableDataOptions,
  ShowAsyncModalOnCellClickReaction,
  SortingState,
  TableComponentProps,
  TableFilterFormComponent,
  TableRow,
} from 'services/Main/types.Component';
import TableView from './Table.view';
import getTableRow from './core/getTableRow';
import getTableCell from './core/getTableCell';
import mainService from '../../../services/Main';
import { findDataTypes } from './helpers';
import { TableContext } from './TableContext';
import { mapValuesToFilterOptions } from '../../lowLevel/TableFilterForm/TableFilterForm';
import { mapValuesToPlain } from '../../lowLevel/FormBuilder/helpers';
import useColumnResizingState from './useColumnResizingState';
import { useFormatMessage } from '../../../locale';
import useEnqueueSnackbar from '../../../utils/hooks/useEnqueueSnackbar';
import useColumnReordering from './useColumnReordering';
import useColumnVisibilityState from './useColumnVisibilityState';
import useDidUpdateEffect from '../../../utils/hooks/useDidUpdateEffect';
import useGetParams from './useGetParams';
import useSerializeStateToGetParams from './useSerializeStateToGetParams';

export default (props: TableComponentProps) => {
  const formatMessage = useFormatMessage();
  const {
    options,
    columnExtensions,
    columns,
    rows: propsRows,
    requestConfig,
    enableColumnResizing,
    enableColumnReordering,
    columnVisibilityConfig,
    filterComponent: propsFilterComponent,
  } = props;

  // Состояние из props.
  const {
    rowClickReaction,
    cellClickReaction,
    totalRows: propsTotalRows,
  } = options;

  const enqueueSnackbar = useEnqueueSnackbar();

  const stateFromGetParams: Partial<FetchTableDataOptions> = useGetParams();

  const fetchTableDataOptions = {
    filter: stateFromGetParams?.filter || {},
    query: stateFromGetParams?.query,
    sort: stateFromGetParams?.sort || props.options.sorting,
    quickFilters: stateFromGetParams?.quickFilters || [],
    withClosed: stateFromGetParams?.withClosed || false,
    currentPage: props.options.currentPage,
    pageSize: props.options.pageSize,
  };

  const [filterComponent, setFilterComponent] = useState<
    TableFilterFormComponent | undefined
  >(propsFilterComponent);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<TableRow[]>(propsRows);
  const [totalRows, setTotalRows] = useState<number>(propsTotalRows);

  const [pageSize, setPageSize] = useState<number>(
    fetchTableDataOptions.pageSize
  );
  const [currentPage, setCurrentPage] = useState<number>(
    fetchTableDataOptions.currentPage
  );

  const [filter, setFilter] = useState<DirtyFormValues | undefined>(
    fetchTableDataOptions.filter
  );
  const [quickFilters, setQuickFilters] = useState<string[]>(
    fetchTableDataOptions.quickFilters || []
  );
  const [query, setQuery] = useState<string | undefined>(
    fetchTableDataOptions.query
  );
  const [sorting, setSorting] = useState<SortingState | undefined>(
    fetchTableDataOptions.sort
  );
  const [withClosed, setWithClosed] = useState<boolean>(
    fetchTableDataOptions.withClosed || false
  );

  const filterOptions = useMemo(
    () =>
      filter &&
      mapValuesToFilterOptions(
        mapValuesToPlain(
          filter,
          propsFilterComponent?.props?.fieldGroups[0].fields || []
        )
      ),
    [filter, propsFilterComponent]
  );

  // Хак для получения актуальных значений state-переменных из callback
  const tableStateRef = useRef<FetchTableDataOptions>({
    requestConfig,
    filter: filterOptions,
    query,
    sort: sorting,
    currentPage,
    quickFilters,
    withClosed,
    pageSize,
  });

  tableStateRef.current = {
    requestConfig,
    filter: filterOptions,
    query,
    sort: sorting,
    currentPage,
    quickFilters,
    withClosed,
    pageSize,
  };

  // Хук для упаковки react-состояния в GET-параметры.
  useSerializeStateToGetParams({
    filter: filterOptions,
    query,
    sort: sorting,
    quickFilters,
    withClosed,
    pageSize,
    currentPage,
  });

  const loadRows = () => {
    setIsLoading(true);

    mainService
      .fetchTableData(tableStateRef.current)
      .then((response) => {
        if (response.filterComponent) {
          setFilterComponent(response.filterComponent);
        }

        setRows(response.rows as TableRow[]);
        setTotalRows(
          (response.options?.totalRows || response.options?.totalRows === 0) &&
            response.options?.totalRows >= 0
            ? response.options?.totalRows
            : propsTotalRows
        );
      })
      .catch(() => {
        enqueueSnackbar(formatMessage('errorOccurredWhileRequestingData'), {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const debouncedLoadRows = useCallback(debounce(loadRows, 250), []);

  // Инициализируем запрос rows при изменении фильтров или поиска.
  // Пропускаем:
  // Первый рендер - создание эффекта.
  const filterOptionsHash = objectHash(filterOptions);
  useDidUpdateEffect(() => {
    setIsLoading(true);
    debouncedLoadRows();
    // eslint-disable-next-line
  }, [filterOptionsHash, quickFilters, withClosed, query, sorting]);

  // Обновляем rows в случае, если rows из props изменились (reLoadModule).
  const propsHash = objectHash({ propsRows, propsTotalRows });

  useEffect(() => {
    setRows(propsRows);
    setTotalRows(propsTotalRows);
    // eslint-disable-next-line
  }, [propsHash]);

  const dataTypeProvidersProps = findDataTypes(
    columns,
    Array.from(ALL_FORMATS)
  );

  const tableProps: TableProps = useMemo(
    () => ({
      columnExtensions,
    }),
    [columnExtensions]
  );

  tableProps.rowComponent = useMemo(
    () => getTableRow(rowClickReaction),
    [rowClickReaction]
  );

  if (
    cellClickReaction &&
    cellClickReaction.type === 'showAsyncModalOnCellClickReaction'
  ) {
    tableProps.cellComponent = useMemo(
      () =>
        getTableCell(cellClickReaction as ShowAsyncModalOnCellClickReaction),
      [cellClickReaction]
    );
  }

  const sortingStateColumnExtensions = useMemo(
    () =>
      columns?.reduce(
        (acc, column) => [
          ...acc,
          {
            columnName: column.name,
            sortingEnabled: !!column.options?.sortable,
          },
        ],
        [] as any[]
      ),
    [columns]
  );

  const someColumnSortable = useMemo(
    () => columns && columns.some((column) => column.options?.sortable),
    [columns]
  );

  const someColumnHasTitle = useMemo(
    () => columns && columns.some((column) => column.title),
    [columns]
  );

  const [columnWidths, setColumnWidths, resetColumnWidthsToDefault] =
    useColumnResizingState(columns, columnExtensions, enableColumnResizing);

  const { columnOrder, setColumnOrder, resetColumnOrderToDefault } =
    useColumnReordering(columns, enableColumnReordering);

  const [hiddenColumnNames, setHiddenColumnNames, resetColumnWidthToDefault] =
    useColumnVisibilityState(columns, columnVisibilityConfig);

  return (
    <TableContext.Provider
      value={{
        ...props,
        resetColumnWidthsToDefault,
        resetColumnOrderToDefault,
        quickFiltersState: quickFilters,
        setQuickFilters,
        withClosed,
        setWithClosed,
        setHiddenColumnNames,
        hiddenColumnNames,
        resetColumnWidthToDefault,
      }}
    >
      <TableView
        {...props}
        filterComponent={filterComponent}
        options={{
          ...props.options,
          totalRows,
          sorting,
          currentPage,
          pageSize,
        }}
        rows={rows}
        isLoading={isLoading}
        tableProps={tableProps}
        dataTypeProvidersProps={dataTypeProvidersProps}
        setSorting={setSorting}
        sortingStateColumnExtensions={sortingStateColumnExtensions}
        someColumnSortable={someColumnSortable}
        someColumnHasTitle={someColumnHasTitle}
        query={query}
        setQuery={setQuery}
        filter={filter}
        setFilter={setFilter}
        loadRows={debouncedLoadRows}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        columnWidths={columnWidths}
        setColumnWidths={setColumnWidths}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
        hiddenColumnNames={hiddenColumnNames}
      />
    </TableContext.Provider>
  );
};
