import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import get from 'lodash.get';
import {
  Chip,
  InputAdornment,
  TextField as MuiTextField,
} from '@material-ui/core';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete';
import useStyles from './createRenderInput.styles';
import { FlatComboBoxProps, TreeComboBoxProps } from '../types';

export default (props: TreeComboBoxProps | FlatComboBoxProps) =>
  function renderInput(params: AutocompleteRenderInputParams): ReactNode {
    return <TextField params={params} comboBoxProps={props} />;
  };

interface TextFieldProps {
  params: AutocompleteRenderInputParams;
  comboBoxProps: TreeComboBoxProps | FlatComboBoxProps;
}

function TextField({ params, comboBoxProps }: TextFieldProps) {
  const classes = useStyles();
  const { errors } = useFormContext();
  const { disabled, helperText, label, name, value, warningText } =
    comboBoxProps;
  const error = get(errors, name);
  const multiple =
    (comboBoxProps as FlatComboBoxProps).multiple ||
    comboBoxProps.optionsType === 'checkboxTree';

  return (
    <MuiTextField
      {...params}
      className={clsx(classes.textField, {
        warning: warningText,
      })}
      disabled={disabled}
      name={name}
      label={label}
      error={!!error}
      helperText={error?.message || warningText || helperText}
      InputProps={{
        ...params.InputProps,
        readOnly: disabled,
        classes: {
          root: classes.inputRoot,
        },
        endAdornment: (
          <InputAdornment position="end">
            {multiple && value?.length > 0 && (
              <Chip label={value.length} disabled={disabled} size="small" />
            )}
            {!disabled && params.InputProps.endAdornment}
          </InputAdornment>
        ),
      }}
    />
  );
}
