import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import MuiGrid from '@material-ui/core/Grid';
import Icon from '../Icon';
import TableFilterForm from '../TableFilterForm';
import { TableFilterFormProps } from '../TableModules';
import { getFilterIconName } from './helpers';

const TableFilters = ({
  filter,
  filterChips,
  setFilter,
  ...rest
}: TableFilterFormProps) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);

  return (
    <>
      <MuiGrid item>
        <IconButton onClick={() => setIsFiltersOpen(true)}>
          <Icon definition={getFilterIconName(filterChips?.length)} />
        </IconButton>
        <Drawer
          anchor="right"
          open={isFiltersOpen}
          keepMounted
          onClose={() => setIsFiltersOpen(false)}
        >
          <TableFilterForm filter={filter} setFilter={setFilter} {...rest} />
        </Drawer>
      </MuiGrid>
    </>
  );
};

export default TableFilters;
