import { useCallback, useContext, useEffect, useState } from 'react';
import { TimelineContext } from '../../highLevel/Timeline/TimelineContext';

export default () => {
  const timelineContext = useContext(TimelineContext);
  const [shouldDisplay, setShouldDisplay] = useState<boolean>(false);
  const timelineElements = timelineContext?.timelineElements;
  const [hasUnreadMessage, setHasUnreadMessage] = useState<boolean>(false);

  const calculateShouldDisplay = useCallback(() => {
    const timelineScrollManager =
      timelineContext?.timelineScrollManager?.current;

    if (timelineScrollManager) {
      const newShouldDisplay = !timelineScrollManager.isScrolledToBottom;
      setShouldDisplay(newShouldDisplay);

      if (!newShouldDisplay) {
        setHasUnreadMessage(false);
      }
    }
  }, [timelineContext]);

  useEffect(() => {
    setTimeout(() => {
      timelineContext?.timelineScrollManager?.current?.container.addEventListener(
        'scroll',
        calculateShouldDisplay
      );
    }, 0); // workaround для избежания навешивания события на undefined.

    return () => {
      timelineContext?.timelineScrollManager?.current?.container.removeEventListener(
        'scroll',
        calculateShouldDisplay
      );
    };
    // eslint-disable-next-line
  }, []);

  const timelineElementsLength = timelineElements?.length;
  useEffect(() => {
    calculateShouldDisplay();

    // setTimeout нужен для того, чтобы это не срабатывало
    // при работе с формой добавления свободного комментария.
    setTimeout(() => {
      if (
        timelineContext?.timelineScrollManager?.current &&
        !timelineContext?.timelineScrollManager?.current?.isScrolledToBottom
      ) {
        setHasUnreadMessage(true);
      }
    }, 0);
    // eslint-disable-next-line
  }, [timelineElementsLength]);

  return { shouldDisplay, hasUnreadMessage };
};
