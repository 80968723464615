import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import { AutocompleteInputChangeReason } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import { useFormContext } from 'react-hook-form';
import get from 'lodash.get';
import mainService from 'services/Main';
import { SelectOption } from 'services/Main/types.Field';
import renderInput from './renderInput';
import { DependentSelectContainerProps } from './types';
import useStyles from '../Select.styles';
import { useFormatMessage } from '../../../../locale';
import Tags from '../../ComboBox/components/Tags';

/**
 * NOTICE: Поле всегда является multiple (множественным), т.к.
 * хочется обеспечить логичное поведение при работе с единичным
 * выбором.
 *
 * Для того чтобы это реализовать, передаётся проп multiple и в
 * обработчике изменений (handleChange) есть проверка на количество
 * выбранных вариантов, т.е. в SingleSelect не может быть больше
 * одного варианта в rhf value.
 */
export default ({
  disabled,
  label,
  searchUrl,
  value,
  helperText,
  onChange,
  name,
  multiple,
  reFetchOptionsCountAndReason,
  allDependencies,
  dependOnFieldValues,
  dependOn,
  watchOn,
  isParentEmpty,
  sequential,
  highlightColor,
  autoSelect,
}: DependentSelectContainerProps & {
  reFetchOptionsCountAndReason: string;
  allDependencies: Record<string, string | string[] | undefined>;
  isParentEmpty: boolean;
  dependOnFieldValues: Record<string, string | string[] | undefined> | null;
}) => {
  const classes = useStyles(highlightColor)();
  const formatMessage = useFormatMessage();
  const { errors } = useFormContext();
  const error = get(errors, name);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>([]);
  const [warningText, setWaringText] = useState<string | undefined>();

  const loadOptions = useCallback(
    (dependencies?: Record<string, string | string[] | undefined>) => {
      setIsLoading(true);

      mainService
        .fetchDependentSelectOptions(searchUrl, {
          value: inputValue,
          dependentFieldsValues: dependencies || allDependencies,
        })
        .then(({ options: newOptions, warningText: newWarningText }) => {
          setWaringText(newWarningText || undefined);
          setOptions(newOptions);
          setFilteredOptions(newOptions);
          setIsLoading(false);

          // Скипаем следующие условия, т.к. мы вызываем `loadOptions` с параметром
          // `dependencies` только при очистке поля. А при очистке поля никакие
          // автоподстановки делать не следует.
          if (dependencies) return false;

          if (autoSelect && newOptions.length === 1) {
            onChange(newOptions);
            return false;
          }

          // Если уже есть выбранные значения и
          // Если это ведомое и
          // То отфильтруем value по наличию в newOptions.
          if (value && value.length > 0) {
            const filteredValues = value.filter((option) => {
              return newOptions.map((o) => o.value).includes(option.value);
            });

            if (dependOn && dependOn.length > 0 && filteredValues) {
              onChange(filteredValues);
              return false;
            }
          }

          return false;
        });
    },
    // eslint-disable-next-line
    [allDependencies, searchUrl, inputValue, value, dependOn]
  );

  useEffect(() => {
    // Предотвращаем лишние запросы при условии, если
    // У этого поля заполнено значение и
    // Оно является ведущим для какого-то поля и
    // Меняется дочерний элемент.
    if (
      value &&
      value.length > 0 &&
      watchOn &&
      watchOn.length > 0 &&
      reFetchOptionsCountAndReason.includes('childChanged')
    ) {
      return;
    }

    loadOptions();
    // eslint-disable-next-line
  }, [reFetchOptionsCountAndReason]);

  useEffect(() => {
    setFilteredOptions(
      options.filter((o) =>
        o.label.toLowerCase().includes(inputValue.toLowerCase())
      )
    );
    // eslint-disable-next-line
  }, [inputValue]);

  const handleInputChange = useCallback(
    (
      event: ChangeEvent<{}>,
      newInputValue: string,
      reason: AutocompleteInputChangeReason
    ) => {
      // Игнорируем программные вызовы handleInputChange
      if (event?.type === 'change') setInputValue(newInputValue);

      // Сбрасываем значение input при выборе варианта
      if (
        (event?.type === 'keydown' || event?.type === 'click') &&
        reason === 'reset'
      )
        setInputValue('');
    },
    []
  );

  const handleChange = (
    event: any,
    changedValue: SelectOption[] | null,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'clear') {
      loadOptions(dependOnFieldValues || {});
    }

    let newOptions = changedValue;

    if (!multiple && newOptions && newOptions?.length > 1) {
      newOptions = newOptions?.slice(-1);
    }

    onChange(newOptions);
  };

  const isSequentialAndParentEmpty =
    sequential && dependOn && dependOn.length > 0 && isParentEmpty;

  return (
    <Autocomplete
      className={clsx(classes.autocompleteRoot, {
        warning: !!warningText,
      })}
      classes={{
        inputRoot: classes.inputRoot,
        input: classes.input,
      }}
      disableCloseOnSelect={multiple}
      multiple
      loading={isLoading}
      loadingText={formatMessage('loading')}
      noOptionsText={formatMessage('noOptions')}
      clearText={formatMessage('clear')}
      closeText={formatMessage('close')}
      openText={formatMessage('open')}
      disabled={isSequentialAndParentEmpty || disabled}
      value={value || []}
      inputValue={inputValue}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(o, v) => o.value === v.value}
      getOptionDisabled={(option) =>
        !!option.disabled && !value?.some((o) => o.value === option.value)
      }
      filterOptions={(o) => o}
      includeInputInList
      options={filteredOptions}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={(params: AutocompleteRenderInputParams) =>
        renderInput({
          multiple: !!multiple,
          inputProps: {
            params,
            value,
            name,
            label,
            error,
            helperText: helperText || warningText,
            highlightColor,
          },
        })
      }
      renderOption={(option, { selected }) => (
        <span
          className={clsx(classes.option, {
            disabledAndSelected: !!option.disabled && selected,
          })}
        >
          {option.label}
        </span>
      )}
      renderTags={(tags) => <Tags tags={tags} />}
    />
  );
};
