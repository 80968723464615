import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { IconDefinition } from '../../../services/Main/types.common';
import Icon from '../Icon/Icon';

interface IconWithTooltipProps {
  definition: IconDefinition;
  tooltipText: string;
}

const IconWithTooltip = ({ definition, tooltipText }: IconWithTooltipProps) => (
  <Tooltip title={tooltipText} enterTouchDelay={50}>
    <div>
      <Icon definition={definition} />
    </div>
  </Tooltip>
);

export default IconWithTooltip;
