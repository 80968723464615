import React, { Dispatch, SetStateAction } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { PeriodFormProps } from '../../../services/Main/types.Component';
import useStyles from './WidgetContainer.styles';
import { SelectOption } from '../../../services/Main/types.Field';

type Props = PeriodFormProps & {
  period: SelectOption | undefined;
  setPeriod: Dispatch<SetStateAction<SelectOption | undefined>>;
};

export default ({ options, period, setPeriod }: Props) => {
  const classes = useStyles();

  const handleChange: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    child: React.ReactNode
  ) => void = (event) => {
    setPeriod(options.find((o) => o.value === (event.target.value as string)));
  };

  return (
    <Select
      classes={{ root: classes.selectRoot, icon: classes.selectIcon }}
      defaultValue="currentMonth"
      name="period"
      value={period?.value}
      onChange={handleChange}
    >
      {options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};
