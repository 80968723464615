import React from 'react';
import {
  Select,
  AsyncSelect as AsyncSelectProps,
  AsyncMultiSelect as AsyncMultiSelectProps,
  MultiSelect as MultiSelectProps,
  SingleSelect as SingleSelectProps,
  DependentSelect as DependentSelectProps,
} from 'services/Main/types.Field';
import AsyncSelect from './AsyncSelect';
import AsyncMultiSelect from './AsyncMultiSelect';
import MultiSelect from './MultiSelect';
import SingleSelect from './SingleSelect';
import DependentSelect from './DependentSelect';

export default ({ selectType, ...rest }: Select) => {
  switch (selectType) {
    case 'async':
      return <AsyncSelect {...(rest as AsyncSelectProps)} />;
    case 'asyncMulti':
      return <AsyncMultiSelect {...(rest as AsyncMultiSelectProps)} />;
    case 'multi':
      return <MultiSelect {...(rest as MultiSelectProps)} />;
    case 'single':
      return <SingleSelect {...(rest as SingleSelectProps)} />;
    case 'dependent':
      return <DependentSelect {...(rest as DependentSelectProps)} />;
    default:
      throw new Error('Не удалось опредилить тип Select-компонента');
  }
};
