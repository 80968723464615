import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    SystemTimelineElementContainer: {
      marginLeft: 'auto',
      marginRight: 'auto',
      borderBottom: '1px dashed #eee',
      borderTop: '1px dashed #eee',
      flexDirection: 'column',

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
  })
);
