import { ParsableDate } from 'services/Main/types.common';
import isValidDate from 'utils/isValidDate';
import { parseISO } from 'date-fns';

/**
 * Вспомогательная функция для возврата даты из разных возможных
 * (пришедших из бека) форматов дат.
 *
 * Может работать с ISO строками и UNIX timestamp.
 *
 * @param initialDate
 * @param fallbackDate
 */
export function getDate(
  initialDate?: ParsableDate,
  fallbackDate: Date | undefined | null = null
) {
  if (typeof initialDate === 'string' && initialDate !== '') {
    return isValidDate(parseISO(initialDate))
      ? parseISO(initialDate)
      : fallbackDate;
  }

  if (typeof initialDate === 'number') {
    return new Date(initialDate * 1000);
  }

  return fallbackDate;
}
