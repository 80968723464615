import React, { useState } from 'react';
import clsx from 'clsx';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { TreeItem } from 'performant-array-to-tree';
import { IconButton, Paper, Popper, Box } from '@material-ui/core';
import { useAutocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { AutocompleteInputChangeReason } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import { TreeComboBoxProps, TreeSelectOptionWithChildren } from '../../types';
import {
  transformLastChildrenToUndefined,
  useDefaultExpanded,
  useDefaultNodes,
  useGroupedOptionsByValue,
} from './treeHelpers';
import createRenderInput from '../../helpers/createRenderInput';
import renderTags from '../../helpers/renderTags';
import useStyles from './TreeComboBox.styles';
import getCheckboxTreeIcons from '../../helpers/getCheckboxTreeIcons';
import useLikeSearch from './useLikeSearch';
import { useFormatMessage } from '../../../../../locale';

const TreeComboBox = (props: TreeComboBoxProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const { disabled, options, optionsType, name, value, onChange } = props;
  const isCheckboxTree = optionsType === 'checkboxTree';
  const defaultExpanded = useDefaultExpanded(value, options);
  const defaultNodes = useDefaultNodes(options);

  const [nodes, setNodes] = useState<TreeItem[]>(defaultNodes);
  const [expanded, setExpanded] = useState<string[] | undefined>(
    defaultExpanded
  );

  const groupedOptionsByValue = useGroupedOptionsByValue(options);
  const { isTreeHidden, filterText, setFilterText } = useLikeSearch({
    options,
    defaultNodes,
    setExpanded,
    setNodes,
  });

  // В node лежит TreeSelectOption и дополнительные параметры от CheckboxTree.
  const handleCheck = (checkedOptions: string[], node: { value: string }) => {
    if (disabled) return;

    const newOptionsValue = isCheckboxTree ? checkedOptions : [node.value];

    setFilterText('');
    onChange(newOptionsValue.map((v) => groupedOptionsByValue[v]));
  };

  const handleClear = () => {
    if (disabled) return;

    setFilterText('');
    setExpanded(undefined);
    onChange([]);
  };

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    inputValue: string,
    reason: AutocompleteInputChangeReason
  ) => {
    // Чтобы не очищалось при работе с чекбоксами/радио.
    if (reason === 'reset') return;

    setFilterText(inputValue);
  };

  const {
    anchorEl,
    dirty,
    focused,
    focusedTag,
    id,
    popupOpen,
    getClearProps,
    getPopupIndicatorProps,
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    setAnchorEl,
  } = useAutocomplete({
    multiple: true,
    disableClearable: disabled,
    options,
    filterOptions: (o) => o,
    value,
    inputValue: filterText,
    onInputChange: handleInputChange,
  });

  return (
    <div>
      <div
        className={clsx(classes.root, classes.fullWidth, {
          [classes.hasPopupIcon]: !disabled,
          [classes.focused]: focused,
          [classes.hasClearIcon]: !disabled,
        })}
        {...getRootProps()}
      >
        {createRenderInput(props)({
          id,
          size: undefined,
          InputProps: {
            ref: setAnchorEl,
            className: classes.inputRoot,
            startAdornment:
              Array.isArray(value) &&
              value.length > 0 &&
              renderTags({
                multiline: (props as { multiline?: boolean })?.multiline,
              })(value),
            endAdornment: (
              <div className={classes.endAdornment}>
                {!disabled && (
                  <>
                    <IconButton
                      {...getClearProps()}
                      aria-label={formatMessage('clear')}
                      title={formatMessage('clear')}
                      className={clsx(classes.clearIndicator, {
                        [classes.clearIndicatorDirty]: dirty,
                      })}
                      onClick={handleClear}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      {...getPopupIndicatorProps()}
                      disabled={disabled}
                      aria-label={
                        popupOpen
                          ? formatMessage('close')
                          : formatMessage('open')
                      }
                      title={
                        popupOpen
                          ? formatMessage('close')
                          : formatMessage('open')
                      }
                      className={clsx(classes.popupIndicator, {
                        [classes.popupIndicatorOpen]: popupOpen,
                      })}
                    >
                      <ArrowDropDownIcon />
                    </IconButton>
                  </>
                )}
              </div>
            ),
          },
          InputLabelProps: getInputLabelProps(),
          inputProps: {
            className: clsx(classes.input, {
              [classes.inputFocused]: focusedTag === -1,
            }),
            ...getInputProps(),
            disabled,
          },
          disabled: !!disabled,
          fullWidth: true,
        })}
      </div>
      {popupOpen && anchorEl && (
        <Popper
          open
          anchorEl={anchorEl}
          className={classes.popper}
          role="presentation"
          style={{ width: anchorEl ? anchorEl.clientWidth : undefined }}
        >
          <Paper className={classes.paper} {...getListboxProps()}>
            {isTreeHidden && (
              <div className={classes.noOptions}>
                {formatMessage('noOptions')}
              </div>
            )}
            <Box hidden={isTreeHidden}>
              <CheckboxTree
                name={name}
                noCascade={!isCheckboxTree}
                nodes={
                  nodes.map((node) =>
                    transformLastChildrenToUndefined(
                      node as TreeSelectOptionWithChildren
                    )
                  ) as any // тип не экспортируется из библиотеки
                }
                disabled={disabled}
                icons={getCheckboxTreeIcons(optionsType, formatMessage)}
                showExpandAll
                showNodeIcon={false}
                checked={value.map((o) => o.value)}
                expanded={expanded}
                checkModel="leaf"
                optimisticToggle={false}
                onCheck={handleCheck}
                onExpand={setExpanded}
              />
            </Box>
          </Paper>
        </Popper>
      )}
    </div>
  );
};

export default TreeComboBox;
