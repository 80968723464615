import React from 'react';
import { Container, Typography, Paper } from '@material-ui/core';
import { DocRenderer, textFileLoader } from 'react-doc-viewer';
import useStyles from './TextRenderer.styles';

const TextRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
  const classes = useStyles();

  if (!currentDocument) return null;

  return (
    <Container>
      <Paper className={classes.paper} elevation={4}>
        <Typography component="pre" variant="body2" className={classes.text}>
          {currentDocument.fileData}
        </Typography>
      </Paper>
      <div style={{ width: '100%', height: 12 }} />
    </Container>
  );
};

TextRenderer.weight = 3;
TextRenderer.fileTypes = ['txt', 'text/plain'];
TextRenderer.fileLoader = textFileLoader;

export default TextRenderer;
