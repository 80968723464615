import React, {
  useMemo,
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import { Pagination } from '@material-ui/lab';
import {
  Select,
  InputBase,
  MenuItem,
  useTheme,
  useMediaQuery,
  Grid,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import useStyles from '../../Table.styles';
import { TableContext } from '../../TableContext';
import { useFormatMessage } from '../../../../../locale';

interface PagingPanelProps {
  totalRows: number;
  pageSize: number;
  setPageSize: Dispatch<SetStateAction<number>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  loadRows: () => void;
  pageSizeOptions?: number[];
}

const PagingPanel = ({
  setCurrentPage,
  loadRows,
  pageSize,
  setPageSize,
  totalRows,
  currentPage,
  pageSizeOptions,
}: PagingPanelProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const tableContext = useContext(TableContext);

  const handleChange = (e: ChangeEvent<unknown>, newPage: number) => {
    const newCurrentPage = newPage - 1;

    // Исключаем повторное нажатие на одну и туже страницу
    if (currentPage === newCurrentPage) return;

    setCurrentPage(newCurrentPage);

    loadRows();
  };

  const handleChangePageSize = (event: ChangeEvent<{ value: unknown }>) => {
    const newPageSize = event.target.value as number;

    setPageSize(newPageSize);
    setCurrentPage(0);

    loadRows();
  };

  const totalPages = useMemo(
    () => Math.ceil(totalRows / pageSize),
    [totalRows, pageSize]
  );

  return (
    <Grid container spacing={1} className={classes.pagingPanelContainer}>
      {Array.isArray(pageSizeOptions) &&
        pageSizeOptions.length > 1 &&
        pageSizeOptions !== [0] && (
          <Grid item>
            <Typography component="span" variant="body2">
              {`${formatMessage('rowsPerPage')}:`}
            </Typography>
            <Select
              classes={{
                select: classes.select,
              }}
              input={<InputBase className={clsx(classes.input)} />}
              value={pageSize}
              onChange={handleChangePageSize}
            >
              {pageSizeOptions.map((rowsPerPageOption) => (
                <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
                  {rowsPerPageOption}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
      {tableContext?.options?.pageSize &&
        totalRows > tableContext?.options?.pageSize && (
          <Grid item>
            <Pagination
              count={totalPages}
              page={currentPage + 1}
              size={isMdUp ? 'medium' : 'small'}
              onChange={handleChange}
              showFirstButton={isMdUp}
              showLastButton={isMdUp}
            />
          </Grid>
        )}
    </Grid>
  );
};

export default PagingPanel;
