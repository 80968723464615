import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('sm')]: {
        width: 280,
      },
      [theme.breakpoints.up('md')]: {
        width: 325,
      },
      padding: theme.spacing(2),
    },
  })
);
