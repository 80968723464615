import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Table as DevExpressTable,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { ShowAsyncModalOnCellClickReaction } from 'services/Main/types.Component';
import AsyncDialog from 'components/lowLevel/AsyncDialog';
import useStyles from './getTableCell.styles';

const getTableCell = (
  cellClickReaction: ShowAsyncModalOnCellClickReaction
) => ({
  children,
  column,
  tableColumn,
  tableRow,
  row,
  value,
  className,
  ...rest
}: DevExpressTable.DataCellProps & any) => {
  const classes = useStyles(row)();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  // Возвращаем дефолтный Cell, если не хотим
  // навешивать обработчик клика.
  if (!cellClickReaction.columnNames.includes(column.name)) {
    return (
      <VirtualTable.Cell
        tableRow={tableRow}
        tableColumn={tableColumn}
        row={row}
        column={column}
        value={value}
        className={clsx(className, classes.cell)}
        {...rest}
      >
        {children}
      </VirtualTable.Cell>
    );
  }

  return (
    <>
      <VirtualTable.Cell
        tableRow={tableRow}
        tableColumn={tableColumn}
        row={row}
        column={column}
        value={value}
        className={clsx(className, classes.clickableCell)}
        onClick={() => setModalOpen((prevState) => !prevState)}
        {...rest}
      >
        {children}
      </VirtualTable.Cell>
      <AsyncDialog
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        fetchModalDataRequestConfig={
          cellClickReaction.fetchModalDataRequestConfig
        }
        fetchModalDataRequestParams={{ column, row }}
      />
    </>
  );
};

export default getTableCell;
