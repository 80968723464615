import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    TimelineElementsContainer: {
      padding: theme.spacing(0, 1),
      overflowY: 'auto',
      flexGrow: 1,
    },
  })
);
