import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormBuilder from '../FormBuilder';
import FormAction from '../FormAction';
import { TimelineContext } from '../../highLevel/Timeline/TimelineContext';
import TimelineQuote from '../TimelineQuote';
import Icon from '../Icon';
import useStyles from './AddTimelineElementForm.styles';
import { AddTimelineElementFormProps } from './types';
import { useFormatMessage } from '../../../locale';
import { ComponentContext } from '../../creational/ComponentLoader';

const AddTimelineElementForm = ({
  addTimelineElementForm,
  addCommentUrl,
  closeCollapse,
}: AddTimelineElementFormProps & {
  closeCollapse: () => void;
}) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const componentContext = useContext(ComponentContext);
  const timelineContext = useContext(TimelineContext);

  const handleQuoteClear = () => {
    timelineContext?.setQuote(null);
  };

  return (
    <div>
      {timelineContext?.quote && (
        <Grid
          className={classes.QuoteContainer}
          container
          alignItems="center"
          spacing={1}
          wrap="nowrap"
        >
          <Grid item className={classes.ClearQuoteButtonContainer}>
            <Grid container alignItems="center">
              <Icon
                className={classes.ClearQuoteButton}
                definition="mui:HighlightOff"
                onClick={handleQuoteClear}
              />
            </Grid>
          </Grid>
          <Grid item className={classes.TimelineQuoteContainer}>
            <TimelineQuote {...timelineContext.quote} />
          </Grid>
        </Grid>
      )}
      <FormBuilder
        id={
          componentContext
            ? `${componentContext.id}_timeline`
            : 'unknown_timeline'
        }
        disableBorder
        padding="0"
        fieldGroups={[{ fields: addTimelineElementForm }]}
        footer={(props) => (
          <Grid container spacing={1} justify="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  closeCollapse();
                  props.reset();
                  timelineContext?.setQuote(null);
                }}
              >
                {formatMessage('cancel')}
              </Button>
            </Grid>
            <Grid item>
              <FormAction
                action={{
                  type: 'submitForm',
                  label: formatMessage('send'),
                  requestConfig: addCommentUrl,
                  successResponseReaction: {
                    type: 'reLoadModule',
                  },
                  variant: 'contained',
                  color: 'primary',
                  icon: 'mui:Send',
                }}
                onReactionExecute={() => {
                  props.reset();
                  timelineContext?.setQuote(null);
                  timelineContext?.setIsAddTimelineElementFormOpened(false);
                  timelineContext?.timelineScrollManager.current?.scrollToBottom();
                }}
              />
            </Grid>
          </Grid>
        )}
      />
    </div>
  );
};

export default AddTimelineElementForm;
