import React, { ReactNode } from 'react';
import { ComboBoxOption } from '../../../../services/Main/types.Field';
import Tags from '../components/Tags';

export interface RenderTagsProps {
  multiline?: boolean;
}

export default (props: RenderTagsProps) => {
  // MUI: (value: T[], getTagProps: AutocompleteGetTagProps) => React.ReactNode
  return function renderTags(tags: ComboBoxOption[]): ReactNode {
    return <Tags tags={tags} {...props} />;
  };
};
