import { MouseEvent, useCallback, useState } from 'react';
import axios from 'axios';
import { post } from 'services/Api';
import { LinearProgressbarProps } from 'components/lowLevel/LinearProgressbar';
import { ServerResponse } from '../../../../services/Main/types';
import { HandleChangePending } from './types';
import { useFormatMessage } from '../../../../locale';
import useEnqueueSnackbar from '../../../../utils/hooks/useEnqueueSnackbar';

export const useOnDropHandler = (
  files: any,
  name: string,
  disabled: boolean,
  onChange: any,
  onUploadProgress: any,
  resetProgress: () => void,
  setCancelRequest: (value: any) => void,
  handleChangePending?: HandleChangePending
) => {
  const formatMessage = useFormatMessage();
  const enqueueSnackbar = useEnqueueSnackbar();

  return useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0 || disabled) {
        return;
      }

      const formData = new FormData();

      acceptedFiles.forEach((file) => formData.append(name, file));

      const uploadStartedAt = new Date();

      handleChangePending && handleChangePending(true);

      post<ServerResponse<string[]>>('/upload/v1.0', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        cancelToken: new axios.CancelToken(function executor(cancelFn: any) {
          setCancelRequest(() => () => {
            handleChangePending && handleChangePending(false);
            // удаляем метку в сторе о том, что файл загружается
            resetProgress(); // удаляем прогрессбар
            cancelFn(); // отменяем xhr-запрос
          });
        }),
        onUploadProgress: onUploadProgress(uploadStartedAt),
      })
        .then(({ data }) => {
          if (
            !data ||
            !Array.isArray(data.payload) ||
            data.payload.length === 0
          ) {
            enqueueSnackbar(formatMessage('errorWhileUploadingFiles'), {
              variant: 'error',
            });
            return;
          }

          const { payload } = data;

          // TODO Сейчас при загрузке MSG форматов mimeType = ''
          // @ts-ignore
          const newFiles = acceptedFiles.map((file, index) => {
            return {
              id: payload[index],
              name: file.name,
              size: file.size,
              mimeType: file.type,
            };
          });

          onChange(files.concat(newFiles));
        })
        .catch((error) => {
          resetProgress();
          console.log('error', error);

          const messageKey =
            error?.response?.status === 413
              ? 'fileTooLargeError'
              : 'errorWhileUploadingFiles';

          enqueueSnackbar(formatMessage(messageKey), {
            variant: 'error',
          });
        })
        .finally(() => {
          handleChangePending && handleChangePending(false);
        });
    },
    [
      files,
      name,
      disabled,
      onChange,
      onUploadProgress,
      resetProgress,
      setCancelRequest,
      handleChangePending,
      formatMessage,
      enqueueSnackbar,
    ]
  );
};

export const useProgress = (): [
  LinearProgressbarProps | undefined,
  (uploadStartedAt: any) => (props: ProgressEvent) => void,
  () => void,
  any | undefined,
  (value: any) => void
] => {
  const [progress, setProgress] = useState<
    LinearProgressbarProps | undefined
  >();
  const [cancelRequest, setCancelRequest] = useState<any>();
  const handleUploadProgress =
    (uploadStartedAt: any) =>
    ({ lengthComputable, loaded, total }: ProgressEvent) => {
      const timeElapsed = new Date().getTime() - uploadStartedAt.getTime();

      let remainingTimeInSeconds;
      // Время старта больше 15 секунд
      if (timeElapsed > 100) {
        const uploadSpeed = loaded / (timeElapsed / 1000);
        remainingTimeInSeconds = Math.round((total - loaded) / uploadSpeed);
      }

      if (lengthComputable) {
        setProgress({
          percent: Math.round((loaded / total) * 100),
          remainingTimeInSeconds,
        });
      }
    };

  const resetProgress = () => {
    setProgress(undefined);
  };

  return [
    progress,
    handleUploadProgress,
    resetProgress,
    cancelRequest,
    setCancelRequest,
  ];
};

export const createRemoveHandler =
  (files: any, onChange: any) => (fileId: string) => (event: MouseEvent) => {
    event.preventDefault();

    onChange(files.filter((file: any) => file.id !== fileId));
  };
