import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 'auto',
      minHeight: '100vh',
      padding: theme.spacing(3),
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    typographyHeader: {
      color: theme.additionalPalette.title,
      marginBottom: theme.spacing(3),
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(23),
      fontWeight: 700,
      [theme.breakpoints.up('sm')]: {
        textAlign: 'left',
        fontSize: theme.typography.pxToRem(48),
      },
    },
    typography: {
      color: theme.additionalPalette.title,
      marginBottom: theme.spacing(3),
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        textAlign: 'left',
      },
    },
    logoGrid: {
      [theme.breakpoints.up('md')]: {
        order: 1,
      },
    },
    textBlock: {
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'left',
      },
    },
    button: {
      textDecoration: 'none',
    },
  })
);
