import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Dropdown from '@material-ui/core/Menu';
import AutoSizer, { AutoSizerProps, Size } from 'react-virtualized-auto-sizer';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import objectHash from 'object-hash';
import useStyles from './WidgetContainer.styles';
import {
  DirtyFormValues,
  WidgetInteractionConfig,
} from '../../../services/Main/types.Component';
import PlaneForm from './PlaneForm';
import getInitialPlanes from './getInitialPlanes';
import PeriodForm from './PeriodForm';
import chartService from '../../../services/Chart';
import { SelectOption } from '../../../services/Main/types.Field';

interface WidgetContainerProps<T> {
  style?: CSSProperties;
  heading?: string;
  interactionConfig?: WidgetInteractionConfig;
  autoSizerProps?: Omit<AutoSizerProps, 'children'>;
  handleStateChange?: (payload: T) => void;
  children: (size: Size) => ReactNode;
}

function WidgetContainer<T>({
  heading,
  style,
  autoSizerProps,
  interactionConfig,
  handleStateChange,
  children,
}: WidgetContainerProps<T>) {
  const classes = useStyles();
  const planeFormFields =
    interactionConfig?.planeForm?.props?.fieldGroups[0].fields || undefined;
  const [isFirstRequest, setIsFirstRequest] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dirtyPlanes, setDirtyPlanes] = useState<DirtyFormValues | undefined>(
    planeFormFields ? getInitialPlanes(planeFormFields) : undefined
  );
  const [period, setPeriod] = useState<SelectOption | undefined>(
    interactionConfig?.period?.defaultValue
  );

  const stateHash = objectHash({ dirtyPlanes, period });
  useEffect(() => {
    if (isFirstRequest) {
      setIsFirstRequest(false);
      return;
    }

    if (!interactionConfig?.requestConfig) return;

    chartService
      .updateData<T>(interactionConfig.requestConfig, {
        period,
        planes: dirtyPlanes,
      })
      .then((result) => {
        handleStateChange && handleStateChange(result.payload);
      });
    // eslint-disable-next-line
  }, [stateHash]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={style}>
      <CardHeader
        classes={{ root: classes.root }}
        action={
          interactionConfig?.planeForm && (
            <>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Dropdown
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
              >
                <Box>
                  <PlaneForm
                    {...interactionConfig?.planeForm}
                    dirtyValues={dirtyPlanes}
                    setDirtyValues={setDirtyPlanes}
                  />
                </Box>
              </Dropdown>
            </>
          )
        }
        title={
          <Grid container wrap="wrap">
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {heading}
              </Typography>
            </Grid>
            {interactionConfig?.period && (
              <Grid item>
                <PeriodForm
                  {...interactionConfig.period}
                  period={period}
                  setPeriod={setPeriod}
                />
              </Grid>
            )}
          </Grid>
        }
      />
      <AutoSizer {...autoSizerProps}>{children}</AutoSizer>
    </div>
  );
}

export default WidgetContainer;
