import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    card: {
      border: `1px solid ${theme.additionalPalette.border}`,
      borderRadius: '6px',
      minWidth: 140,
      height: '100%',
    },
    metric: {
      overflowWrap: 'break-word',
    },
    description: {
      color: theme.palette.secondary.dark,
    },
  })
);
