import React from 'react';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { useFormatMessage } from '../../../../../locale';
import { ComboBoxOption } from '../../../../../services/Main/types.Field';
import getLocalizedProperties from '../../helpers/getLocalizedProperties';
import createRenderInput from '../../helpers/createRenderInput';
import renderTags from '../../helpers/renderTags';
import { FlatComboBoxProps } from '../../types';
import { handleFilterOptions, renderOption } from './flatHelpers';
import useStyles from './FlatComboBox.styles';

const FlatComboBox = (props: FlatComboBoxProps) => {
  const classes = useStyles();
  const { disabled, multiple, options, value, onChange } = props;
  const formatMessage = useFormatMessage();
  const handleChange = (event: any, changedValue: ComboBoxOption[]) => {
    if (disabled) return;

    let newOptions = changedValue;

    if (!multiple && newOptions && newOptions?.length > 1) {
      newOptions = newOptions?.slice(-1);
    }

    onChange(newOptions);
  };

  return (
    <MuiAutocomplete
      {...getLocalizedProperties(formatMessage)}
      classes={{
        root: clsx(classes.root, { disabled }),
        inputRoot: classes.inputRoot,
        input: classes.input,
      }}
      multiple
      disabled={disabled}
      disableCloseOnSelect={multiple}
      disableClearable={disabled}
      options={options}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(o, v) => o.value === v.value}
      filterOptions={handleFilterOptions}
      onChange={handleChange}
      value={value}
      renderInput={createRenderInput(props)}
      renderTags={renderTags({
        multiline: (props as { multiline?: boolean })?.multiline,
      })}
      renderOption={renderOption}
    />
  );
};

export default FlatComboBox;
