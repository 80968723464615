import { createStyles, darken, makeStyles } from '@material-ui/core';
import { Theme } from '../../../theme';

const CLEAR_QUOTE_BUTTON_WIDTH = 24;

export default makeStyles((theme: Theme) =>
  createStyles({
    ClearQuoteButton: {
      color: theme.additionalPalette.title,
      fontSize: `${theme.typography.pxToRem(16)} !important`,
      cursor: 'pointer',

      '&:hover': {
        color: darken(theme.additionalPalette.title, 0.5),
      },
    },
    AddTimelineElementFormCollapse: {
      marginTop: theme.spacing(2),
      color: '#777',
      cursor: 'pointer',
      userSelect: 'none',
    },
    QuoteContainer: {
      marginBottom: theme.spacing(1),
    },
    ClearQuoteButtonContainer: {
      width: CLEAR_QUOTE_BUTTON_WIDTH,
    },
    TimelineQuoteContainer: {
      width: `calc(100% - ${CLEAR_QUOTE_BUTTON_WIDTH}px)`,
    },
  })
);
