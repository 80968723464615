import React, { useContext } from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { CurrentUserTimelineElement } from '../../../../services/Main/types.Component';
import useSharedStyles from '../../../highLevel/Timeline/Timeline.styles';
import useStyles from './CurrentUserTimelineElement.styles';
import TimelineQuote from '../../TimelineQuote';
import Icon from '../../Icon/Icon';
import FileList from '../../FileList';
import { formatDateFromISOstring } from '../../../../utils/formatDate';
import IconWithTooltip from '../../IconWithTooltip';
import { TimelineContext } from '../../../highLevel/Timeline/TimelineContext';
import { useFormatMessage } from '../../../../locale';

export default ({
  id,
  body,
  createdAt,
  attachments,
  privacy,
  quote,
  isAddedManually,
  author,
}: CurrentUserTimelineElement) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const timelineContext = useContext(TimelineContext);
  const sharedClasses = useSharedStyles();

  const formattedCreatedAt = formatDateFromISOstring(
    createdAt as string,
    'dateTime'
  );

  const handleQuoteClick = () => {
    timelineContext?.setQuote({
      quotedElementId: id,
      body,
      header: author ? `${author.firstName} ${author.lastName}` : undefined,
    });
  };

  return (
    <Grid
      className={clsx(
        sharedClasses.CommonTimelineElement,
        sharedClasses.CommonTimelineElementSpacings,
        sharedClasses.TimelineElementWithBorder,
        classes.CurrentUserTimelineElementContainer
      )}
      container
      data-timeline-element-id={id}
    >
      <Grid
        item
        xs={12}
        md={9}
        className={sharedClasses.CommonTimelineElementBodyContainer}
      >
        {quote && (
          <Box mb={1}>
            <TimelineQuote {...quote} />
          </Box>
        )}
        <div
          className={sharedClasses.CommonTimelineElementBody}
          dangerouslySetInnerHTML={{ __html: body.html }}
        />
        {attachments && <FileList files={attachments} viewSize="small" />}
      </Grid>
      <Grid
        xs={12}
        md={3}
        className={sharedClasses.CommonTimelineElementSidebar}
        container
        item
        direction="column"
        justify="space-between"
      >
        <Grid container justify="flex-end" spacing={2}>
          <Grid
            item
            className={clsx(sharedClasses.CommonTimelineElementCreatedAt)}
          >
            {formattedCreatedAt}
          </Grid>
          {isAddedManually && (
            <Grid item className={sharedClasses.CommonTimelineElementIcon}>
              <IconWithTooltip
                definition="mui:ChatBubbleOutline"
                tooltipText={formatMessage('freeComment')}
              />
            </Grid>
          )}
          {privacy && (
            <Grid item className={sharedClasses.CommonTimelineElementIcon}>
              <IconWithTooltip
                definition="mui:LockOutlined"
                tooltipText={`${formatMessage('visibility')}: ${privacy.label}`}
              />
            </Grid>
          )}
        </Grid>
        <Grid item className={sharedClasses.CommonTimelineElementQuoteButton}>
          <Icon definition="mui:Reply" onClick={handleQuoteClick} />
        </Grid>
      </Grid>
    </Grid>
  );
};
