import React from 'react';
import TreeComboBox from './components/TreeComboBox';
import FlatComboBox from './components/FlatComboBox';
import { ComboBoxProps, FlatComboBoxProps, TreeComboBoxProps } from './types';

export default (props: ComboBoxProps) => {
  switch (props.optionsType) {
    case 'checkboxTree':
    case 'radioTree':
      return <TreeComboBox {...(props as unknown as TreeComboBoxProps)} />;
    case 'flat':
      return <FlatComboBox {...(props as FlatComboBoxProps)} />;
    default:
      throw new Error('Unknown optionsType');
  }
};
