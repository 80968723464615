import { createStyles, makeStyles } from '@material-ui/core';
import { TableRowViewConfig } from 'services/Main/types.Component';

export default (viewConfig: TableRowViewConfig) =>
  makeStyles(() =>
    createStyles({
      row: {
        backgroundColor: viewConfig?.backgroundColor,
        color: viewConfig?.color,
        fontWeight: viewConfig?.bold ? 700 : undefined,
      },
    })
  );
