import React, { useState } from 'react';
import { Pie, PieChart, RechartsFunction, Legend, Tooltip } from 'recharts';
import {
  DonutChartDatum,
  DonutChartProps,
} from '../../../services/Main/types.Component';
import renderActiveShape from './renderActiveShape';
import './DonutChartWidget.styles.css';
import getMinHeight from './getMinHeight';
import { HEADING_HEIGHT } from './constants';
import WidgetContainer from '../../lowLevel/WidgetContainer';
import EmptyWidgetDataText from '../../lowLevel/EmptyWidgetDataText';

interface State {
  data: DonutChartDatum[];
}

const DonutChartWidget = ({
  data: initialData,
  showLegend,
  showTooltip,
  heading,
  interactionConfig,
}: DonutChartProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [data, setData] = useState<DonutChartDatum[]>(initialData);
  const minHeight = getMinHeight(
    300,
    !!heading,
    !!showLegend,
    !!interactionConfig?.period
  );

  const handlePieEnter: RechartsFunction = (datum, index) => {
    setActiveIndex(index);
  };

  const handleStateChange = ({ data: newData }: State) => {
    setData(newData);
  };

  return (
    <WidgetContainer<State>
      style={{ minHeight, height: '100%' }}
      heading={heading}
      autoSizerProps={{ disableHeight: true }}
      interactionConfig={interactionConfig}
      handleStateChange={handleStateChange}
    >
      {({ width }) => {
        const chartHeight = heading ? minHeight - HEADING_HEIGHT : minHeight;

        return data && Array.isArray(data) && data.length > 0 ? (
          <PieChart width={width} height={chartHeight}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={data}
              innerRadius={85}
              outerRadius={100}
              fill="#8884d8"
              dataKey="value"
              onMouseEnter={handlePieEnter}
            />
            {showLegend && (
              <Legend
                wrapperStyle={{ bottom: 15 }}
                onMouseEnter={handlePieEnter}
              />
            )}
            {showTooltip && <Tooltip />}
          </PieChart>
        ) : (
          <EmptyWidgetDataText height={chartHeight} width={width} />
        );
      }}
    </WidgetContainer>
  );
};

export default DonutChartWidget;
