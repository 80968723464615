import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    root: {
      padding: 0,
    },
    selectRoot: {
      fontSize: '0.8rem',
    },
    selectIcon: {
      transform: 'scale(0.8)',
    },
    planeFormRoot: {
      padding: theme.spacing(0, 2),
      width: 280,
    },
  })
);
