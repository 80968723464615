import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import mainService from '../../../services/Main';
import {
  Reaction,
  RedirectReaction,
  TriggerEndpointAction,
  TableAction,
} from '../../../services/Main/types.Component';
import ReactionController, {
  OnReactionExecuteEvent,
} from '../../../responseReactions/ReactionController';
import { PageContext } from '../../creational/PageLoader/PageLoader';
import redirectReaction from '../../../responseReactions/redirect/redirectReaction';
import { DialogAlertContext } from '../DialogAlert/DialogAlert';
import useIsSomeActionExecuting from '../FormAction/useIsSomeActionExecuting';
import history from '../../../utils/history';
import { alertsFromActionServerResponseActions } from '../../../store/alertsFromActionServerResponse';
import { metaActions } from '../../../store/meta';
import { ComponentContext } from '../../creational/ComponentLoader';
import store from '../../../store';
import useEnqueueSnackbar from '../../../utils/hooks/useEnqueueSnackbar';
import FormBuilderContext from '../FormBuilder/FormBuilderContext';

type TableActionPropsOmit = Omit<
  TableAction,
  'label' | 'color' | 'icon' | 'variant'
>;

interface OtherProps {
  onReactionExecute?: OnReactionExecuteEvent;
}

export const useHandleClickAction = ({
  type,
  onReactionExecute,
  ...rest
}: TableActionPropsOmit & OtherProps) => {
  const pageContext = useContext(PageContext);
  const componentContext = useContext(ComponentContext);
  const formBuilderContext = useContext(FormBuilderContext);
  const dispatch = useDispatch();
  const enqueueSnackbar = useEnqueueSnackbar();
  const dialogAlertContext = useContext(DialogAlertContext);
  const { setIsSomeActionExecuting } = useIsSomeActionExecuting();

  switch (type) {
    // showForm обрабатываем выше.
    // case 'showForm':
    //   return () => console.log(type, rest);

    // Получить урл, сделать запрос,
    // выполнить реакцию (колбек)
    case 'triggerEndpoint':
      return () => {
        setIsSomeActionExecuting(true);
        mainService
          .makeActionRequest((rest as TriggerEndpointAction).requestConfig)
          .then(
            ({
              payload,
              snackbar,
              dialogAlert,
              preventSuccessResponseReactionReasons,
            }) => {
              if (snackbar) enqueueSnackbar(snackbar.text, snackbar.options);
              if (dialogAlert) dialogAlertContext.setDialogState(dialogAlert);

              const alertsId = `${componentContext.id}_${formBuilderContext.id}`;

              if (
                preventSuccessResponseReactionReasons &&
                Array.isArray(preventSuccessResponseReactionReasons)
              ) {
                dispatch(
                  alertsFromActionServerResponseActions.setForPage({
                    id: alertsId,
                    alerts: preventSuccessResponseReactionReasons,
                  })
                );
                dispatch(metaActions.setIsSomeActionExecuting(false));
                return;
              }

              if (
                store.getState().alertsFromActionServerResponse[alertsId]
                  ?.length > 0
              ) {
                dispatch(alertsFromActionServerResponseActions.clear(alertsId));
              }

              new ReactionController(
                (rest as TriggerEndpointAction).successResponseReaction,
                pageContext as PageContext,
                enqueueSnackbar,
                payload
              ).execute(onReactionExecute);
            }
          )
          .catch((error) => {
            if (error.response.status === 422) {
              if (error.response?.data?.snackbar) {
                enqueueSnackbar(
                  error.response?.data?.snackbar.text,
                  error.response?.data?.snackbar.options
                );
              }

              if (error.response?.data?.dialogAlert) {
                if (error.response?.data?.dialogAlert)
                  dialogAlertContext.setDialogState(
                    error.response.data.dialogAlert
                  );
              }

              if ((rest as TriggerEndpointAction).validationErrorReaction) {
                new ReactionController(
                  (rest as TriggerEndpointAction)
                    .validationErrorReaction as Reaction,
                  pageContext as PageContext,
                  enqueueSnackbar,
                  error.response
                ).execute(onReactionExecute);
              }
            }

            console.log('Ошибка', error);
          });
      };

    case 'redirect':
      return () => {
        redirectReaction(rest as RedirectReaction);
      };

    case 'goBack':
      return () => {
        history.goBack();
      };

    default:
      return () => console.log('CLICK CLOCK');
  }
};
