import { createContext, Dispatch, SetStateAction } from 'react';
import { TableComponentProps } from '../../../services/Main/types.Component';

type AdditionalProps = {
  resetColumnWidthsToDefault: () => void;
  resetColumnOrderToDefault: () => void;
  quickFiltersState: string[];
  setQuickFilters: Dispatch<SetStateAction<string[]>>;
  withClosed: boolean;
  setWithClosed: Dispatch<SetStateAction<boolean>>;
  setHiddenColumnNames: Dispatch<SetStateAction<string[]>>;
  hiddenColumnNames: string[];
  resetColumnWidthToDefault: () => void;
};

export type TableContextValue = (TableComponentProps & AdditionalProps) | null;
export const TableContext = createContext<TableContextValue>(null);
