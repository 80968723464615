import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import isEqual from 'lodash.isequal';
import {
  DirtyFormValues,
  FormValues,
  PlaneFormComponent,
} from '../../../services/Main/types.Component';
import theme from '../../../theme';
import FormBuilder from '../FormBuilder';
import useStyles from './WidgetContainer.styles';

type PlaneFormProps = PlaneFormComponent & {
  dirtyValues: DirtyFormValues | undefined;
  setDirtyValues: Dispatch<SetStateAction<DirtyFormValues | undefined>>;
};

const PlaneForm = ({
  id,
  props,
  dirtyValues,
  setDirtyValues,
}: PlaneFormProps) => {
  const classes = useStyles();
  const { fieldGroups } = props;
  const [values, setValues] = useState<FormValues | undefined>();

  const handleChange = useCallback(
    (newValues: any, dirty: any) => {
      const haveSomeValue = Object.values(newValues).some(
        (v) => !!v || v === 0
      );
      const oldValuesLength = values
        ? Object.values(values).filter((v) => !!v || v === 0).length
        : 0;
      const newValuesLength = Object.values(newValues).filter(
        (v) => !!v || v === 0
      ).length;

      const isInserting = !isEqual(values, newValues) && haveSomeValue;

      const isDeletingLastFilter =
        !isEqual(values, values) &&
        oldValuesLength > 0 &&
        newValuesLength === 0;

      // Проверка на то, что фильтр изменился реально.
      // Нужно, т.к. RHF watch метод срабатывает
      // чаще, чем нужно (при focusout, к примеру).
      if (isInserting || isDeletingLastFilter) {
        setValues(newValues);
        setDirtyValues(dirty);
      }
    },
    [values, setValues, setDirtyValues]
  );

  return (
    <div className={classes.planeFormRoot}>
      <FormBuilder
        id={id}
        fieldGroups={fieldGroups}
        padding={theme.spacing(0, 0, 2, 0)}
        disableBorder
        values={dirtyValues}
        onChange={handleChange}
      />
    </div>
  );
};

export default PlaneForm;
