import React, { useState } from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { KeyboardDatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { formatISO } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import get from 'lodash.get';
import { SimpleDatePicker } from 'services/Main/types.Field';
import { DATE_FORMAT } from 'commonConstants';
import isValidDate from 'utils/isValidDate';
import { formatDate } from 'utils/formatDate';
import { getDate } from '../../helpers';
import { useFormatMessage } from '../../../../../locale';
import useStyles from '../../DatePicker.styles';

type DateProps = SimpleDatePicker & {
  value?: any;
  onChange?: any;
};

export default ({
  disabled,
  value,
  onChange,
  initialFocusedDate,
  label,
  minDate,
  maxDate,
  helperText,
  name,
  autoComplete,
}: DateProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const { errors } = useFormContext();
  const [internalError, setInternalError] = useState<string | null>(null);
  const error = get(errors, name);

  const handleChange = (date: MaterialUiPickersDate) => {
    if (isValidDate(date) || date === null) {
      setInternalError(null);
      onChange(date ? formatISO(date) : null);
    } else {
      setInternalError(
        formatMessage('enterTheCorrectDateFormat', {
          date: formatDate(new Date()),
        })
      );
    }
  };

  return (
    <KeyboardDatePicker
      autoOk
      clearable
      fullWidth
      showTodayButton
      label={label}
      minDate={minDate}
      maxDate={maxDate}
      name={name}
      value={getDate(value)}
      KeyboardButtonProps={{
        className: clsx(classes.keyboardButton, { disabled }),
      }}
      helperText={error?.message || internalError || helperText}
      error={!!error}
      onChange={handleChange}
      initialFocusedDate={getDate(initialFocusedDate, new Date())}
      format={DATE_FORMAT}
      disabled={disabled}
      inputProps={{
        autoComplete: autoComplete || 'off',
      }}
      clearLabel={formatMessage('clear')}
      todayLabel={formatMessage('today')}
      cancelLabel={formatMessage('cancel')}
    />
  );
};
