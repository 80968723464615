import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '../../../../../theme';

export default makeStyles<Theme>((theme) =>
  createStyles({
    /* Styles applied to the root element. */
    root: {
      '&$focused $clearIndicatorDirty': {
        visibility: 'visible',
      },
      /* Avoid double tap issue on iOS */
      '@media (pointer: fine)': {
        '&:hover $clearIndicatorDirty': {
          visibility: 'visible',
        },
      },
    },
    /* Styles applied to the root element if `fullWidth={true}`. */
    fullWidth: {
      width: '100%',
    },
    /* Pseudo-class applied to the root element if focused. */
    focused: {},
    /* Styles applied when the popup icon is rendered. */
    hasPopupIcon: {},
    /* Styles applied when the clear icon is rendered. */
    hasClearIcon: {},
    /* Styles applied to the Input element. */
    inputRoot: {
      // flexWrap: 'wrap',
      '$hasPopupIcon &, $hasClearIcon &': {
        paddingRight: 26 + 4,
      },
      '$hasPopupIcon$hasClearIcon &': {
        paddingRight: 52 + 4,
      },
      '& $input': {
        width: 0,
        minWidth: 30,
      },
      '&[class*="MuiInput-root"]': {
        paddingTop: 5,
        paddingBottom: 6,
        '& $input': {
          padding: '1px 2px',
        },
      },
      '&[class*="MuiInput-root"][class*="MuiInput-marginDense"]': {
        '& $input': {
          padding: '4px 4px 5px',
        },
        '& $input:first-child': {
          padding: '3px 0 6px',
        },
      },
      '&[class*="MuiOutlinedInput-root"]': {
        padding: 9,
        '$hasPopupIcon &, $hasClearIcon &': {
          paddingRight: 26 + 4 + 9,
        },
        '$hasPopupIcon$hasClearIcon &': {
          paddingRight: 52 + 4 + 9,
        },
        '& $input': {
          padding: '9.5px 4px',
        },
        '& $input:first-child': {
          paddingLeft: 6,
        },
        '& $endAdornment': {
          right: 9,
        },
      },
      '&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]':
        {
          padding: 6,
          '& $input': {
            padding: '4.5px 4px',
          },
        },
      '&[class*="MuiFilledInput-root"]': {
        paddingTop: 19,
        paddingLeft: 8,
        '$hasPopupIcon &, $hasClearIcon &': {
          paddingRight: 26 + 4 + 9,
        },
        '$hasPopupIcon$hasClearIcon &': {
          paddingRight: 52 + 4 + 9,
        },
        '& $input': {
          padding: '9px 4px',
        },
        '& $endAdornment': {
          right: 9,
        },
      },
      '&[class*="MuiFilledInput-root"][class*="MuiFilledInput-marginDense"]': {
        paddingBottom: 1,
        '& $input': {
          padding: '4.5px 4px',
        },
      },
    },
    /* Styles applied to the input element. */
    input: {
      flexGrow: 1,
      textOverflow: 'ellipsis',
      opacity: 0,
    },
    /* Styles applied to the input element if tag focused. */
    inputFocused: {
      opacity: 1,
    },
    /* Styles applied to the endAdornment element. */
    endAdornment: {
      // We use a position absolute to support wrapping tags.
      position: 'absolute',
      right: 0,
      top: 'calc(50% - 14px)', // Center vertically
    },
    /* Styles applied to the clear indicator. */
    clearIndicator: {
      marginRight: -2,
      padding: 4,
      visibility: 'hidden',
    },
    /* Styles applied to the clear indicator if the input is dirty. */
    clearIndicatorDirty: {},
    /* Styles applied to the popup indicator. */
    popupIndicator: {
      padding: 2,
      marginRight: -2,
    },
    /* Styles applied to the popup indicator if the popup is open. */
    popupIndicatorOpen: {
      transform: 'rotate(180deg)',
    },
    /* Styles applied to the popper element. */
    popper: {
      zIndex: theme.zIndex.modal,

      '& .react-checkbox-tree': {
        flexDirection: 'column',
      },
      '& .react-checkbox-tree .rct-options': {
        textAlign: 'left',
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(0.5),
      },
      '& .react-checkbox-tree .rct-options button': {
        ...theme.typography.body2,
        color: theme.palette.primary.main,
        opacity: 1,
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      '& .react-checkbox-tree .rct-text': {
        paddingLeft: 6,
      },
      '& .react-checkbox-tree .rct-bare-label, .react-checkbox-tree label': {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        padding: '6px 0',
      },
      '& .react-checkbox-tree label': {
        '&:before': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          height: '100%',
          background: 'transparent',
          transform: 'translateX(-100%)',
          zIndex: 1,
        },
      },
      '& .react-checkbox-tree .rct-node .highlighted': {
        fontWeight: 500,
        background: 'rgba(255, 196, 0, 0.3)',
        padding: '0 1px',
      },
      '& .react-checkbox-tree .rct-node.hidden': {
        display: 'none',
      },
      '& .react-checkbox-tree .rct-node:not(.rct-disabled)  label:hover': {
        background: 'rgba(0, 0, 0, 0.04)',
        position: 'relative',

        '&:before': {
          background: 'rgba(0, 0, 0, 0.04)',
          width: '100%',
        },
      },
      '& .react-checkbox-tree .rct-node:not(.rct-disabled)  label:active, .react-checkbox-tree .rct-node:not(.rct-disabled)  label:focus':
        {
          background: 'rgba(0, 0, 0, 0.08)',

          '&:before': {
            background: 'rgba(0, 0, 0, 0.08) !important',
            width: '100%',
          },
        },
      '& .react-checkbox-tree .rct-disabled > .rct-text > label': {
        opacity: 0.38,
      },
      '& .react-checkbox-tree .rct-checkbox': {
        padding: 0,
        display: 'inline-flex',
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
      '& .react-checkbox-tree .rct-collapse-btn': {
        zIndex: 10,
      },
      '& .react-checkbox-tree .rct-collapse-btn, .rct-checkbox': {
        color: theme.palette.action.active,
      },
      '& .react-checkbox-tree .rct-collapse-btn > *': {
        fontSize: '1rem',
      },
      '& .react-checkbox-tree .rct-collapse *, .rct-checkbox *, .rct-node-icon *':
        {
          width: 20,
        },
      '& .rct-title': {
        whiteSpace: 'nowrap',
      },
    },
    /* Styles applied to the `Paper` component. */
    paper: {
      ...theme.typography.body1,
      overflow: 'auto',
      margin: '4px 0',
      padding: theme.spacing(1, 0),
      maxHeight: '40vh',
    },
    /* Styles applied to the no option wrapper. */
    noOptions: {
      color: theme.palette.text.secondary,
      padding: '14px 16px',
    },
  })
);
