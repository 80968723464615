import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { TimelineComponentProps } from '../../../services/Main/types.Component';
import Timeline from './Timeline';
import { TimelineContext } from './TimelineContext';
import useScrollManager from './useScrollManager';
import useTimeline from './useTimeline';
import useAddTimelineElementForm from './useAddTimelineElementForm';

const TimelineContainer = (props: TimelineComponentProps) => {
  const {
    quote,
    setQuote,
    isAddTimelineElementFormOpened,
    setIsAddTimelineElementFormOpened,
  } = useAddTimelineElementForm(props.addCommentUrl);
  const { timelineElements, isTimelineInitialized } = useTimeline(
    props.timelineSocketConfig
  );
  const {
    timelineElementsContainer,
    timelineScrollManager,
  } = useScrollManager();

  if (!isTimelineInitialized) return <LinearProgress />;

  return (
    <TimelineContext.Provider
      value={{
        quote,
        setQuote,
        isAddTimelineElementFormOpened,
        setIsAddTimelineElementFormOpened,
        timelineScrollManager,
        timelineElements,
      }}
    >
      <Timeline
        ref={timelineElementsContainer}
        timelineElements={timelineElements}
        {...props}
      />
    </TimelineContext.Provider>
  );
};

export default TimelineContainer;
