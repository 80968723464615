import React, { useState } from 'react';
import { Dialog, DialogContent, IconButton, Tooltip } from '@material-ui/core';
import {
  FormHistoryConfig,
  FormHistoryElement,
  Header as HeaderProps,
} from '../../../services/Main/types.Component';
import Icon from '../Icon';
import formHistoryService from '../../../services/FormHistory';
import Header from '../Header';
import DialogTitle from '../DialogTitle';
import FormHistoryElementComponent from '../FormHistoryElement';
import useStyles from './FormHistory.styles';
import { useFormatMessage } from '../../../locale';
import useIsSomeActionExecuting from '../FormAction/useIsSomeActionExecuting';

interface FormHistoryProps {
  config: FormHistoryConfig;
}

const FormHistory = ({ config }: FormHistoryProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const [header, setHeader] = useState<HeaderProps | null>(null);
  const [history, setHistory] = useState<FormHistoryElement[] | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { isSomeActionExecuting } = useIsSomeActionExecuting();

  const loadHistory = () => {
    formHistoryService
      .fetchFormHistory(config.requestConfig)
      .then(({ payload }) => {
        const sortedElements = [...payload.elements]
          .sort((a, b) => a.sort - b.sort)
          .reverse();
        setHeader(payload.header);
        setHistory(sortedElements);
      });
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Tooltip title={formatMessage('history')}>
        <IconButton disabled={isSomeActionExecuting} onClick={loadHistory}>
          <Icon definition="mui:History" />
        </IconButton>
      </Tooltip>
      <Dialog fullWidth maxWidth="xl" open={isOpen} onClose={handleClose}>
        {header && (
          <DialogTitle onClose={handleClose}>
            <Header {...header} />
          </DialogTitle>
        )}
        <DialogContent className={classes.dialogContentRoot}>
          {history &&
            history.map((element) => (
              <FormHistoryElementComponent key={element.id} {...element} />
            ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FormHistory;
