import { Field } from '../../../services/Main/types.Field';
import { DirtyFormValues } from '../../../services/Main/types.Component';

export default function getInitialPlanes(fields: Field[]): DirtyFormValues {
  return fields.reduce((acc, { defaultValue, name }) => {
    if (defaultValue) return { ...acc, [name]: defaultValue };

    return acc;
  }, {});
}
