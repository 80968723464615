import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      minWidth: '90%',

      [theme.breakpoints.up('sm')]: {
        minWidth: 400,
      },

      [theme.breakpoints.up('md')]: {
        minWidth: 600,
      },
    },
  })
);
