import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { UseFormMethods, useForm, FormProvider } from 'react-hook-form';
import {
  DirtyFormValues,
  FieldGroup,
  FormValues,
} from 'services/Main/types.Component';
import { Field } from 'services/Main/types.Field';
import FieldGroupComponent from '../FieldGroup';
import FieldComponent from '../Field';
import { getInitialValues, mapValuesToPlain } from './helpers';
import useYupSchema from './useYupSchema';
import FormBuilderContext, { FormSubmitHandlers } from './FormBuilderContext';
import GridBuilder from '../GridBuilder';
import { yupResolver } from './yupResolver';

export interface InversePropsBag
  extends Pick<UseFormMethods<DirtyFormValues>, 'reset'> {
  defaultValues: DirtyFormValues;
}

export interface FormBuilderProps {
  id: number | string;
  fieldGroups: FieldGroup[];
  disableBorder?: boolean;
  globalDisabled?: boolean;
  header?: (props: InversePropsBag) => ReactElement;
  footer?: (props: InversePropsBag) => ReactElement;
  padding?: string;
  values?: DirtyFormValues;
  onChange?: (values: FormValues, dirtyValues: DirtyFormValues) => void;
}
const FormBuilder = ({
  id,
  fieldGroups,
  disableBorder,
  globalDisabled,
  header,
  footer,
  padding,
  values,
  onChange,
}: FormBuilderProps) => {
  const defaultValues = getInitialValues(fieldGroups);
  const schema = useYupSchema(fieldGroups);
  const formMethods = useForm<DirtyFormValues>({
    defaultValues,
    resolver: yupResolver(schema),
    // если поле исчезает из DOM-дерева, то оно автоматически unregister из формы
    shouldUnregister: true,
  });

  const fields: Field[] = useMemo(
    () =>
      fieldGroups.reduce(
        (acc, group) => [...acc, ...group.fields],
        [] as Field[]
      ),
    [fieldGroups]
  );
  const fieldsByName = useMemo(
    () => fields.reduce((acc, field) => ({ ...acc, [field.name]: field }), {}),
    [fields]
  );

  const { control, watch, handleSubmit, reset, getValues, setValue } =
    formMethods;
  const [onSubmitHandlers, setOnSubmitHandlers] =
    useState<FormSubmitHandlers>(null);
  const [firedSubmitAction, setFiredSubmitAction] = useState<string | null>(
    null
  );

  // Controlled FormBuilder
  useEffect(() => {
    if (values) {
      Object.keys(getValues()).forEach((fieldName) => {
        setValue(
          fieldName,
          values[fieldName] ? values[fieldName] : defaultValues[fieldName]
        );
      });
    }
    // eslint-disable-next-line
  }, [values, setValue, getValues]);

  useEffect(() => {
    if (onChange) {
      const dirtyValues = watch();

      onChange(mapValuesToPlain(dirtyValues, fields), dirtyValues);
    }
  });

  return (
    <FormBuilderContext.Provider
      value={{
        id,
        onSubmitHandlers,
        setOnSubmitHandlers,
        firedSubmitAction,
        setFiredSubmitAction,
        fields,
        fieldsByName,
      }}
    >
      <FormProvider {...formMethods}>
        <form
          onSubmit={(e) => {
            // Останавливает всплытие, чтобы предотвратить триггер
            // валидации родительской формы, в случае вложенности.
            e.preventDefault();
            e.stopPropagation();

            handleSubmit((dirtyValues) => {
              onSubmitHandlers &&
                firedSubmitAction &&
                onSubmitHandlers[firedSubmitAction] &&
                onSubmitHandlers[firedSubmitAction](dirtyValues);
            })(e);
          }}
        >
          {header && header({ reset, defaultValues })}
          {fieldGroups.map(
            (
              {
                label,
                header: formGroupHeader,
                fields: groupFields,
                accordion,
              },
              groupIndex
            ) => (
              <FieldGroupComponent
                key={`group_${label}_${groupIndex}`}
                label={label}
                header={formGroupHeader}
                padding={padding}
                disableBorder={disableBorder}
                accordion={accordion}
              >
                <GridBuilder<Field>
                  markup={groupFields}
                  renderGridItem={(fieldProps) => (
                    <FieldComponent
                      control={control}
                      globalDisabled={globalDisabled}
                      {...fieldProps}
                      // Перезаписываем оригинальное значение поля на пропущенное
                      // через getInitialValues.
                      defaultValue={defaultValues[fieldProps.name]}
                    />
                  )}
                />
              </FieldGroupComponent>
            )
          )}
          {footer && footer({ reset, defaultValues })}
        </form>
      </FormProvider>
    </FormBuilderContext.Provider>
  );
};

export default FormBuilder;
