import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
    },
  })
);

let RemovedPage: any = (props: any) => (
  <SvgIcon
    className={useStyles().root}
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M134.029 101.938C135.447 101.295 137.508 99.6261 137.974 99.1283C136.969 98.7099 135.887 98.0714 134.84 97.2023C133.793 96.3331 131.162 91.8596 122.148 92.6959C112.626 93.5791 109.069 100.559 109.545 105.687C109.613 106.42 109.681 107.153 109.749 107.885C97.8412 114.901 87.4665 122.513 76.4165 138.771L108.361 135.808C106.739 136.697 103.898 137.927 102.109 140.082L105.65 148.848C110.249 148.194 114.789 146.295 117.604 144.783C120.193 143.293 131.641 136.831 134.859 121.3C135.231 119.788 136.194 111.798 136.31 110.593C136.42 109.333 136.907 106.616 138.942 104.665C138.162 104.226 136.336 102.918 134.276 102.143C133.605 102.262 133.605 102.262 134.029 101.938Z"
      fill="#D24A48"
    />
    <path
      d="M123.833 97.996C121.129 98.2469 119.136 100.648 119.386 103.353C119.637 106.058 122.038 108.052 124.743 107.801C127.447 107.551 129.441 105.149 129.19 102.444C128.939 99.7391 126.537 97.7451 123.833 97.996Z"
      fill="#4DA162"
    />
    <path
      d="M110.285 147.963L119.209 170.041L126.307 169.383L116.699 145.436C115.02 146.33 112.665 147.288 110.285 147.963Z"
      fill="#4DA162"
    />
    <path
      d="M149.671 100.26C147.913 99.6839 144.894 98.9977 140.95 99.3635C139.711 99.4785 138.753 99.5673 138.199 99.1072C137.514 99.6823 135.651 101.049 134.255 101.917C133.543 102.211 133.569 102.492 134.323 102.65C136.383 103.425 138.188 104.508 138.989 105.172C141.51 102.949 144.095 104.471 149.929 101.202C150.45 100.699 150.424 100.417 149.671 100.26Z"
      fill="#4DA162"
    />
    <path
      d="M48.0086 134.529C48.2339 134.508 48.2339 134.508 48.2548 134.734C48.2757 134.959 48.2757 134.959 48.0504 134.98C43.8812 135.367 41.2234 138.569 41.3169 142.027L64.7543 139.853C63.2209 141.7 61.5 144.588 60.5114 147.408L96.1182 144.105L106.983 171.175L114.081 170.517L105.451 149.151L101.91 140.385C103.924 138.209 106.54 137 108.162 136.11L76.9498 139.006C87.7693 122.713 98.3745 115.136 110.282 108.12C109.989 107.408 110.146 106.655 110.078 105.922C109.602 100.794 113.16 93.8138 122.681 92.9305C125.836 92.6378 129.341 93.0516 132.202 94.4913C130.545 93.1673 125.146 90.7125 120.019 91.1881C109.258 92.1864 103.581 99.5905 104.737 107.156C98.213 110.945 87.0642 118.174 80.0792 124.961L40.078 128.672C40.8894 132.518 44.0648 134.895 48.0086 134.529Z"
      fill="#4DA162"
    />
    <path
      d="M124.104 103.37L128.52 100.744C127.898 99.5513 126.605 98.4776 125.318 98.0854L123.55 102.91C123.371 103.438 123.618 103.643 124.104 103.37Z"
      fill="white"
    />
    <path
      d="M58.6138 111.197L28.7298 116.714L25.5388 99.427C24.4818 93.699 28.2678 88.197 33.9968 87.14L43.1368 85.453C48.8648 84.396 54.3668 88.182 55.4238 93.911L58.6138 111.197Z"
      fill="#464D94"
    />
    <path
      d="M266.892 131.836L238.377 121.33L244.455 104.835C246.469 99.369 252.533 96.571 257.999 98.584L266.72 101.797C272.186 103.811 274.984 109.875 272.971 115.341L266.892 131.836Z"
      fill="#464D94"
    />
    <path
      d="M73.9888 241.941L67.1938 174.682L127.433 169.304C135.536 168.485 142.769 174.391 143.588 182.494L147.419 220.408C148.238 228.511 142.332 235.744 134.229 236.563L73.9888 241.941Z"
      fill="#0A104B"
    />
    <path
      d="M176.221 217.787L172.333 179.303C171.53 171.357 164.438 165.567 156.492 166.37C148.546 167.173 142.756 174.265 143.559 182.211L147.447 220.695C148.25 228.641 155.342 234.431 163.288 233.628C171.233 232.825 177.023 225.733 176.221 217.787Z"
      fill="#6C72AF"
    />
    <path
      d="M204.995 214.88L201.107 176.396C200.304 168.45 193.212 162.66 185.266 163.463C177.32 164.266 171.53 171.358 172.333 179.304L176.221 217.788C177.024 225.734 184.116 231.524 192.062 230.721C200.007 229.918 205.798 222.826 204.995 214.88Z"
      fill="#0A104B"
    />
    <path
      d="M99.0119 225.551L32.5339 237.823L23.5729 189.279C22.0939 181.27 27.3889 173.579 35.3979 172.1L72.8719 165.182C80.8809 163.703 88.5719 168.998 90.0509 177.007L99.0119 225.551Z"
      fill="#6C72AF"
    />
    <path
      d="M67.9042 136.69L29.8662 143.712C22.0122 145.162 16.8212 152.704 18.2712 160.557C19.7212 168.411 27.2632 173.602 35.1162 172.152L73.1542 165.13C81.0082 163.68 86.1992 156.138 84.7492 148.285C83.2992 140.431 75.7572 135.24 67.9042 136.69Z"
      fill="#0A104B"
    />
    <path
      d="M62.6542 108.25L24.6162 115.272C16.7622 116.722 11.5712 124.264 13.0212 132.117C14.4712 139.971 22.0132 145.162 29.8662 143.712L67.9042 136.69C75.7582 135.24 80.9492 127.698 79.4992 119.845C78.0492 111.991 70.5072 106.8 62.6542 108.25V108.25Z"
      fill="#6C72AF"
    />
    <path
      d="M242.731 250.858L179.299 227.486L196.366 181.166C199.182 173.524 207.66 169.611 215.302 172.427L251.059 185.602C258.701 188.418 262.614 196.896 259.798 204.538L242.731 250.858Z"
      fill="#6C72AF"
    />
    <path
      d="M261.326 158.563L225.031 145.19C217.537 142.429 209.224 146.266 206.463 153.759C203.702 161.253 207.539 169.566 215.032 172.327L251.327 185.7C258.821 188.461 267.134 184.624 269.895 177.131C272.657 169.637 268.82 161.324 261.326 158.563V158.563Z"
      fill="#0A104B"
    />
    <path
      d="M271.325 131.426L235.03 118.053C227.536 115.292 219.223 119.129 216.462 126.622C213.701 134.116 217.538 142.429 225.031 145.19L261.326 158.563C268.82 161.324 277.133 157.487 279.894 149.994C282.655 142.5 278.819 134.187 271.325 131.426V131.426Z"
      fill="#6C72AF"
    />
    <path
      d="M447.785 279.206L435.505 242.052C432.745 233.701 426.512 226.951 418.408 223.534L395.641 213.937C390.425 211.738 388.065 205.6 390.561 200.519C392.018 197.552 393.892 194.71 395.951 192.065C398.437 188.871 394.809 184.523 391.192 186.341L389.319 187.282C386.815 188.54 383.811 186.994 383.381 184.224L382.596 179.171C382.017 175.449 376.966 174.73 375.372 178.143L372.574 184.135C371.734 185.934 369.734 186.881 367.81 186.391L358.961 184.138C355.317 183.21 352.653 187.576 355.173 190.366C358.94 194.535 362.121 199.023 364.126 203.578C366.205 208.3 364.348 213.827 359.819 216.299L332.76 231.069C327.238 234.083 322.784 238.733 320.01 244.379L305.835 273.23C302.074 280.884 301.693 289.763 304.783 297.711L314.871 323.656C318.128 332.032 324.915 338.543 333.419 341.449L359.809 350.467C366.635 352.799 373.925 353.418 381.045 352.266C389.171 350.951 400.407 349.134 409.482 347.666C419.208 346.093 427.569 339.915 431.933 331.082L446.171 302.264C449.717 295.09 450.297 286.807 447.785 279.206V279.206Z"
      fill="#464D94"
    />
    <path
      d="M356.685 217.032C352.589 217.032 349.175 213.815 348.974 209.681C348.767 205.418 352.054 201.796 356.316 201.588L398.331 199.546C402.638 199.341 406.216 202.627 406.424 206.888C406.631 211.151 403.343 214.773 399.082 214.981L357.067 217.023C356.938 217.028 356.811 217.032 356.685 217.032Z"
      fill="#2D3581"
    />
    <path
      d="M511.653 426.08L504.924 375.48C503.411 364.107 497.059 353.943 487.5 347.599L460.646 329.777C454.493 325.694 452.957 317.254 457.388 311.347C459.976 307.898 463.067 304.709 466.347 301.818C470.309 298.327 466.712 291.875 461.635 293.327L459.006 294.079C455.491 295.085 452.016 292.374 452.136 288.72L452.355 282.053C452.516 277.142 446.216 274.996 443.345 278.984L438.306 285.986C436.793 288.088 434 288.817 431.653 287.723L420.857 282.689C416.412 280.616 411.937 285.566 414.492 289.753C418.309 296.01 421.298 302.534 422.763 308.859C424.283 315.416 420.562 322.05 414.157 324.12L393.966 330.647L397.277 361.522L405.273 444.711L394.896 486.901L392.69 500.885C397.759 502.483 403.037 503.399 408.391 503.542C419.126 503.828 433.968 504.223 445.955 504.542C458.802 504.884 471.017 498.994 478.753 488.731L503.991 455.246C510.278 446.908 513.029 436.431 511.653 426.08V426.08Z"
      fill="#4752B7"
    />
    <path
      d="M425.118 399.179L407.253 351.361C404.315 343.498 399.754 336.463 393.967 330.647L375.894 336.489C368.085 339.013 361.249 343.892 356.324 350.457L331.158 383.999C324.482 392.897 321.839 404.186 323.873 415.123L330.511 450.824C332.654 462.35 339.775 472.341 349.97 478.128L381.609 496.087C385.134 498.088 388.855 499.677 392.691 500.886C398.525 495.561 403.303 489.049 406.609 481.658L423.728 443.379C430.018 429.311 430.512 413.614 425.118 399.179V399.179Z"
      fill="#2D3581"
    />
    <path
      d="M466.204 329.692C465.849 329.692 465.49 329.668 465.128 329.618L410.78 322.047C406.554 321.457 403.605 317.554 404.193 313.328C404.783 309.102 408.684 306.149 412.912 306.741L467.26 314.312C471.486 314.902 474.435 318.805 473.847 323.031C473.308 326.896 469.998 329.692 466.204 329.692Z"
      fill="#2D3581"
    />
    <path
      d="M407.748 405.669L389.882 357.852C385.867 347.105 377.404 338.617 366.669 334.569L336.512 323.198C329.603 320.593 326.219 312.71 329.219 305.962C330.97 302.022 333.271 298.223 335.822 294.672C338.903 290.384 333.956 284.898 329.332 287.449L326.938 288.769C323.737 290.535 319.744 288.669 319.044 285.081L317.767 278.534C316.827 273.711 310.206 273.027 308.299 277.556L304.952 285.507C303.947 287.894 301.387 289.228 298.855 288.686L287.208 286.192C282.412 285.165 279.157 290.99 282.582 294.5C287.701 299.746 292.072 305.438 294.914 311.275C297.86 317.327 295.716 324.624 289.936 328.073L266.49 342.064L251.654 375.643L241.617 443.692L239.916 469.04L241.852 478.889C246.875 485.328 253.805 490.142 261.784 492.531L296.636 502.966C305.65 505.665 315.184 506.106 324.408 504.248C334.935 502.128 349.491 499.196 361.246 496.829C373.845 494.292 384.435 485.821 389.682 474.089L406.8 435.811C411.062 426.278 411.403 415.451 407.748 405.669V405.669Z"
      fill="#4752B7"
    />
    <path
      d="M241.852 478.889L266.49 342.064L255.403 348.68C248.356 352.885 242.782 359.169 239.449 366.668L222.415 404.985C217.896 415.15 217.843 426.744 222.269 436.95L236.717 470.265C238.067 473.378 239.805 476.265 241.852 478.889V478.889Z"
      fill="#2D3581"
    />
    <path
      d="M285.5 326.834C281.536 326.834 278.162 323.802 277.812 319.779C277.442 315.528 280.588 311.781 284.838 311.411L339.504 306.647C343.749 306.274 347.503 309.422 347.872 313.673C348.242 317.924 345.096 321.671 340.846 322.041L286.18 326.805C285.951 326.825 285.725 326.834 285.5 326.834Z"
      fill="#2D3581"
    />
    <path
      d="M278.8 254.109H6.539C2.928 254.109 0 251.181 0 247.57V224.341C0 220.73 2.928 217.802 6.539 217.802H278.8C282.411 217.802 285.339 220.73 285.339 224.341V247.57C285.339 251.182 282.411 254.109 278.8 254.109V254.109Z"
      fill="#464D94"
    />
    <path
      d="M278.8 217.802H258.073C261.684 217.802 264.612 220.73 264.612 224.341V247.57C264.612 251.181 261.684 254.109 258.073 254.109H278.8C282.411 254.109 285.339 251.181 285.339 247.57V224.341C285.339 220.73 282.411 217.802 278.8 217.802V217.802Z"
      fill="#2D3581"
    />
    <path
      d="M258.639 254.109H21.5488L63.4948 487.051C65.4048 497.658 74.6358 505.375 85.4138 505.375H194.775C205.553 505.375 214.784 497.658 216.694 487.051L258.639 254.109Z"
      fill="#464D94"
    />
    <path
      d="M21.5488 254.109L24.8178 272.263H218.348C229.094 272.263 237.222 281.986 235.318 292.562L200.296 487.052C198.386 497.659 189.155 505.376 178.377 505.376H199.926C210.704 505.376 219.935 497.659 221.845 487.052L263.791 254.11H21.5488V254.109Z"
      fill="#1B2268"
    />
    <path
      d="M161.099 422.103H124.239C118.633 422.103 114.089 417.558 114.089 411.953V347.533C114.089 341.927 118.634 337.383 124.239 337.383H161.099C166.705 337.383 171.249 341.928 171.249 347.533V411.953C171.25 417.559 166.705 422.103 161.099 422.103Z"
      fill="#9EB0E9"
    />
    <path
      d="M161.099 337.382H140.684C146.29 337.382 150.834 341.927 150.834 347.533V411.953C150.834 417.559 146.289 422.103 140.684 422.103H161.099C166.705 422.103 171.25 417.559 171.25 411.953V347.533C171.25 341.927 166.705 337.382 161.099 337.382Z"
      fill="#8296E1"
    />
    <path
      d="M465.642 53.458C459.006 54.095 452.982 49.559 451.759 43.006L449.545 31.14C448.158 23.708 453.444 16.687 460.969 15.966L463.101 15.761C470.626 15.039 477.151 20.927 477.202 28.487L477.284 40.557C477.33 47.223 472.278 52.821 465.642 53.458Z"
      fill="#464D94"
    />
    <path
      d="M470.103 57.755C469.714 64.41 474.471 70.261 481.065 71.239L493.005 73.01C500.483 74.119 507.302 68.575 507.743 61.028L507.868 58.889C508.309 51.342 502.182 45.042 494.626 45.272L482.561 45.64C475.897 45.843 470.492 51.1 470.103 57.755V57.755Z"
      fill="#464D94"
    />
    <path
      d="M459.805 71.706C457.876 71.706 455.945 70.988 454.446 69.544C451.373 66.583 451.281 61.692 454.242 58.619L483.042 28.726C486.002 25.654 490.892 25.56 493.967 28.522C497.04 31.483 497.132 36.374 494.171 39.447L465.371 69.34C463.853 70.914 461.831 71.706 459.805 71.706V71.706Z"
      fill="#6C72AF"
    />
    <path
      d="M313.105 49.284C314.405 55.822 310.499 62.272 304.102 64.147L292.519 67.543C285.264 69.67 277.748 65.117 276.273 57.702L275.855 55.601C274.38 48.186 279.583 41.103 287.099 40.292L299.1 38.997C305.727 38.282 311.804 42.746 313.105 49.284V49.284Z"
      fill="#464D94"
    />
    <path
      d="M316.932 44.414C323.592 44.132 328.935 38.811 329.245 32.152L329.806 20.094C330.158 12.542 323.956 6.31498 316.403 6.63498L314.263 6.72598C306.71 7.04598 301.057 13.775 302.046 21.27L303.625 33.237C304.498 39.847 310.271 44.696 316.932 44.414Z"
      fill="#464D94"
    />
    <path
      d="M324.155 61.76C322.484 61.76 320.802 61.221 319.385 60.108L286.748 34.46C283.394 31.823 282.811 26.966 285.448 23.611C288.084 20.255 292.94 19.673 296.297 22.31L328.934 47.959C332.288 50.596 332.871 55.453 330.234 58.808C328.71 60.747 326.443 61.76 324.155 61.76V61.76Z"
      fill="#6C72AF"
    />
    <path
      d="M475.395 202.252C474.593 202.252 473.778 202.126 472.975 201.862C468.923 200.526 466.719 196.158 468.055 192.105C472.059 179.955 469.6 168.144 460.538 155.996C448.873 140.362 457.437 124.789 463.596 118.738C466.639 115.748 471.532 115.79 474.523 118.835C477.499 121.865 477.47 126.726 474.468 129.72C473.097 131.123 466.707 138.423 472.925 146.756C485.05 163.01 488.35 179.895 482.732 196.942C481.662 200.191 478.64 202.252 475.395 202.252Z"
      fill="#464D94"
    />
    <path
      d="M404.021 154.408C403.219 154.408 402.404 154.282 401.601 154.018C397.549 152.682 395.345 148.314 396.682 144.261C400.686 132.112 398.227 120.3 389.165 108.152C377.5 92.517 386.064 76.944 392.223 70.894C395.266 67.905 400.159 67.947 403.15 70.991C406.126 74.02 406.097 78.879 403.097 81.874C401.73 83.272 395.331 90.574 401.552 98.912C413.677 115.166 416.977 132.051 411.359 149.098C410.287 152.348 407.265 154.408 404.021 154.408V154.408Z"
      fill="#464D94"
    />
    <path
      d="M321.192 195.785C320.39 195.785 319.575 195.659 318.772 195.395C314.72 194.059 312.516 189.691 313.852 185.638C317.856 173.488 315.397 161.677 306.335 149.529C294.67 133.895 303.234 118.322 309.393 112.271C312.436 109.282 317.329 109.323 320.32 112.368C323.296 115.398 323.267 120.259 320.265 123.253C318.894 124.656 312.504 131.956 318.722 140.289C330.847 156.543 334.147 173.428 328.529 190.475C327.458 193.725 324.436 195.785 321.192 195.785Z"
      fill="#464D94"
    />
  </SvgIcon>
);
RemovedPage.muiName = 'SvgIcon';

RemovedPage = React.memo(RemovedPage);
RemovedPage.displayName = 'Error503';
const RemovedPagePicture = RemovedPage;

export default RemovedPagePicture;
