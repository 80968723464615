import React from 'react';
import clsx from 'clsx';
import {
  Table as DevExpressTable,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import useStyles from './DefaultTableRow.styles';

interface OtherProps {
  className?: string;
  onClick?: () => void;
}

export default ({
  children,
  tableRow,
  row,
  className,
  ...rest
}: DevExpressTable.DataRowProps & OtherProps) => {
  const classes = useStyles(row.viewConfig)();

  return (
    <VirtualTable.Row
      className={clsx(classes.row, className)}
      row={row}
      tableRow={tableRow}
      {...rest}
    >
      {children}
    </VirtualTable.Row>
  );
};
