import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Skeleton from '@material-ui/lab/Skeleton';
import mainService from 'services/Main';
import { Component, RequestConfig } from 'services/Main/types.Component';
import ComponentLoader from 'components/creational/ComponentLoader';
import useStyles from './AsyncDialog.styles';

interface AsyncDialogProps {
  open: boolean;
  handleClose: () => void;
  fetchModalDataRequestConfig: RequestConfig;
  fetchModalDataRequestParams?: object;
}

const AsyncDialog = ({
  open,
  handleClose,
  fetchModalDataRequestConfig,
  fetchModalDataRequestParams,
}: AsyncDialogProps) => {
  const classes = useStyles();
  const [component, setComponent] = useState<Component | undefined>();

  useEffect(() => {
    let didCancel = false;

    const makeRequest = () => {
      mainService
        .makeActionRequest<Component>(
          fetchModalDataRequestConfig,
          fetchModalDataRequestParams
        )
        .then(({ isSuccess, payload }) => {
          if (isSuccess && !didCancel) {
            setComponent(payload);
          }
        });
    };

    if (open) {
      makeRequest();
    }

    return () => {
      didCancel = true;
      setComponent(undefined);
    };
  }, [open, fetchModalDataRequestConfig, fetchModalDataRequestParams]);

  return (
    <Dialog open={open} onClose={handleClose}>
      {open && (
        <Box className={classes.wrapper} px={3} py={2}>
          {component ? (
            <ComponentLoader component={component} />
          ) : (
            <Box width="100%" pb={1}>
              <Skeleton />
              <Skeleton variant="rect" height={180} />
            </Box>
          )}
        </Box>
      )}
    </Dialog>
  );
};

export default AsyncDialog;
