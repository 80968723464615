import React, { PropsWithChildren, useCallback, useState } from 'react';
import debounce from 'lodash.debounce';
import {
  DirtyFormValues,
  FilterChip,
  FilterOptions,
  Header,
  RequestConfig,
  SortingState,
  TableColumn,
  TableFilterFormComponent,
  TableRow,
} from '../../../../../../../services/Main/types.Component';
import EntryPickerTableContext from '../../EntryPickerTable.context';
import entryPickerService from '../../../../../../../services/EntryPicker';
import { useFormatMessage } from '../../../../../../../locale';
import useEnqueueSnackbar from '../../../../../../../utils/hooks/useEnqueueSnackbar';
import { FetchTablePropsRequestBody } from '../../../../../../../services/EntryPicker/EntryPicker.interface';

interface EntryPickerTableProviderProps {
  requestConfig: RequestConfig;
  onEntryChoose: (chosenValues: (string | number)[]) => void;
  multiple?: boolean;
  onCancel: () => void;
  defaultPageSize?: number;
  defaultCurrentPage?: number;
}

const EntryPickerTableProvider = ({
  requestConfig,
  onEntryChoose,
  onCancel,
  multiple,
  children,
  defaultPageSize = 10,
  defaultCurrentPage = 0,
}: PropsWithChildren<EntryPickerTableProviderProps>) => {
  const formatMessage = useFormatMessage();
  const enqueueSnackbar = useEnqueueSnackbar();

  // Состояние таблицы
  const [columns, setColumns] = useState<TableColumn[] | null>(null);
  const [rows, setRows] = useState<TableRow[]>([]);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [currentPage, setCurrentPage] = useState<number>(defaultCurrentPage);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [header, setHeader] = useState<Header | undefined>(undefined);
  const [filterComponent, setFilterComponent] = useState<
    TableFilterFormComponent | undefined
  >(undefined);

  // Состояния сортировки
  const [sorting, setSorting] = useState<SortingState | undefined>();
  // Состояния выбранной строки
  const [selectedRowsId, setSelectedRowsId] = useState<(number | string)[]>([]);
  // Состояни фильтров
  const [filter, setFilter] = useState<DirtyFormValues | undefined>();
  const [filterOptions, setFilterOptions] = useState<
    FilterOptions | undefined
  >();
  // Состояниие чипсов
  const [filterChips, setFilterChips] = useState<FilterChip[] | undefined>();
  // Состояни загрузки таблицы
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const debounceLoadRows = useCallback(
    debounce((params: FetchTablePropsRequestBody) => {
      setIsLoading(true);

      entryPickerService
        .fetchTableProps(requestConfig, params)
        .then((response) => {
          console.log('Fetch table props response (EntryPicker):', response);
          const { payload } = response;

          // В single entry picker
          if (!multiple) {
            // Сбрасываем выбранную строку
            if (payload.rows.length > 0) {
              setSelectedRowsId([payload.rows[0].id]);
            } else {
              setSelectedRowsId([]);
            }
          }

          setColumns(payload.columns);
          setRows(payload.rows);
          setTotalRows(payload.totalRows);

          setHeader(payload.header);
          setFilterComponent(payload.filterComponent);
        })
        .catch(() => {
          enqueueSnackbar(formatMessage('errorOccurredWhileRequestingData'), {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 250),
    []
  );

  return (
    <EntryPickerTableContext.Provider
      value={{
        multiple,
        onEntryChoose,
        onCancel,
        columns,
        setColumns,
        rows,
        setRows,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
        totalRows,
        setTotalRows,
        header,
        setHeader,
        filterComponent,
        setFilterComponent,
        sorting,
        setSorting,
        selectedRowsId,
        setSelectedRowsId,
        filter,
        setFilter,
        filterChips,
        setFilterChips,
        isLoading,
        setIsLoading,
        loadRows: debounceLoadRows,
        filterOptions,
        setFilterOptions,
      }}
    >
      {children}
    </EntryPickerTableContext.Provider>
  );
};

export default EntryPickerTableProvider;
