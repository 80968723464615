import React from 'react';
import Grid from '@material-ui/core/Grid';
import { TextWidget } from '../../../services/Main/types.Component';
import TextWidgetComponent from '../../lowLevel/TextWidget';
import SizeWrapper from './SizeWrapper';

interface Props {
  textWidgets: TextWidget[];
}

export default ({ textWidgets }: Props) => (
  <Grid container spacing={2}>
    {textWidgets.map((textWidgetProps, index) => (
      <SizeWrapper key={index}>
        <TextWidgetComponent {...textWidgetProps} />
      </SizeWrapper>
    ))}
  </Grid>
);
