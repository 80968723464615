import { SocketConfig, TimelineElement } from '../Main/types.Component';
import SocketServiceIo from '../Socket/SocketService.io';
import SocketServiceSignalR from '../Socket/SocketService.signalR';

const TIMELINE_INITIALIZED = 'timelineInitialized';
const TIMELINE_ELEMENT_ADDED = 'timelineElementAdded';

class TimelineService {
  client: SocketServiceIo | SocketServiceSignalR;

  constructor(config: SocketConfig) {
    this.client =
      config.client === 'io'
        ? new SocketServiceIo(config)
        : new SocketServiceSignalR(config);
  }

  subscribeOnTimelineInitialized = (
    cb: (timelineElement: TimelineElement[]) => void
  ) => {
    this.client.connection.on(TIMELINE_INITIALIZED, cb);
  };

  subscribeOnTimelineElementAdded = (
    cb: (timelineElement: TimelineElement) => void
  ) => {
    this.client.connection.on(TIMELINE_ELEMENT_ADDED, cb);
  };
}

export default TimelineService;
