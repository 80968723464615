import React, { ReactElement, useCallback, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { FormActionProps } from './types';
import Icon from '../Icon/Icon';
import { ShowFormAction } from '../../../services/Main/types.Component';
import FormBuilder from '../FormBuilder';
import Header from '../Header';
import FormActions from '../FormActions';
import { useFormatMessage } from '../../../locale';
import Alerts from '../Alerts';
import FormActionButton from './FormAction.Button';

interface OtherProps {
  renderButton?: (openDialog: () => void) => ReactElement;
}

const DialogFormAction = ({
  action,
  renderButton,
}: FormActionProps & OtherProps) => {
  const { label, color, icon, variant, form, viewStyle, actions, alerts } =
    action as ShowFormAction;
  const formatMessage = useFormatMessage();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const openDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  // TODO обработать
  if (form.type === 'preset') return null;

  return (
    <>
      {renderButton ? (
        renderButton(openDialog)
      ) : (
        <Grid item>
          <FormActionButton
            variant={variant}
            color={color}
            onClick={openDialog}
            startIcon={icon && <Icon definition={icon} />}
          >
            {label}
          </FormActionButton>
        </Grid>
      )}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isDialogOpen}
        onClose={closeDialog}
        fullScreen={viewStyle === 'fullScreenModal'}
      >
        {form.header && (
          <DialogTitle>
            <Header {...form.header} />
          </DialogTitle>
        )}
        <DialogContent>
          <FormBuilder
            id={form.id}
            fieldGroups={form.fieldGroups}
            header={() => <Alerts staticAlerts={alerts} />}
            footer={() => {
              if (!actions) return <span />;

              return (
                <Grid container spacing={1} justify="flex-end">
                  <Grid item>
                    <FormActionButton
                      variant="outlined"
                      color={color}
                      onClick={closeDialog}
                    >
                      {formatMessage('cancel')}
                    </FormActionButton>
                  </Grid>
                  <FormActions
                    actions={actions}
                    onReactionExecute={closeDialog}
                  />
                </Grid>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogFormAction;
