import React, { useContext } from 'react';
import clsx from 'clsx';
import { Tabs, Tab, Button } from '@material-ui/core';
import {
  TableQuickFilterOption,
  TableQuickFilters,
} from '../../../services/Main/types.Component';
import useStyles from './TableQuickFilters.styles';
import { TableContext } from '../../highLevel/Table';
import { useFormatMessage } from '../../../locale';

export default ({ options }: TableQuickFilters) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const tableContext = useContext(TableContext);

  const hasFilter =
    Array.isArray(tableContext?.quickFiltersState) &&
    tableContext?.quickFiltersState.length !== 0;

  const cleanFilters = () => {
    tableContext?.setQuickFilters([]);
  };

  return (
    <div className={classes.wrapper}>
      <Tabs
        variant="scrollable"
        textColor="primary"
        value={false} // см. аннотацию свойства
        className={classes.container}
      >
        {options.map(TableQuickFilter)}
      </Tabs>
      {hasFilter && (
        <Button
          className={classes.cleanButton}
          variant="text"
          onClick={cleanFilters}
        >
          {formatMessage('reset')}
        </Button>
      )}
    </div>
  );
};

function TableQuickFilter({ value, label }: TableQuickFilterOption) {
  const classes = useStyles();
  const tableContext = useContext(TableContext);
  const isActive = tableContext?.quickFiltersState.some((v) => value === v);

  const handleClick = () => {
    tableContext?.setQuickFilters((filters) => {
      if (filters.includes(value)) {
        return filters.filter((f) => f !== value);
      }
      return [...filters, value];
    });
  };

  return (
    <Tab
      key={value}
      className={clsx(classes.tab, {
        active: isActive,
      })}
      label={label}
      onClick={handleClick}
    />
  );
}
