import { ReactElement } from 'react';
import { useInitialAppData } from 'utils/hooks/useInitialAppData';

/**
 * Компонент для инициализации redux-хранилища данными с бека
 *
 * @constructor
 */
const RootLoader = ({ children }: { children?: ReactElement }) => {
  const isInitialized = useInitialAppData();

  if (!isInitialized) return null;

  return children || null;
};

export default RootLoader;
