import { useCallback, useContext } from 'react';
import EntryPickerTableContext from '../EntryPickerTable.context';

export function useSelectRow() {
  const { selectedRowsId, setSelectedRowsId, multiple } = useContext(
    EntryPickerTableContext
  );

  const handleChangeSelection = useCallback(
    (newSelection: Array<number | string>) => {
      // Логика работы Multi Select
      if (multiple) {
        setSelectedRowsId(newSelection);
        return;
      }

      // Логика работы Single Select
      const lastSelectedRowId = newSelection.find(
        (selected) => selectedRowsId.indexOf(selected) === -1
      );

      if (lastSelectedRowId !== undefined) {
        setSelectedRowsId([lastSelectedRowId]);
      }
    },
    [multiple, selectedRowsId, setSelectedRowsId]
  );

  return {
    handleChangeSelection,
  };
}
