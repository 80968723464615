import React, { useState } from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { formatISO } from 'date-fns';
import { DateRangePicker } from 'services/Main/types.Field';
import { DATE_FORMAT } from 'commonConstants';
import isValidDate from 'utils/isValidDate';
import { formatDate } from 'utils/formatDate';
import { getDate } from '../../helpers';
import { useFormatMessage } from '../../../../../locale';
import useStyles from '../../DatePicker.styles';

type DateRangeProps = DateRangePicker & {
  value?: any;
  onChange?: any;
};

export default ({
  disabled,
  value,
  onChange,
  initialFocusedDate,
  label,
  minDate,
  maxDate,
  autoComplete,
}: DateRangeProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [internalErrorFrom, setInternalErrorFrom] = useState<string | null>(
    null
  );
  const [internalErrorTo, setInternalErrorTo] = useState<string | null>(null);
  const internalErrorText = formatMessage('enterTheCorrectDateFormat', {
    date: formatDate(new Date()),
  });

  const handleChange = (id: 'from' | 'to') => (date: MaterialUiPickersDate) => {
    if (isValidDate(date) || date === null) {
      const newValues = value
        ? { ...value }
        : { from: undefined, to: undefined };

      newValues[id] = date ? formatISO(date) : undefined;
      id === 'from' ? setInternalErrorFrom(null) : setInternalErrorTo(null);

      onChange(newValues);
    } else {
      id === 'from'
        ? setInternalErrorFrom(internalErrorText)
        : setInternalErrorTo(internalErrorText);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <InputLabel shrink>{label}</InputLabel>
      </Grid>
      <Grid item xs={isSmDown ? 12 : 6}>
        <KeyboardDatePicker
          autoOk
          clearable
          fullWidth
          showTodayButton
          label={formatMessage('from')}
          minDate={minDate}
          maxDate={maxDate}
          value={getDate(value?.from)}
          KeyboardButtonProps={{
            className: clsx(classes.keyboardButton, { disabled }),
          }}
          helperText={internalErrorFrom}
          onChange={handleChange('from')}
          initialFocusedDate={getDate(initialFocusedDate, new Date())}
          format={DATE_FORMAT}
          disabled={disabled}
          InputProps={{
            autoComplete: autoComplete || 'off',
          }}
          clearLabel={formatMessage('clear')}
          todayLabel={formatMessage('today')}
          cancelLabel={formatMessage('cancel')}
        />
      </Grid>
      <Grid item xs={isSmDown ? 12 : 6}>
        <KeyboardDatePicker
          autoOk
          clearable
          fullWidth
          showTodayButton
          label={formatMessage('to')}
          minDate={minDate}
          maxDate={maxDate}
          value={getDate(value?.to)}
          KeyboardButtonProps={{
            className: clsx(classes.keyboardButton, { disabled }),
          }}
          helperText={internalErrorTo}
          onChange={handleChange('to')}
          initialFocusedDate={getDate(initialFocusedDate, new Date())}
          format={DATE_FORMAT}
          disabled={disabled}
          InputProps={{
            autoComplete: autoComplete || 'off',
          }}
          clearLabel={formatMessage('clear')}
          todayLabel={formatMessage('today')}
          cancelLabel={formatMessage('cancel')}
        />
      </Grid>
    </Grid>
  );
};
