export default function (name: string) {
  let Icon: any;

  if (name === 'FileArchive') Icon = require('svg/FileArchive').default;
  if (name === 'FileDoc') Icon = require('svg/FileDoc').default;
  if (name === 'FileExcel') Icon = require('svg/FileExcel').default;
  if (name === 'FilePdf') Icon = require('svg/FilePdf').default;
  if (name === 'Google') Icon = require('svg/Google').default;
  if (name === 'List') Icon = require('svg/List').default;
  if (name === 'Logo') Icon = require('svg/Logo').default;
  if (name === 'Record') Icon = require('svg/Record').default;
  if (name === 'Shop') Icon = require('svg/Shop').default;
  if (name === 'User') Icon = require('svg/User').default;
  if (name === 'Handyman') Icon = require('svg/Handyman').default;
  if (name === 'AddBusiness') Icon = require('svg/AddBusiness').default;
  if (name === 'Rule') Icon = require('svg/Rule').default;
  if (name === 'PendingActions') Icon = require('svg/PendingActions').default;

  return Icon || undefined;
}
