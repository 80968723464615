import { ChartInterface, UpdateDataPayload } from './Chart.interface';
import { RequestConfig } from '../Main/types.Component';
import { post } from '../Api';
import { ServerResponse } from '../Main/types';

class ChartRemote implements ChartInterface {
  updateData = <T>({ url }: RequestConfig, payload: UpdateDataPayload) => {
    return post<ServerResponse<T>>(url, payload).then(({ data }) => data);
  };
}

export default ChartRemote;
