import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    row: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(16, 156, 241, 0.07)',
        userSelect: 'none',
      },
    },
  })
);
