import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import isEqual from 'lodash.isequal';
import {
  FieldRenderCondition,
  OptionsType,
} from '../../../services/Main/types.Field';
import FormBuilderContext from '../FormBuilder/FormBuilderContext';

export default function useShouldRender(
  renderCondition?: FieldRenderCondition
): boolean {
  const { watch } = useFormContext();
  const { fieldsByName } = useContext(FormBuilderContext);

  if (!renderCondition) return true;

  const masterFieldValue = (() => {
    const value = watch(renderCondition.dependOn);
    const field = fieldsByName[renderCondition.dependOn];

    if (field.type === 'comboBox') {
      // Конвертируем ComboBoxOption[] в ComboBoxOption для single flat и radio.
      if (
        (field.optionsType === OptionsType.flat && !field.multiple) ||
        field.optionsType === OptionsType.radioTree
      ) {
        return value[0];
      }
    }

    return value;
  })();

  switch (renderCondition.condition) {
    case 'empty':
      return !masterFieldValue;

    case 'equals':
      return isEqual(masterFieldValue, renderCondition.value);

    case 'except':
      return !isEqual(masterFieldValue, renderCondition.value);

    case 'notEmpty':
      return !!masterFieldValue;

    default:
      throw new Error('Не удалось определить тип условия');
  }
}
