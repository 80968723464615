import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import { AddTimelineElementFormProps } from '../types';
import AddTimelineElementForm from '../AddTimelineElementForm';
import useStyles from '../AddTimelineElementForm.styles';
import { TimelineContext } from '../../../highLevel/Timeline/TimelineContext';
import { useFormatMessage } from '../../../../locale';

const AddTimelineElementFormCollapse = (props: AddTimelineElementFormProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const timelineContext = useContext(TimelineContext);

  const handleCollapseOpen = () => {
    timelineContext?.setIsAddTimelineElementFormOpened(true);
  };

  const handleCollapseClose = () => {
    timelineContext?.setIsAddTimelineElementFormOpened(false);
  };

  return (
    <div>
      <hr />
      {!timelineContext?.isAddTimelineElementFormOpened ? (
        <Typography
          variant="body1"
          className={classes.AddTimelineElementFormCollapse}
          onClick={handleCollapseOpen}
        >
          {`${formatMessage('enterYourComment')}...`}
        </Typography>
      ) : (
        <AddTimelineElementForm
          closeCollapse={handleCollapseClose}
          {...props}
        />
      )}
    </div>
  );
};

export default AddTimelineElementFormCollapse;
