import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import objectHash from 'object-hash';
import isEmpty from 'lodash.isempty';
import merge from 'lodash.merge';
import {
  DirtyFormValues,
  FetchTableDataOptions,
} from 'services/Main/types.Component';
import { deserializePayload } from './useGetParams';

type State = Omit<
  FetchTableDataOptions,
  'requestConfig' | 'filter' | 'currentPage' | 'pageSize'
> & {
  filter?: DirtyFormValues;
  currentPage?: number;
  pageSize?: number;
};

/**
 * У Table filter, sort etc есть следующие state:
 * 1. React.
 * 2. Query params.
 *
 * Жизненный цикл состояния этих вещей:
 * 1. При загрузке страницы распарсивается query.
 * 2. Если там есть данные, то подсовываются в качестве defaultValue в react.
 * 3. При изменении фильтров происходит обновление react-состояния и на основании
 *   него происходит обновление query params.
 *
 * Логика обновления query params, если в react-state произошло, то должно работать так:
 * Удаление параметра:
 *   Было:
 *    { payload: { filter: { status: 1, shop: 'c7395879-c090-456a-9e63-098e5d26a33d' } } }
 *   Стало:
 *    { payload: { filter: { status: 1 } } }
 *
 * Добавление параметра:
 *   Было:
 *    { payload: { filter: { status: 1 } } }
 *   Стало:
 *    { payload: { filter: { status: 1, shop: 'c7395879-c090-456a-9e63-098e5d26a33d' } } }
 *
 * Изменение параметра:
 *   Было:
 *    { payload: { filter: { status: 1, shop: 'c7395879-c090-456a-9e63-098e5d26a33d' } } }
 *   Стало:
 *    { payload: { filter: { status: 2,  shop: 'c7395879-c090-456a-9e63-098e5d26a33d' } } }
 */
export default (state: State) => {
  const stateHash = objectHash(state);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // Обрезание состояния до минимального вида.
    const preparedState: State | null = (() => {
      const s: State = {};

      if (state.filter && !isEmpty(state.filter)) {
        s.filter = state.filter;
      }
      if (state.sort) {
        s.sort = state.sort;
      }
      if (state.query) {
        s.query = state.query;
      }
      if (state.quickFilters && state.quickFilters.length > 0) {
        s.quickFilters = state.quickFilters;
      }
      if (state.withClosed || typeof state.withClosed === 'boolean') {
        s.withClosed = state.withClosed;
      }
      if (state.currentPage || typeof state.currentPage === 'number') {
        s.currentPage = state.currentPage;
      }
      if (state.pageSize || typeof state.pageSize === 'number') {
        s.pageSize = state.pageSize;
      }

      if (isEmpty(s)) return null;

      return s;
    })();

    const queryParams = new URLSearchParams(location.search);
    const payload = deserializePayload(queryParams.get('payload'));
    // Не упаковываем filter, sort, query и quickFilters из query params,
    // т.к. считаем, что в react-state всегда лежат актуальные значения.
    const { filter, sort, query, quickFilters, ...serializableQueryParams } =
      payload || {};

    // Не ломайте deepMerge :<
    // См. https://lodash.com/docs/4.17.15#merge
    // Тут происходит мутация в serializableQueryParams.
    // serializableQueryParams - новая переменная. Не ссылка!
    merge(serializableQueryParams, preparedState);

    // Удаляем withClosed: false из query params.
    // Не стал делать это для всех булевых, т.к. неизвестно, какие параметры
    // будут булевыми в будущем.
    if (serializableQueryParams.withClosed === false) {
      delete serializableQueryParams.withClosed;
    }

    // Удаляем currentPage: 0 из query params.
    if (serializableQueryParams.currentPage === 0) {
      delete serializableQueryParams.currentPage;
    }

    !isEmpty(serializableQueryParams)
      ? queryParams.set('payload', JSON.stringify(serializableQueryParams))
      : queryParams.delete('payload');

    history.replace({ search: queryParams.toString(), hash: location.hash });
    // eslint-disable-next-line
  }, [stateHash]);
};
