export function resetHash() {
  window.history.replaceState(null, '', ' ');
}

export function setHash(hash: string | number) {
  window.location.hash = `#${hash}`;
}

export function getHash(): string {
  return window.location.hash.substring(1);
}
