import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { OtherUserTimelineElement } from '../../../../services/Main/types.Component';
import useStyles from './OtherUserTimelineElement.styles';
import useSharedStyles from '../../../highLevel/Timeline/Timeline.styles';
import Icon from '../../Icon/Icon';
import TimelineQuote from '../../TimelineQuote';
import { formatDateFromISOstring } from '../../../../utils/formatDate';
import FileList from '../../FileList';
import IconWithTooltip from '../../IconWithTooltip';
import { TimelineContext } from '../../../highLevel/Timeline/TimelineContext';
import OtherUserTimelineElementAvatar from './OtherUserTimelineElementAvatar';

export default ({
  id,
  body,
  createdAt,
  attachments,
  privacy,
  quote,
  header,
  isAddedManually,
  author,
}: OtherUserTimelineElement) => {
  const timelineContext = useContext(TimelineContext);
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const formattedCreatedAt = formatDateFromISOstring(
    createdAt as string,
    'dateTime'
  );

  const handleQuoteClick = () => {
    timelineContext?.setQuote({
      quotedElementId: id,
      body,
      header,
    });
  };

  return (
    <div
      className={clsx(
        sharedClasses.CommonTimelineElement,
        classes.OtherUserTimelineElementRoot
      )}
      data-timeline-element-id={id}
    >
      <div className={classes.OtherUserTimelineElementAvatar}>
        <OtherUserTimelineElementAvatar author={author} />
      </div>
      <div className={classes.OtherUserTimelineElementContentWrapper}>
        <Grid
          className={clsx(
            sharedClasses.TimelineElementWithBorder,
            classes.OtherUserTimelineElementContainer
          )}
          container
        >
          <Grid
            item
            xs={12}
            md={9}
            className={sharedClasses.CommonTimelineElementBodyContainer}
          >
            <Grid
              className={classes.OtherUserTimelineElementHeaderContainer}
              container
              spacing={1}
              alignItems="center"
            >
              <Grid
                className={classes.MobileOtherUserTimelineElementAvatar}
                item
              >
                <OtherUserTimelineElementAvatar author={author} />
              </Grid>
              <Grid item>
                <div
                  className={clsx(
                    sharedClasses.TimelineHeader,
                    classes.OtherUserTimelineHeader
                  )}
                >
                  {header}
                </div>
              </Grid>
            </Grid>
            {quote && (
              <Box mb={1}>
                <TimelineQuote {...quote} />
              </Box>
            )}
            <div
              className={sharedClasses.CommonTimelineElementBody}
              dangerouslySetInnerHTML={{ __html: body.html }}
            />
            {attachments && <FileList files={attachments} viewSize="small" />}
          </Grid>
          <Grid
            xs={12}
            md={3}
            className={sharedClasses.CommonTimelineElementSidebar}
            container
            item
            direction="column"
            justify="space-between"
          >
            <Grid container justify="flex-end" spacing={2}>
              <Grid
                item
                className={clsx(sharedClasses.CommonTimelineElementCreatedAt)}
              >
                {formattedCreatedAt}
              </Grid>
              {isAddedManually && (
                <Grid item className={sharedClasses.CommonTimelineElementIcon}>
                  <IconWithTooltip
                    definition="mui:ChatBubbleOutline"
                    tooltipText="Свободный комментарий"
                  />
                </Grid>
              )}
              {privacy && (
                <Grid item className={sharedClasses.CommonTimelineElementIcon}>
                  <IconWithTooltip
                    definition="mui:LockOutlined"
                    tooltipText={`Видимость: ${privacy.label}`}
                  />
                </Grid>
              )}
            </Grid>
            <Grid
              item
              className={sharedClasses.CommonTimelineElementQuoteButton}
            >
              <Icon definition="mui:Reply" onClick={handleQuoteClick} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
