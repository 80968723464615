import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

interface Props {
  isAccordionEnabled: boolean;
  disableBorder?: boolean;
  padding?: string;
}

export default ({ isAccordionEnabled, disableBorder, padding }: Props) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        border: disableBorder
          ? 'none'
          : `1px solid ${theme.additionalPalette.lightGray}`,
        borderRadius: 5,
        padding,
        '&:not(:last-child)': {
          marginBottom: theme.spacing(2),
        },
        '&:before': {
          display: 'none',
        },
      },
      summary: {
        minHeight: '48px !important',

        '&:hover': {
          cursor: isAccordionEnabled
            ? 'pointer !important'
            : 'default !important',
        },

        '& .MuiExpansionPanelSummary-content': {
          margin: '10px 0 !important',
        },
      },
      label: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(10),
        textTransform: 'uppercase',
      },
      detail: {
        padding,
      },
      heading: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(10),
        textTransform: 'uppercase',
      },
    })
  );
