import { Dispatch, SetStateAction } from 'react';
import { FormatMessage } from '../../locale/hooks';

interface Props {
  formatMessage: FormatMessage;
  setErrorText: Dispatch<SetStateAction<string | undefined>>;
}

export default ({ formatMessage, setErrorText }: Props) => (error: any) => {
  if (error.message.includes('User or password is incorrect')) {
    setErrorText(formatMessage('incorrectUsernameOrPassword'));
  } else if (error.message.includes('User blocked by administrator')) {
    setErrorText(formatMessage('userBlockedByAdministrator'));
  } else {
    setErrorText(formatMessage('errorOccurredWhileTryingToLogin'));
    console.error(error);
  }
};
