export default class ScrollManager {
  container: HTMLElement;

  dataIdName: string;

  constructor(
    container: HTMLElement,
    initialScrollTop: number,
    dataIdName: string
  ) {
    this.container = container;
    this.dataIdName = dataIdName;
    this.container.scrollTop = initialScrollTop;
  }

  scrollToElementWithId(id: string) {
    const element: HTMLElement | null = document.querySelector(
      `[${this.dataIdName}='${id}']`
    );

    if (element)
      this.container.scrollTop = element.offsetTop - this.container.offsetTop;
  }

  scrollToBottom() {
    this.container.scrollTop = this.container.scrollHeight;
  }

  // Считаем проскролленным, если проскроллено хотя бы до 10 пикселей снизу.
  get isScrolledToBottom() {
    return (
      this.container.scrollTop + this.container.clientHeight >=
      this.container.scrollHeight - 10
    );
  }
}
