import { ChartInterface } from './Chart.interface';

let ChartService: any;

if (
  process.env.REACT_APP_ENV === 'development' &&
  process.env.REACT_APP_IS_REMOTE !== 'true'
) {
  ChartService = require('./Chart.local').default;
} else {
  ChartService = require('./Chart.remote').default;
}

const chartService = new ChartService() as ChartInterface;

export default chartService;
