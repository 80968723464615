import axios, { CancelTokenSource } from 'axios';
import { get, post } from 'services/Api';
import { MainService } from '../Main.interface';
import {
  ActionServerResponse,
  FetchAutocompleteOptionsPayload,
  FetchAutocompleteOptionsResponse,
  GetAutocompleteOptionsPayload,
  GetDependentAutocompleteOptionsPayload,
  RootResponse,
  ServerResponse,
} from '../types';
import { Page, PageId } from '../types.Page';
import {
  FetchTableDataOptions,
  RequestConfig,
  TableComponentProps,
} from '../types.Component';
import { stringifyParamsValues } from '../../../utils/serializeObjectToUrl';
import store from '../../../store';

const COMPONENT_PREFIX = '/component/v1.0';

export default class MainServiceLocal implements MainService {
  fetchRootData = (): Promise<RootResponse> =>
    get<ServerResponse<RootResponse>>(`${COMPONENT_PREFIX}/root`).then(
      ({ data }) => data?.payload
    );

  fetchPageData = (
    pageId: PageId,
    urlParts: object,
    params?: object,
    componentUrlPrefix?: string,
    method: 'GET' | 'POST' = 'GET'
  ): { request: Promise<Page>; source: CancelTokenSource } => {
    const cleanParams = Object.entries(params || {}).reduce(
      (acc, [key, value]) => {
        if (value) return { ...acc, [key]: value };
        return acc;
      },
      {} as any
    );

    const url = componentUrlPrefix || `${COMPONENT_PREFIX}/get/`;
    const source: CancelTokenSource = axios.CancelToken.source();

    if (method === 'POST') {
      return {
        request: post<ServerResponse<Page>>(
          `${url}${pageId}${stringifyParamsValues(urlParts)}`,
          cleanParams,
          { cancelToken: source.token }
        ).then(({ data }) => data?.payload),
        source,
      };
    }

    return {
      request: get<ServerResponse<Page>>(
        `${url}${pageId}${stringifyParamsValues(urlParts)}`,
        {
          params: cleanParams,
          cancelToken: source.token,
        }
      ).then(({ data }) => data?.payload),
      source,
    };
  };

  fetchTableData = ({
    requestConfig,
    ...params
  }: FetchTableDataOptions): Promise<Partial<TableComponentProps>> => {
    return post<ServerResponse<TableComponentProps>>(
      `${requestConfig.url}`,
      params
    ).then(({ data }) => data?.payload);
  };

  fetchAutocompleteOptions = (
    searchUrl: string,
    payload: GetAutocompleteOptionsPayload
  ): Promise<FetchAutocompleteOptionsPayload> =>
    post<FetchAutocompleteOptionsResponse>(searchUrl, { payload }).then(
      ({ data }) => data.payload
    );

  fetchDependentSelectOptions = (
    searchUrl: string,
    payload: GetDependentAutocompleteOptionsPayload
  ): Promise<FetchAutocompleteOptionsPayload> =>
    post<FetchAutocompleteOptionsResponse>(searchUrl, { payload }).then(
      ({ data }) => data.payload
    );

  makeActionRequest = <T>(
    { method, url }: RequestConfig,
    initialParams?: object
  ) => {
    const additionalPayload =
      store.getState().meta.additionalPayloadToSendWithFormStore[url] || {};
    const params = { ...initialParams, ...additionalPayload };

    return method === 'POST'
      ? post<ActionServerResponse<T>>(url, params).then(({ data }) => data)
      : get<ActionServerResponse<T>>(url, { data: params }).then(
          ({ data }) => data
        );
  };
}
