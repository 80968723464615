import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { Component } from 'services/Main/types.Component';

export type DialogWithComponentState = Component | null;

const open: CaseReducer<DialogWithComponentState, PayloadAction<Component>> = (
  state,
  action
) => action.payload;

const close: CaseReducer<DialogWithComponentState> = () => null;

export const { reducer, actions } = createSlice({
  name: 'dialogWithComponent',
  initialState: null as DialogWithComponentState,
  reducers: {
    open,
    close,
  },
});

export default reducer;
