import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '../../../theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    TimelineQuote: {
      borderLeft: `3px solid ${theme.additionalPalette.title}`,
      paddingLeft: theme.spacing(1),
      userSelect: 'none',
      cursor: 'pointer',
    },
    TimelineQuoteText: {
      // УДАЛИТЬ, КОГДА ОТКАЖЕМСЯ ОТ HTML В ЦИТАТАХ
      '& *': {
        fontWeight: 'normal !important',
        textAlign: 'left !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        textDecoration: 'none !important',

        ...theme.typography.body2,
      },
    },
  })
);
