import { LocalizationDictionary } from './types';

const ruDictionary: LocalizationDictionary = {
  localeName: 'Русский',
  language: 'Язык',
  addLine: 'Добавить строку',
  close: 'Закрыть',
  search: 'Поиск',
  errorOccurredWhileRequestingData:
    'При запросе данных произошла ошибка. Попробуйте ещё раз или обратитесь к администратору.',
  uploadFiles: 'Загрузите файлы',
  clickToUploadFiles: 'Нажмите, чтобы загрузить файлы',
  enterYourComment: 'Введите комментарий',
  send: 'Отправить',
  enterTheCorrectDateFormat: 'Введите корректный формат даты, пример {date}', // Interpolation ${formatDate(new Date())
  noData: 'Нет данных',
  goBackToTheMainPage: 'Вернуться на главную',
  errorWhileUploadingFiles:
    'Ошибка при загрузке файлов. Попробуйте ещё раз или обратитесь к администратору.',
  fileTooLargeError:
    'Превышен объем загружаемого файла. Выберите файл меньшего размера или обратитесь к администратору.',
  cancel: 'Отменить',
  secondsLeft: 'Осталось {remaining} сек.', // Interpolation
  minutesLeft: 'Осталось около {remaining} мин.', // Interpolation
  hoursLeft: 'Осталось около {remaining} ч.', // Interpolation
  daysLeft: 'Осталось около {remaining} д.', // Interpolation
  QRCodeDescription:
    'Направьте камеру на изображение с QR-кодом так, чтобы QR-код попал в центр поля, ограниченного красными уголками.',
  errorOccurredWhileTryingToScan:
    'При попытке сканировать произошла ошибка. Попробуйте ещё раз или обратитесь к администратору.',
  followTheLink: 'Перейти по ссылке?',
  loading: 'Загрузка...',
  actions: 'Действия',
  filterList: 'Список фильтров',
  from: 'От',
  to: 'До',
  total: 'Всего',
  selected: 'Выбрано',
  freeComment: 'Свободный комментарий',
  visibility: 'Видимость',
  noAccess: 'Уппс... Нет доступа',
  forbiddenDescription:
    'Сожалеем, но у вас нет доступа к этой странице или ресурсу. Пожалуйста, вернитесь на главную страницу.',
  forbiddenUserDescription:
    'Сожалеем, но учётная запись была отключена администратором.',
  notFound: 'Страница не найдена',
  notFoundDescription:
    'Вы находитесь здесь потому, что запрашиваемая страница не существует или была перемещена по другому адресу.',
  removedPageTitle: 'Страница удалена...',
  removedPageDescription:
    'Вы находитесь здесь потому что запрашиваемая страница была удалена',
  serviceUnavailable: 'Сервис недоступен',
  serviceUnavailableDescription:
    'Извините... К сожалению сервис временно недоступен. Мы уже ведем работы по устранению неисправностей.',
  errorLoginGoogle:
    'При попытке авторизоваться через Google произошла непредвиденная ошибка.',
  cancelLoginGoogle: 'Вы отменили попытку войти через Google.',
  loginWithGoogle: 'Войти с Google',
  incorrectUsernameOrPassword: 'Неправильный логин или пароль',
  errorOccurredWhileTryingToLogin:
    'При попытке авторизоваться произошла ошибка. Попробуйте ещё раз или обратитесь к администратору',
  loginHeaderMessage: 'Пожалуйста, авторизуйтесь.',
  requiredField: 'Обязательное поле',
  login: 'Логин',
  password: 'Пароль',
  signIn: 'Войти',
  logout: 'Выйти',
  unexpectedDataErrorBoundary:
    'В работе приложения произошла фатальная ошибка.',
  unexpectedDataErrorBoundaryDescription:
    'Попробуйте перезагрузить страницу или обратитесь к администратору системы.',
  resetColumnWidthsToDefault: 'Сбросить ширину колонок',
  resetColumnOrderToDefault: 'Сбросить порядок колонок',
  resetColumnVisibilityToDefault: 'Сбросить видимость колонок',
  reset: 'Сбросить',
  resetFilters: 'Сбросить фильтры',
  dropFilesHere: 'Бросьте файлы сюда...',
  dragAndDropFile: 'Перетащите сюда файлы или нажмите, чтобы выбрать файлы',
  clear: 'Очистить',
  today: 'Сегодня',
  noOptions: 'Нет вариантов',
  userBlockedByAdministrator:
    'Учётная запись пользователя отключена администратором.',
  open: 'Открыть',
  browserNotSupportFileViewing:
    'Ваш браузер не поддерживает предпросмотр файлов',
  expandAll: 'Развернуть все',
  collapseAll: 'Свернуть все',
  expandMenuTooltip: 'Развернуть меню',
  collapseMenuTooltip: 'Свернуть меню',
  scanQrCode: 'Сканировать QR-код',
  profileButton: 'Профиль',
  set: 'Установить',
  showPassword: 'Показать пароль',
  hidePassword: 'Скрыть пароль',
  currentPassword: 'Текущий пароль',
  newPassword: 'Новый пароль',
  repeatPassword: 'Повторите пароль',
  settingAPassword: 'Установка пароля',
  changePassword: 'Сменить пароль',
  restoringAccess: 'Восстановление доступа',
  forgotPassword: 'Забыли пароль?',
  forgotPasswordText:
    'Укажите свой логин и email, под которым вы зарегистрированы в системе. На него будет отправлена информация о восстановлении пароля.',
  emailSentSuccessfully: 'Письмо успешно отправлено!',
  emailSentSuccessfullyText:
    'Письмо со ссылкой для восстановления пароля отправлено на:',
  enterYouNewPasswordTitle: 'Укажите свой новый пароль',
  enterYouNewPasswordText: 'Укажите новый пароль для входа в систему',
  passwordsDoNotMatch: 'Пароли не совпадают',
  minimumCharacters: 'Минимум 6 символов',
  oneLowerEnglishLetter: 'Минимум одна строчная латинская буква',
  oneCapitalEnglishLetter: 'Минимум одна заглавная латинская буква',
  oneDigit: 'Минимум одна цифра',
  oneSpecialSymbol: 'Минимум один из спецсимволов: !@#$%^&*()_+-=',
  containOnlyAllowedCharacters:
    'Пароль должен содержать только разрешенные символы',
  showClosed: 'Отображать закрытые',
  hideClosed: 'Скрыть закрытые',
  toLoginForm: 'К форме входа',
  history: 'История',
  select: 'Выбрать',
  defaultNoOptionsText: 'Введите хотя бы 3 символа...',
  noOptionsWereFoundForQuery:
    'По введенному запросу не найдено подходящих вариантов',
  firstTenRecordsFoundAreDisplayed:
    'Отображены первые 10 найденных записей. Уточните критерии или откройте',
  allEntries: 'Все записи',
  columnVisibilityControl: 'Управление видимостью колонок',
  allColumnsAreHidden: 'Все столбцы скрыты',
  rowsPerPage: 'Строк на страницу',
};

export default ruDictionary;
