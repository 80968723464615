import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core';

import './index.css';
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import store from 'store';
import PreLoaderScreen from 'components/lowLevel/PreLoaderScreen';
import { UnexpectedDataErrorBoundary } from 'utils/errors/boundaries/UnexpectedDataErrorBoundary';
import theme from './theme';
import LocaleProvider from './locale';
import './yupCustomMethods';

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <LocaleProvider>
        <UnexpectedDataErrorBoundary>
          <CssBaseline />
          <PreLoaderScreen>
            <App />
          </PreLoaderScreen>
        </UnexpectedDataErrorBoundary>
      </LocaleProvider>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
