import React from 'react';
import {
  TimelineElement,
  CurrentUserTimelineElement,
  OtherUserTimelineElement,
  SystemTimelineElement,
} from '../../../services/Main/types.Component';
import { UnexpectedDataError } from '../../../utils/errors/UnexpectedDataError';
import CurrentUserTimelineElementComponent from './CurrentUserTimelineElement';
import OtherUserTimelineElementComponent from './OtherUserTimelineElement';
import SystemTimelineElementComponent from './SystemTimelineElement';

export default (props: TimelineElement) => {
  switch (props.type) {
    case 'currentUser':
      return (
        <CurrentUserTimelineElementComponent
          {...(props as CurrentUserTimelineElement)}
        />
      );
    case 'otherUser':
      return (
        <OtherUserTimelineElementComponent
          {...(props as OtherUserTimelineElement)}
        />
      );
    case 'system':
      return (
        <SystemTimelineElementComponent {...(props as SystemTimelineElement)} />
      );
    default:
      throw new UnexpectedDataError(
        'Не удалось определить тип TimelineElement'
      );
  }
};
