import React, { createContext, PropsWithChildren, useState } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogAlert } from '../../../services/Main/types';
import { useFormatMessage } from '../../../locale';

interface DialogAlertProps {}

export interface DialogAlertContextState {
  dialogState: DialogAlert | null;
  setDialogState: (state: DialogAlert | null) => void;
}

export const DialogAlertContext = createContext<DialogAlertContextState>({
  dialogState: null,
  setDialogState: () => {},
});

export default ({ children }: PropsWithChildren<DialogAlertProps>) => {
  const formatMessage = useFormatMessage();
  const [dialogState, setDialogState] = useState<DialogAlert | null>(null);

  const handleClose = () => {
    setDialogState(null);
  };

  return (
    <DialogAlertContext.Provider value={{ dialogState, setDialogState }}>
      <Dialog open={!!dialogState} onClose={handleClose}>
        <DialogTitle>{dialogState?.heading}</DialogTitle>
        <DialogContent>
          {dialogState?.body.split('\n').map((p, index) => (
            <p key={index}>{p}</p>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {formatMessage('close')}
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </DialogAlertContext.Provider>
  );
};
