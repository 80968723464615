import { createContext, Dispatch, SetStateAction } from 'react';
import { DirtyFormValues } from '../../../services/Main/types.Component';
import { Field } from '../../../services/Main/types.Field';

export type FormSubmitHandlers = {
  [key: string]: FormSubmitHandler;
} | null;

export type FormSubmitHandler = (dirtyValues: DirtyFormValues) => void;

interface FormBuilderContext {
  id: number | string;
  onSubmitHandlers: FormSubmitHandlers;
  setOnSubmitHandlers: Dispatch<SetStateAction<FormSubmitHandlers>>;
  firedSubmitAction: string | null;
  setFiredSubmitAction: (actionName: string) => void;
  fields: Field[];
  fieldsByName: Record<string, Field>;
}

export default createContext<FormBuilderContext>({
  id: -65535,
  onSubmitHandlers: null,
  setOnSubmitHandlers: () => null,
  firedSubmitAction: null,
  setFiredSubmitAction: () => {},
  fields: [],
  fieldsByName: {},
});
