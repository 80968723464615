import {
  AsyncShowComponentInModalReaction,
  Component,
} from '../../services/Main/types.Component';
import store from '../../store';
import { dialogWithComponentActions } from '../../store/dialogWithComponent';

export default (
  reaction: AsyncShowComponentInModalReaction,
  payload: Component
) => {
  if (!payload || !payload.type) return;

  store.dispatch(dialogWithComponentActions.open(payload));
};
