import React, { Dispatch, SetStateAction, useRef } from 'react';
import {
  SortingState as GridSortingState,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
import {
  DragDropProvider,
  Grid,
  TableHeaderRow,
  TableBandHeader,
  TableFixedColumns,
  TableColumnResizing,
  TableColumnVisibility,
  Table,
  TableProps,
  TableColumnReordering,
} from '@devexpress/dx-react-grid-material-ui';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';
import StickyTable from 'components/lowLevel/StickyTable';
import TableContainer from 'components/lowLevel/TableContainer';
import {
  DataTypeProvidersProps,
  DirtyFormValues,
  SortingState,
  TableComponentProps,
  TableFilterFormComponent,
} from '../../../services/Main/types.Component';
import DataTypeProviders from './TypeProviders/DataTypeProviders';
import useStyles from './Table.styles';
import TableModules, {
  TableFilterFormProps,
} from '../../lowLevel/TableModules';
import TableHeaderTooltip from '../../lowLevel/TableHeaderTooltip';
import { useFormatMessage } from '../../../locale';
import PagingPanel from './components/PagingPanel';
import TableColumnVisibilityMessage from './components/TableColumnVisibilityMessage';

interface TableComponentViewProps extends TableComponentProps {
  isLoading: boolean;
  tableProps: TableProps;
  loadRows: () => void;
  dataTypeProvidersProps: DataTypeProvidersProps;
  setSorting: Dispatch<SetStateAction<SortingState | undefined>>;
  sortingStateColumnExtensions: any[];
  someColumnSortable: boolean;
  someColumnHasTitle: boolean;
  setQuery: Dispatch<SetStateAction<string | undefined>>;
  filter: DirtyFormValues | undefined;
  setFilter: Dispatch<SetStateAction<DirtyFormValues | undefined>>;
  columnWidths: TableColumnWidthInfo[];
  setColumnWidths: Dispatch<SetStateAction<TableColumnWidthInfo[]>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setPageSize: Dispatch<SetStateAction<number>>;
  query?: string;
  columnOrder: string[];
  setColumnOrder: Dispatch<SetStateAction<string[]>>;
  hiddenColumnNames: string[];
}

export default ({
  columns,
  columnBands,
  rows,
  isLoading,
  heading,
  header,
  showSearchbar,
  filterComponent,
  quickFilters,
  requestConfig,
  actions,
  alerts,
  showTotalRows,
  tableProps,
  loadRows,
  dataTypeProvidersProps,
  setSorting,
  sortingStateColumnExtensions,
  fixedColumns,
  someColumnHasTitle,
  someColumnSortable,
  fullHeight = true,
  query,
  setQuery,
  filter,
  setFilter,
  columnWidths,
  setColumnWidths,
  setCurrentPage,
  setPageSize,
  enableColumnResizing,
  columnOrder,
  setColumnOrder,
  enableColumnReordering,
  columnVisibilityConfig,
  hiddenColumnNames,
  options: { totalRows, pageSize, currentPage, sorting, pageSizeOptions },
}: TableComponentViewProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const rootRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const filterChangeCount = useRef<number>(0);

  const handleFilterChange = (newFilterState: DirtyFormValues | undefined) => {
    // Пропускаем первый вызов:
    // Установка defaultValue
    // filter: undefined -> filter: { comment: '', shop: [], status: []}
    // или если в filter уже есть значение (например из sessionStorage)
    // filter: { store: [...] } -> filter: { store: [...], comment: '', ... }
    if (filterChangeCount.current === 0) {
      filterChangeCount.current += 1;
      return;
    }

    setCurrentPage(0);
    setFilter(newFilterState);
  };

  const tableFilterFormProps:
    | Omit<TableFilterFormProps, 'filterChips' | 'setFilterChips'>
    | undefined = filterComponent && {
    ...(filterComponent as TableFilterFormComponent),
    filter,
    setFilter: handleFilterChange,
  };

  return (
    <div
      className={classes.root}
      ref={rootRef}
      style={{
        height: fullHeight
          ? `calc(100vh - ${(rootRef.current?.offsetTop || 0) + 40}px)`
          : '100%',
        minHeight: fullHeight ? 600 : 250,
      }}
    >
      <TableModules
        totalRows={totalRows}
        heading={heading}
        header={header}
        showSearchbar={showSearchbar}
        searchInputProps={{ query, onQueryChange: setQuery }}
        tableFilterFormProps={tableFilterFormProps}
        requestConfig={requestConfig}
        actions={actions}
        alerts={alerts}
        showTotalRows={showTotalRows}
        quickFilters={quickFilters}
      />
      <LinearProgress
        style={{
          visibility: isLoading ? 'visible' : 'hidden',
          flexGrow: 0,
        }}
      />
      <Box className={classes.tableContent} mb={2}>
        <Grid getRowId={(row) => row.id} rows={rows} columns={columns}>
          <GridSortingState
            sorting={sorting}
            columnExtensions={sortingStateColumnExtensions}
            onSortingChange={setSorting}
          />
          <DataTypeProviders {...dataTypeProvidersProps} />
          {enableColumnReordering && <DragDropProvider />}
          <Table
            {...tableProps}
            messages={{
              noData: formatMessage('noData'),
            }}
            tableComponent={StickyTable}
            containerComponent={TableContainer}
          />
          {enableColumnReordering && (
            <TableColumnReordering
              order={columnOrder}
              onOrderChange={setColumnOrder}
            />
          )}
          {enableColumnResizing && (
            <TableColumnResizing
              columnWidths={columnWidths}
              onColumnWidthsChange={setColumnWidths}
            />
          )}
          {someColumnHasTitle && (
            <TableHeaderRow
              sortLabelComponent={TableHeaderTooltip}
              showSortingControls={someColumnSortable}
            />
          )}
          {columnVisibilityConfig?.enabled && (
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              messages={{
                noColumns: formatMessage('allColumnsAreHidden'),
              }}
              emptyMessageComponent={TableColumnVisibilityMessage}
            />
          )}
          {columnBands && <TableBandHeader columnBands={columnBands} />}
          {isMdUp && fixedColumns && <TableFixedColumns {...fixedColumns} />}
        </Grid>
      </Box>
      <PagingPanel
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageSizeOptions={pageSizeOptions}
        totalRows={totalRows}
        loadRows={loadRows}
      />
    </div>
  );
};
