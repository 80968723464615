import React, { useContext, useMemo, ChangeEvent } from 'react';
import { Select, InputBase, MenuItem } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import clsx from 'clsx';
import EntryPickerTableContext from '../../EntryPickerTable.context';
import useStyles from '../../EntryPickerTable.styles';

const rowsPerPageOptions = [10, 25, 50, 100, 500];

const PagingPanel = () => {
  const classes = useStyles();
  const {
    totalRows,
    currentPage,
    pageSize,
    setPageSize,
    setCurrentPage,
    loadRows,
    filterOptions,
    sorting,
  } = useContext(EntryPickerTableContext);

  const handleChange = (e: ChangeEvent<unknown>, newPage: number) => {
    setCurrentPage(newPage - 1);

    loadRows({
      pageSize,
      currentPage: newPage - 1,
      filter: filterOptions,
      sort: sorting,
    });
  };

  const handleChangePageSize = (event: ChangeEvent<{ value: unknown }>) => {
    const newPageSize = event.target.value as number;

    setPageSize(newPageSize);
    setCurrentPage(0);

    loadRows({
      pageSize: newPageSize,
      currentPage: 0,
      filter: filterOptions,
      sort: sorting,
    });
  };

  const totalPages = useMemo(
    () => Math.ceil(totalRows / pageSize),
    [totalRows, pageSize]
  );

  return (
    <>
      <Select
        classes={{
          select: classes.select,
        }}
        input={
          <InputBase className={clsx(classes.input, classes.selectRoot)} />
        }
        value={pageSize}
        onChange={handleChangePageSize}
      >
        {rowsPerPageOptions.map((rowsPerPageOption) => (
          <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
            {rowsPerPageOption}
          </MenuItem>
        ))}
      </Select>
      <Pagination
        count={totalPages}
        page={currentPage + 1}
        onChange={handleChange}
        showFirstButton
        showLastButton
      />
    </>
  );
};

export default PagingPanel;
