import React, { forwardRef, Ref } from 'react';
import { TimelineElement } from '../../../services/Main/types.Component';
import TimelineElementComponent from '../TimelineElement';
import useStyles from './TimelineElements.styles';
import TimelineScrollToBottom from '../TimelineScrollToBottom';

interface TimelineElementsProps {
  timelineElements: TimelineElement[];
}

const TimelineElements = (
  { timelineElements }: TimelineElementsProps,
  ref: Ref<HTMLDivElement>
) => {
  const classes = useStyles();

  return (
    <div ref={ref} className={classes.TimelineElementsContainer}>
      {timelineElements.map((timelineElementProps) => (
        <TimelineElementComponent
          key={timelineElementProps.id}
          {...timelineElementProps}
        />
      ))}
      <TimelineScrollToBottom />
    </div>
  );
};

export default forwardRef(TimelineElements);
