import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import objectHash from 'object-hash';
import { useFormContext } from 'react-hook-form';
import { SelectOption } from '../../../../services/Main/types.Field';

interface UseSubscribeProps {
  setReFetchOptionsCount: Dispatch<SetStateAction<string>>;
  reason: string;
  currentFieldName: string;
  subscribeOn?: string[];
}

export default ({
  setReFetchOptionsCount,
  reason,
  currentFieldName,
  subscribeOn,
}: UseSubscribeProps) => {
  const { watch } = useFormContext();
  const subscribeFieldNames = subscribeOn
    ? getSubscribeList(subscribeOn, currentFieldName)
    : null;
  const subscribeOnFieldValues = subscribeFieldNames
    ? watch(subscribeFieldNames)
    : null;
  const subscribeOnFieldValuesHash = subscribeOnFieldValues
    ? objectHash(subscribeOnFieldValues)
    : null;
  const isFirstRun = useRef<boolean>(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    // отлавливаем момент пересоздания row ArrayOf.
    // Undefined присваивает rhf под капотом на короткое время.
    const hasLeadingValuesUndefined =
      subscribeOnFieldValues &&
      Object.values(subscribeOnFieldValues).some((v) => v === undefined);

    if (!subscribeOn) return;
    if (hasLeadingValuesUndefined) return;

    setReFetchOptionsCount(
      (prevState) => `${+prevState.charAt(0) + 1}_${reason}`
    );
    // eslint-disable-next-line
  }, [subscribeOnFieldValuesHash]);

  const preparedFieldValues = subscribeOnFieldValues
    ? prepareFieldValues(
        subscribeOnFieldValues as Record<string, SelectOption[]>
      )
    : subscribeOnFieldValues;

  return [preparedFieldValues];
};

function getSubscribeList(subscribeOn: string[], currentFieldName: string) {
  return subscribeOn.map((s) => {
    if (s.includes('[]')) {
      const fieldNameMatches = currentFieldName.match(/\[(\d+)\]/);
      const currentRowIndex = fieldNameMatches ? fieldNameMatches[1] : null;
      if (!currentRowIndex)
        throw new Error(
          `Не удалось подписать '${currentFieldName}' на строку ArrayOf ${s}.`
        );

      return s.replace('[]', `[${currentRowIndex}]`);
    }

    return s;
  });
}

function prepareFieldValues(
  values: Record<string, SelectOption[]>
): Record<string, string | string[]> {
  return Object.entries(values).reduce<Record<string, string | string[]>>(
    (acc, [fieldName, fieldValue]) => {
      if (!fieldValue) return acc;

      // SelectOption[]
      if (Array.isArray(fieldValue)) {
        if (fieldValue.length === 0) return acc;

        return {
          ...acc,
          [fieldName]: fieldValue.filter((v) => !!v.value).map((v) => v.value),
        };
      }

      // SelectOption
      if ((fieldValue as SelectOption)?.value) {
        return { ...acc, [fieldName]: (fieldValue as SelectOption).value };
      }

      return { ...acc, [fieldName]: fieldValue };
    },
    {}
  );
}

// TODO удалить после 01.01.2022.
//  Это "решение" нужно для того, чтобы на бек уходили SingleDependent Select
//  как guid, сейчас уходит guid[]. Это подходит, т.к. на беке сейчас в любом
//  случае значения конвертируются в sting[]. Минусы решения ниже - высокая
//  ненадёжность (регулярные выражения, много конвертаций без fallback).
// Reducer для prepareFieldValues:
// (acc, [fieldName, fieldValue]) => {
//   const field = getField(fieldName, fields);
//   // const field = fields.find((f) => f.name === preparedFieldName);
//   // const fieldNameMatches = fieldName.match(/\[(\d+)\]/);
//
//   if (!field) return acc;
//   if (!fieldValue || fieldValue.length === 0) return acc;
//
//   const value = getFieldValue(field, fieldValue);
//
//   if (Array.isArray(value))
//     return { ...acc, [fieldName]: value.map((v) => v.value) };
//
//   return { ...acc, [fieldName]: value.value };
// },
//
// // Извлечение значения для Single Dependent Select.
// function getFieldValue(field: Field, value: SelectOption[]) {
//   if (
//     field.type === 'select' &&
//     field.selectType === 'dependent' &&
//     !field.multiple
//   ) {
//     return value[0];
//   }
//
//   return value;
// }
//
// function getField(name: string, fields: Field[]): Field {
//   // arrayOf
//   if (name.match(/[\[\d+\]]/)) {
//     const parseNameRegexp = new RegExp(
//       /([A-Za-z\-]+)\[([\d+]+)\]\.([A-Za-z\-]+)/g
//     );
//     const matches = parseNameRegexp.exec(name);
//     if (!matches || matches.length !== 4)
//       throw new Error('Некорректное имя ArrayOf');
//
//     const arrayOfFieldName = matches[1];
//     // const index = +matches[2];
//     const arrayOfChildrenFieldName = matches[3];
//
//     const arrayOfField = fields.find((f) => f.name === arrayOfFieldName);
//     return ((arrayOfField as unknown) as ArrayOf).rowDefinition.find(
//       (f) => f.name === arrayOfChildrenFieldName
//     ) as Field;
//   }
//
//   return fields.find((f) => f.name === name) as Field;
// }
