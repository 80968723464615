import {
  EntryPickerInterface,
  FetchOptionsResponsePayload,
  FetchTablePropsResponsePayload,
  FetchTablePropsRequestBody,
  FetchOptionsRequestBody,
} from './EntryPicker.interface';
import { RequestConfig } from '../Main/types.Component';
import { get, post } from '../Api';
import { ServerResponse } from '../Main/types';

class EntryPickerRemote implements EntryPickerInterface {
  fetchOptions = ({ url }: RequestConfig, params: FetchOptionsRequestBody) => {
    return get<ServerResponse<FetchOptionsResponsePayload>>(url, {
      params,
    }).then(({ data }) => data);
  };

  fetchTableProps = (
    { url }: RequestConfig,
    params: FetchTablePropsRequestBody
  ) => {
    return post<ServerResponse<FetchTablePropsResponsePayload>>(url, {
      params,
    }).then(({ data }) => data);
  };
}

export default EntryPickerRemote;
