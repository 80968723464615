import React, { PropsWithChildren, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { GridItemContext } from '../../lowLevel/GridBuilder/GridBuilder';

const SizeWrapper = ({ children }: PropsWithChildren<any>) => {
  const gridItemContext = useContext(GridItemContext);

  if (!gridItemContext) return null;

  const { mdCols: containerMdCols } = gridItemContext;

  return (
    <Grid
      item
      xs={12}
      md={(containerMdCols && (containerMdCols <= 4 ? 12 : 6)) || 3}
      xl={(containerMdCols && containerMdCols <= 4 ? 6 : 3) || 3}
    >
      {children}
    </Grid>
  );
};

export default SizeWrapper;
