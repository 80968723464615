import React from 'react';
import Link from 'components/lowLevel/Link';
import { ComboBoxOption } from '../../../../../services/Main/types.Field';
import useStyles from './Tags.styles';

const Tag = ({ href, label }: ComboBoxOption) => {
  const classes = useStyles();

  if (href) {
    return (
      <div className={classes.tag}>
        <Link className={classes.link} to={href} target="_blank">
          {label}
        </Link>
      </div>
    );
  }

  return <div className={classes.tag}>{label}</div>;
};

export default Tag;
