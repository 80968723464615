import React from 'react';
import { DocRenderer } from 'react-doc-viewer';
import useStyles from './ImageRenderer.styles';
import ImageWrapper from '../ImageWrapper';

const ImageRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
  const classes = useStyles();

  if (!currentDocument) return null;

  return (
    <ImageWrapper>
      <img
        src={currentDocument.uri}
        alt={currentDocument.uri}
        className={classes.img}
      />
    </ImageWrapper>
  );
};

const ImageFileTypes = {
  bmp: ['bmp', 'image/bmp'],
  jpg: ['jpg', 'jpeg', 'image/jpg', 'image/jpeg'],
  png: ['png', 'image/png'],
  svg: ['svg', 'image/svg+xml'],
};

ImageRenderer.weight = 3;
ImageRenderer.fileTypes = [
  ...ImageFileTypes.bmp,
  ...ImageFileTypes.jpg,
  ...ImageFileTypes.png,
  ...ImageFileTypes.svg,
];
// Отключает дефолтную загрузку библиотеки
// Убирает лишний запрос при открытии предпросмотра
ImageRenderer.fileLoader = ({ fileLoaderComplete }) => fileLoaderComplete();

export default ImageRenderer;
