import React, { useContext, useEffect } from 'react';
import EntryPickerTableView from './EntryPickerTable.view';
import { useSelectRow } from './hooks/useSelectRow';
import { useSorting } from './hooks/useSorting';
import EntryPickerTableContext from './EntryPickerTable.context';

const EntryPickerTableContainer = () => {
  const { loadRows, pageSize, currentPage, filterOptions, sorting } =
    useContext(EntryPickerTableContext);
  // Хук управления выделенной строки
  const { handleChangeSelection } = useSelectRow();

  // Хук управления сортировкой колонок
  const { sortingStateColumnExtensions, someColumnSortable } = useSorting();

  // Первоначальная инициализация. Сработает только один раз, поэтому подписка
  // на актуальные pageSize, currentPage, etc... не нужна.
  useEffect(() => {
    loadRows({
      pageSize,
      currentPage,
      filter: filterOptions,
      sort: sorting,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <EntryPickerTableView
        onSelectionChange={handleChangeSelection}
        someColumnSortable={someColumnSortable}
        sortingStateColumnExtensions={sortingStateColumnExtensions}
      />
    </>
  );
};
export default EntryPickerTableContainer;
