import React, { forwardRef, Ref, useContext } from 'react';
import {
  TimelineComponentProps,
  TimelineElement,
} from '../../../services/Main/types.Component';
import Header from '../../lowLevel/Header';
import TimelineElements from '../../lowLevel/TimelineElements';
import AddTimelineElementForm from '../../lowLevel/AddTimelineElementForm';
import useStyles from './Timeline.styles';
import { ComponentContext } from '../../creational/ComponentLoader';
import useContainerHeight from './useContainerHeight';

interface TimelineProps
  extends Omit<TimelineComponentProps, 'subscribeToUpdatesUrl'> {
  timelineElements: TimelineElement[];
}

const Timeline = (
  {
    timelineElements,
    header,
    addCommentUrl,
    addTimelineElementForm,
  }: TimelineProps,
  timelineElementsContainer: Ref<HTMLDivElement>
) => {
  const classes = useStyles();
  const componentContext = useContext(ComponentContext);
  const timelineDOMId = `Timeline_${componentContext?.id}`;
  const containerHeight = useContainerHeight(timelineDOMId);

  return (
    <div
      id={timelineDOMId}
      className={classes.TimelineContainer}
      style={{ height: containerHeight }}
    >
      <Header {...header} />
      <TimelineElements
        ref={timelineElementsContainer}
        timelineElements={timelineElements}
      />
      <AddTimelineElementForm
        addTimelineElementForm={addTimelineElementForm}
        addCommentUrl={addCommentUrl}
      />
    </div>
  );
};

export default forwardRef(Timeline);
