import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { TextWidget } from '../../../services/Main/types.Component';
import useStyles from './TextWidget.styles';
import Trend from './Trend';
import EmptyWidgetDataText from '../EmptyWidgetDataText';

export default ({ title, metric, trend, description }: TextWidget) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        {metric ? (
          <>
            <Typography
              className={classes.metric}
              variant="h5"
              component="h2"
              gutterBottom
            >
              {metric}
            </Typography>
            <Typography variant="body2">
              {trend && <Trend {...trend} />}
              <span className={classes.description}>{description}</span>
            </Typography>
          </>
        ) : (
          <EmptyWidgetDataText height="100%" width="100%" fontSize="2.5rem" />
        )}
      </CardContent>
    </Card>
  );
};
