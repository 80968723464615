import React from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { FormHistoryElement } from '../../../services/Main/types.Component';
import useStyles from '../TimelineElement/OtherUserTimelineElement/OtherUserTimelineElement.styles';
import useSharedStyles from '../../highLevel/Timeline/Timeline.styles';
import { formatDateFromISOstring } from '../../../utils/formatDate';
import OtherUserTimelineElementAvatar from '../TimelineElement/OtherUserTimelineElement/OtherUserTimelineElementAvatar';
import useFormHistoryElementStyles from './FormHistoryElement.styles';

export default ({
  id,
  body,
  createdAt,
  header,
  author,
}: FormHistoryElement) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const formHistoryElementClasses = useFormHistoryElementStyles();
  const formattedCreatedAt = formatDateFromISOstring(
    createdAt as string,
    'dateTime'
  );

  return (
    <div
      className={clsx(
        sharedClasses.CommonTimelineElement,
        classes.OtherUserTimelineElementRoot,
        formHistoryElementClasses.elementContainer
      )}
      data-timeline-element-id={id}
    >
      <div
        className={clsx(
          classes.OtherUserTimelineElementAvatar,
          formHistoryElementClasses.OtherUserTimelineElementAvatar
        )}
      >
        {author && <OtherUserTimelineElementAvatar author={author} />}
      </div>
      <div className={clsx(classes.OtherUserTimelineElementContentWrapper)}>
        <Grid
          className={clsx(
            sharedClasses.TimelineElementWithBorder,
            classes.OtherUserTimelineElementContainer
          )}
          container
        >
          <Grid
            item
            xs={12}
            md={9}
            className={sharedClasses.CommonTimelineElementBodyContainer}
          >
            <Grid
              className={classes.OtherUserTimelineElementHeaderContainer}
              container
              spacing={1}
              alignItems="center"
            >
              {author && (
                <Grid
                  className={classes.MobileOtherUserTimelineElementAvatar}
                  item
                >
                  <OtherUserTimelineElementAvatar author={author} />
                </Grid>
              )}
              {header && (
                <Grid item>
                  <div
                    className={clsx(
                      sharedClasses.TimelineHeader,
                      classes.OtherUserTimelineHeader
                    )}
                  >
                    {header}
                  </div>
                </Grid>
              )}
            </Grid>
            <div
              className={sharedClasses.CommonTimelineElementBody}
              dangerouslySetInnerHTML={{ __html: body.html }}
            />
          </Grid>
          <Grid
            xs={12}
            md={3}
            className={sharedClasses.CommonTimelineElementSidebar}
            container
            item
            direction="column"
            justify="space-between"
          >
            <Grid container justify="flex-end" spacing={2}>
              <Grid
                item
                className={clsx(sharedClasses.CommonTimelineElementCreatedAt)}
              >
                {formattedCreatedAt}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
