import React, { MouseEventHandler } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import Icon from '../Icon/Icon';
import { TableActionProps } from '../TableAction/types';
import useStyles from '../TableAction/TableAction.styles';
import useIsSomeActionExecuting from '../FormAction/useIsSomeActionExecuting';

interface TableActionViewProps extends TableActionProps {
  handleClick: MouseEventHandler<unknown>;
}

const TableActionView = (props: TableActionViewProps) => {
  const { action, isDialogOpen, handleClick } = props;
  const classes = useStyles();
  const { label, color, icon, variant } = action;
  const { isSomeActionExecuting } = useIsSomeActionExecuting();

  return (
    <>
      <Grid className={classes.hiddenMobile} item>
        <Button
          variant={variant}
          color={color}
          type="button"
          onClick={handleClick}
          disabled={isSomeActionExecuting}
        >
          {icon && <Icon definition={icon} className={classes.icon} />}
          {label}
        </Button>
      </Grid>
      <SpeedDialAction
        className={classes.hiddenDesktop}
        classes={{ staticTooltipLabel: classes.tooltip }}
        icon={icon ? <Icon definition={icon} /> : <></>}
        title={label}
        tooltipTitle={label}
        tooltipOpen
        open={isDialogOpen}
        onClick={handleClick}
      />
    </>
  );
};

export default TableActionView;
