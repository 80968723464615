import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    speedDial: {
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        zIndex: 10,
        bottom: theme.spacing(3),
        right: theme.spacing(3),
      },
      [theme.breakpoints.up('md')]: {
        '&.MuiSpeedDial-directionUp .MuiSpeedDial-actions': {
          marginBottom: theme.spacing(0),
          paddingBottom: theme.spacing(0),
        },
      },
    },
  })
);
