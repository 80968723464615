import { HEADING_HEIGHT, LEGEND_HEIGHT, PERIOD_BAR_HEIGHT } from './constants';

export default function (
  defaultMinHeight: number,
  hasHeading: boolean,
  hasLegend: boolean,
  hasPeriod: boolean
) {
  let minHeight = defaultMinHeight;

  if (hasHeading) minHeight += HEADING_HEIGHT;
  if (hasLegend) minHeight += LEGEND_HEIGHT;
  if (hasPeriod) minHeight += PERIOD_BAR_HEIGHT;

  return minHeight;
}
